import React from "react"
import { Typography } from "../typography/Typography"
import { getPriorityColor } from "@utils/priorityUtils"
import { IRecommendationPriority } from "@features/recommendations/recommendationTypes"

interface Props {
    priority: IRecommendationPriority
}

export const PriorityBadge: React.FC<Props> = ({ priority }) => {
    const color = getPriorityColor(priority)
    return (
        <div className="flex items-center gap-x-2">
            <div className={`w-[24px] h-[12px] rounded-xl ${color}`} />
            <Typography variant="body-1" color="text-secondary" className="capitalize">
                {priority}
            </Typography>
        </div>
    )
}
