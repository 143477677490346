import { Button } from "@designSystem/buttons/Button"
import { Typography } from "@designSystem/typography/Typography"
import { DetectionsTable } from "@features/detections/components/DetectionsTable"
import { useDetections } from "@features/detections/hooks/useDetections"
import { getValidDateRange } from "@utils/dateRangeUtils"
import { getCurrentTimestamp, getTimestampDaysAgo } from "@utils/dateUtils"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ArrowLongRightIcon } from "@heroicons/react/24/solid"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { MetricBlockSkeleton } from "@designSystem/feedback/skeletons/MetricBlockSkeleton"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import AwaitingDataIllustration from "@assets/illustrations/awaitingDataIllustration.svg?react"
import { MetricBlockFooter, MetricBlockWrapper } from "../metricBlock/MetricBlock"
import { WidgetSize } from "@features/dashboard/dashboardTypes"

interface Props {
    eyed: string
    size?: WidgetSize
}

export const DetectionsWidget: React.FC<Props> = ({ eyed, size = "full" }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const defaultRange = useMemo(() => {
        return getValidDateRange({
            from: getTimestampDaysAgo(30),
            to: getCurrentTimestamp(),
        })
    }, [])

    const { isPending, data } = useDetections(eyed, defaultRange)
    const detectionsData = data?.data ?? []
    const detectionsToShow = detectionsData.slice(0, 5)

    if (isPending) {
        return (
            <div>
                <MetricBlockSkeleton />
            </div>
        )
    }

    if (detectionsToShow.length === 0) {
        return (
            <MetricBlockWrapper testId="detections-widget">
                <div className="p-6">
                    <Typography variant="body-2-semibold" color="text-primary">
                        {t(`dashboard.metrics.detections.heading`)}
                    </Typography>
                    <div className="py-12">
                        <NoDataMessage
                            title={t("detections.messages.noDataTitle")}
                            subtitle={t("detections.messages.noDataSubtitle")}
                            variant="row"
                            image={<AwaitingDataIllustration className="h-[250px]" />}
                        />
                    </div>
                </div>
            </MetricBlockWrapper>
        )
    }

    return (
        <MetricBlockWrapper testId="detections-widget" className="overflow-auto">
            <div>
                <div className="p-6 flex justify-between items-center flex-col xl:flex-row">
                    <Typography variant="body-1-semibold" color="text-primary">
                        {t(`dashboard.metrics.detections.heading`)}
                    </Typography>

                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary">
                            {t("detections.widgetSubtitle", { count: detectionsData.length })}
                        </Typography>
                        <Typography variant="span" color="text-inactive" className="mb-1">
                            |
                        </Typography>
                        <Typography variant="body-2" color="text-secondary" testId="detections-updated-since">
                            {t("detections.updatedSince")}
                        </Typography>
                    </div>
                </div>

                <div data-testid="detections-widget-table">
                    <DetectionsTable
                        data={detectionsToShow}
                        isRounded={false}
                        hasBorder={false}
                        onClickRow={(detectionId: string) => {
                            navigate({
                                pathname: getRouterLinks().detections.root({ eyed }),
                                search: new URLSearchParams({ detectionId: detectionId }).toString(),
                            })
                        }}
                        size={size}
                    />
                </div>
            </div>

            <MetricBlockFooter>
                <div>
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => {
                            navigate(getRouterLinks().detections.root({ eyed }))
                        }}
                        endIcon={<ArrowLongRightIcon width={16} height={16} />}
                    >
                        {t(`dashboard.metrics.detections.link`)}
                    </Button>
                </div>
            </MetricBlockFooter>
        </MetricBlockWrapper>
    )
}
