import { TextInput } from "@designSystem/inputs/TextInput"
import { Collapse } from "@designSystem/layout/Collapse"
import { Typography } from "@designSystem/typography/Typography"
import { ThreatHuntTable } from "@features/threatHunting/components/ThreatHuntTable"
import { useThreatHunts } from "@features/threatHunting/hooks/useThreatHunts"
import { useThreatHuntsWithAssets } from "@features/threatHunting/hooks/useThreatHuntsWithAssets"
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid"
import { useActiveEyed } from "@hooks/useActiveEyed"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import debounce from "lodash/debounce"
import { ThreatHuntingSkeleton } from "@designSystem/feedback/skeletons/ThreatHuntingSkeleton"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import AwaitingDataIllustration from "@assets/illustrations/awaitingDataIllustration.svg?react"
import { ThreatHuntingPageLayout } from "@features/threatHunting/components/ThreatHuntingPageLayout"
import { filterThreatHunts } from "@utils/threatHuntingUtils"
import { ThreatHuntingIntroduction } from "@features/threatHunting/components/ThreatHuntingIntroduction"
import { localStorageSettings } from "@/localStorage/localStorage"
import { IThreatHunt } from "@features/threatHunting/threatHuntingTypes"

export const ThreatHuntingPage: React.FC = () => {
    const { t } = useTranslation()
    const activeEyed = useActiveEyed()
    // Basic search state used to display in the inputfield
    const [searchValue, setSearchValue] = useState("")
    // The state which will be updated with a debounce -> the search filtering listens to this state.
    const [debouncedSearchValue, setDebouncedSearchValue] = useState("")
    const [hasVisitedThreatHuntingIntroduction, setHasVisitedThreatHuntingIntroduction] = useState(
        localStorageSettings.getHasVisitedThreatHuntingIntroduction(),
    )

    // Load the "basic" threat hunts (without assets)
    const {
        data: threatHuntsData,
        isPending: isPendingThreatHunts,
        isSuccess: isSuccessThreatHunts,
    } = useThreatHunts(activeEyed)

    // Load the threat hunts with assets
    const {
        data: threatHuntsWithAssetsData,
        isPending: isPendingThreatHuntsWithAssets,
        isSuccess: isSuccessThreatHuntsWithAssets,
    } = useThreatHuntsWithAssets(activeEyed)

    const threatHunts = useMemo(() => {
        return threatHuntsData?.data ?? []
    }, [threatHuntsData])

    const threatHuntsWithAssets = useMemo(() => {
        return threatHuntsWithAssetsData?.data ?? []
    }, [threatHuntsWithAssetsData])

    // Combine the states of both queries
    const isPending = isPendingThreatHunts || isPendingThreatHuntsWithAssets
    const isSuccess = isSuccessThreatHunts || isSuccessThreatHuntsWithAssets
    const showThreatHuntsTable = threatHunts.length > 0
    const showThreatHuntsWithAssetsTable = threatHuntsWithAssets.length > 0
    const showEmptyDataMessage = !isSuccess && !showThreatHuntsTable && !showThreatHuntsWithAssetsTable

    // Sorts the threat hunts based on their acknowledge status
    const sortThreatHunts = (a: IThreatHunt, b: IThreatHunt) => {
        if (a.acknowledged === b.acknowledged) {
            return 0
        }

        return a.acknowledged ? 1 : -1
    }

    // Filter both threat hunt datasets based on the debounced searchValue
    const filteredThreatHunts = useMemo(() => {
        return threatHunts
            .filter((threatHunt) => {
                return filterThreatHunts(threatHunt, debouncedSearchValue)
            })
            .sort(sortThreatHunts)
    }, [threatHunts, debouncedSearchValue])

    const filteredThreatHuntsWithAssets = useMemo(() => {
        return threatHuntsWithAssets
            .filter((threatHunt) => {
                return filterThreatHunts(threatHunt, debouncedSearchValue)
            })
            .sort(sortThreatHunts)
    }, [threatHuntsWithAssets, debouncedSearchValue])

    // Makes sure the update search value is not executed more than once per 200ms
    const debouncedSearchValueUpdate = debounce(setDebouncedSearchValue, 200)

    const onChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
        debouncedSearchValueUpdate(event.target.value)
    }

    if (isPending) {
        return (
            <ThreatHuntingPageLayout>
                <ThreatHuntingSkeleton />
            </ThreatHuntingPageLayout>
        )
    }

    if (hasVisitedThreatHuntingIntroduction === false) {
        return (
            <ThreatHuntingPageLayout>
                <ThreatHuntingIntroduction
                    onCompleteIntroduction={() => {
                        setHasVisitedThreatHuntingIntroduction(true)
                        localStorageSettings.setHasVisitedThreatHuntingIntroduction()
                    }}
                    hasThreatHuntingData={showThreatHuntsTable || showThreatHuntsWithAssetsTable}
                />
            </ThreatHuntingPageLayout>
        )
    }

    if (showEmptyDataMessage) {
        return (
            <ThreatHuntingPageLayout>
                <NoDataMessage
                    title={t("threatHunting:detailPage.messages.noDataTitle")}
                    subtitle={t("threatHunting:detailPage.messages.noDataSubtitle")}
                    variant="row"
                    image={<AwaitingDataIllustration className="h-[250px]" />}
                />
            </ThreatHuntingPageLayout>
        )
    }

    return (
        <ThreatHuntingPageLayout>
            <section>
                <div className="flex ">
                    <TextInput
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        className="max-w-[325px] min-w-[300px]"
                        iconLocation="start"
                        icon={<MagnifyingGlassIcon width={20} />}
                        placeholder={t("threatHunting:searchPlaceholder")}
                        data-testid="threat-hunting-search-input"
                    />
                </div>

                {isPending && (
                    <div>
                        <ThreatHuntingSkeleton />
                    </div>
                )}

                {showEmptyDataMessage && (
                    <NoDataMessage
                        title={t("detections.messages.noDataTitle")}
                        subtitle={t("detections.messages.noDataSubtitle")}
                        variant="row"
                        image={<AwaitingDataIllustration className="h-[250px]" />}
                    />
                )}

                <div className="space-y-12 mt-5">
                    {showThreatHuntsWithAssetsTable ? (
                        <div>
                            <Collapse
                                defaultOpen
                                title={
                                    <Typography variant="body-1-semibold">
                                        {t("threatHunting:requireAttention", {
                                            count: filteredThreatHuntsWithAssets.length,
                                        })}
                                    </Typography>
                                }
                                buttonClassName="flex flex-row-reverse items-center mb-2"
                            >
                                <ThreatHuntTable
                                    threatHunts={filteredThreatHuntsWithAssets}
                                    type="REQUIRE_ATTENTION_TABLE"
                                />
                            </Collapse>
                        </div>
                    ) : (
                        <div className="bg-background-page-secondary-light flex items-center justify-center p-12 rounded-md">
                            <Typography>{t("threatHunting:tablePlaceholder")}</Typography>
                        </div>
                    )}

                    {showThreatHuntsTable && (
                        <div>
                            <Collapse
                                defaultOpen
                                title={
                                    <Typography variant="body-1-semibold">
                                        {t("threatHunting:otherHunts", { count: filteredThreatHunts.length })}
                                    </Typography>
                                }
                                buttonClassName="flex flex-row-reverse items-center mb-2"
                            >
                                <ThreatHuntTable threatHunts={filteredThreatHunts} type="OTHER_HUNTS_TABLE" />
                            </Collapse>
                        </div>
                    )}
                </div>
            </section>
        </ThreatHuntingPageLayout>
    )
}
