import { IThreatHunt, IThreatHuntWithAssets } from "@features/threatHunting/threatHuntingTypes"
import { addHoursToTimestamp, getHoursMinutesBetweenNowAndTimestamp, isDateInFuture } from "./dateUtils"
import i18n from "../i18next"

export const HOURS_TO_FIX_HUNT = 48
export const OVERDUE_INDICATOR = "OVERDUE"

/**
 * Filters threat hunts based on the search value.
 * Currently filters on name and CVE ID.
 * @param threatHunt
 * @param searchValue
 * @returns
 */
export const filterThreatHunts = (threatHunt: IThreatHunt, searchValue: string) => {
    const matchesName = threatHunt.name.toLowerCase().includes(searchValue.toLowerCase())
    const matchesCVE = threatHunt.cve?.cveId?.toLowerCase().includes(searchValue.toLowerCase())

    return matchesName || matchesCVE
}

export const getInsuranceLabelText = (startDate: string) => {
    const dueDate = addHoursToTimestamp(startDate, HOURS_TO_FIX_HUNT)

    // In case the date could not be parsed ( should not happen )
    if (!dueDate) {
        return null
    }

    if (isDateInFuture(dueDate)) {
        const timeDifference = getHoursMinutesBetweenNowAndTimestamp(dueDate)
        if (timeDifference.hours > 0) {
            return `${i18n.t("threatHunting:table.insurance.hoursLeft", { hours: timeDifference.hours.toFixed() })}`
        }

        if (timeDifference.minutes >= 1) {
            return `${i18n.t("threatHunting:table.insurance.minutesLeft", { minutes: timeDifference.minutes.toFixed() })}`
        }
    }

    return OVERDUE_INDICATOR
}

export const getAcknowledgeStatusText = (threatHunt: IThreatHunt | IThreatHuntWithAssets) => {
    if ("assets" in threatHunt) {
        return threatHunt.acknowledged
            ? i18n.t("threatHunting:table.data.acknowledged")
            : i18n.t("threatHunting:table.data.notAcknowledged")
    }

    return threatHunt.acknowledged
        ? i18n.t("threatHunting:table.data.acknowledged")
        : i18n.t("threatHunting:table.data.notApplied")
}
