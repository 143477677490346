export const LOCAL_STORAGE_ACTIVE_EYED_KEY = "ACTIVE_EYED"
export const LOCAL_STORAGE_VISITED_AWARENESS_KEY = "VISITED_AWARENESS"
export const LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION = "CLOSED_EAST_NOTIFICATION"
export const LOCAL_STORAGE_HOTSPOT_PREFIX = "HOTSPOT_VISITED_"
export const LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION = "SKIP_ACKNOWLEDGEMENT_CONFIRMATION"
export const LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION = "VISITED_THREAT_HUNTING_INTRODUCTION"
export const LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL = "COMPLETED_INSURANCE_UPSELL"
export const LOCAL_STORAGE_REVIEWED_MS_TENANTS = "REVIEWED_MS_TENANTS"

const setHasVisitedAwareness = () => {
    localStorage.setItem(LOCAL_STORAGE_VISITED_AWARENESS_KEY, "true")
}

const getHasVisitedAwareness = (): boolean => {
    const hasVisitedAwareness = localStorage.getItem(LOCAL_STORAGE_VISITED_AWARENESS_KEY)
    // Local storage is always stringified so we have to check if the stringified boolean exists.
    if (hasVisitedAwareness === "true") {
        return true
    }

    return false
}

const setHasClosedEastNotification = () => {
    localStorage.setItem(LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION, "true")
}

const getHasClosedEastNotification = (): boolean => {
    const hasClosedEastNotification = localStorage.getItem(LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION)

    if (hasClosedEastNotification === "true") {
        return true
    }

    return false
}

const setHotspotVisited = (hotspotId: string) => {
    localStorage.setItem(LOCAL_STORAGE_HOTSPOT_PREFIX + hotspotId, "true")
}

const getHotspotVisited = (hotspotId: string): boolean => {
    const hotspotVisited = localStorage.getItem(LOCAL_STORAGE_HOTSPOT_PREFIX + hotspotId)

    return hotspotVisited === "true"
}

const getSkipAcknowledgeConfirmation = (): boolean => {
    const skipAcknowledgeConfirmation = localStorage.getItem(LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION)

    return skipAcknowledgeConfirmation === "true"
}

const setSkipAcknowledgeConfirmation = (skip: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION, `${skip}`)
}

const getHasVisitedThreatHuntingIntroduction = (): boolean => {
    const visitedIntroduction = localStorage.getItem(LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION)

    return visitedIntroduction === "true"
}

const setHasVisitedThreatHuntingIntroduction = () => {
    localStorage.setItem(LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION, "true")
}

const getHasCompletedInsuranceUpsell = (): boolean => {
    const visitedIntroduction = localStorage.getItem(LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL)

    return visitedIntroduction === "true"
}

const setHasCompletedInsuranceUpsell = () => {
    localStorage.setItem(LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL, "true")
}

const getHasReviewedMSTenants = (): boolean => {
    const reviewedTenants = localStorage.getItem(LOCAL_STORAGE_REVIEWED_MS_TENANTS)

    return reviewedTenants === "true"
}

const setHasReviewedMsTenants = () => {
    localStorage.setItem(LOCAL_STORAGE_REVIEWED_MS_TENANTS, "true")
}

export const localStorageSettings = {
    getHasVisitedThreatHuntingIntroduction,
    setHasVisitedThreatHuntingIntroduction,
    getSkipAcknowledgeConfirmation,
    setSkipAcknowledgeConfirmation,
    getHasVisitedAwareness,
    setHasVisitedAwareness,
    getHasClosedEastNotification,
    setHasClosedEastNotification,
    getHotspotVisited,
    setHotspotVisited,
    getHasCompletedInsuranceUpsell,
    setHasCompletedInsuranceUpsell,
    getHasReviewedMSTenants,
    setHasReviewedMsTenants,
}
