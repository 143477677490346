import { designTokens } from "./designTokens"

/**
 * Tailwind itself does not support dynamic class names.
 * This is described here: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 *
 * But sometimes dynamic class names can come in quite handy, especially in combination with our design tokens & dark mode support.
 *
 * There is functionality within tailwind to "safelist" classes that might be used dynamically, so if you know ahead of time which dynamic classes you will be using you can set those up.
 * https://tailwindcss.com/docs/content-configuration#safelisting-classes
 *
 *Below are some functions which create classes that we can add to the tailwind safelist in the tailwind.config.ts file.
 */

//  These tokens can be created dynamically in the <Typography> component ( it creates light/dark mode classes automatically given the text-style prop. )
export const whitelistedTextTokens = Object.keys(designTokens.textTokens).flatMap((key) => [
    `text-${key}-light`,
    `dark:text-${key}-dark`,
])

/**
 * Takes in a color and spits out the appropriate text color class, including the dark mode modifier.
 * This is dependant on the safelisted classes as created by the whitelistedTextTokens function.
 * @param color a typography text color class as defined in the design tokens.
 * @returns a tailwind class.
 */
export const buildThemedTextColorClass = (color: string): string => {
    return `text-${color}-light dark:text-${color}-dark`
}
