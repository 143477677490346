import { Button } from "@designSystem/buttons/Button"
import React, { useContext } from "react"
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid"
import { useDownloadManagementReport } from "../hooks/useDownloadManagementReport"
import { useActiveEyed } from "@hooks/useActiveEyed"
import DropDown from "@designSystem/overlays/Dropdown/Dropdown"
import { DropdownPanel } from "@designSystem/overlays/Dropdown/DropdownPanel"
import { DropDownListItem } from "@designSystem/overlays/Dropdown/DropdownListItem"
import { LANGUAGE_CONFIG } from "@config/languageConfig"
import { UserContext } from "@/providers/UserContext"

interface Props {
    languages: string[]
    date: string
}

export const DownloadScheduledReportButton: React.FC<Props> = ({ date, languages }) => {
    const { mutate, isPending } = useDownloadManagementReport()
    const activeEyed = useActiveEyed()
    const { activeOrganisation } = useContext(UserContext)

    return (
        <DropDown
            buttonType="div"
            button={
                <Button
                    variant="text"
                    isLoading={isPending}
                    disabled={isPending}
                    withPadding={false}
                    startIcon={<ArrowDownTrayIcon width={20} />}
                ></Button>
            }
            dropdownPositioning="fixed"
        >
            {(onClosePanel) => {
                return (
                    <DropdownPanel>
                        {languages.map((language) => {
                            const availableLanguage = LANGUAGE_CONFIG.managementReportingLanguages.find(
                                (lang) => lang.abbreviation.toLowerCase() === language.toLowerCase(),
                            )

                            return (
                                <DropDownListItem
                                    key={`${language}-scheduled-report`}
                                    onClick={() => {
                                        mutate({
                                            date,
                                            eyed: activeEyed,
                                            language,
                                            organisationName: activeOrganisation?.name,
                                        })
                                        onClosePanel()
                                    }}
                                >
                                    {availableLanguage ? availableLanguage.label : language}
                                </DropDownListItem>
                            )
                        })}
                    </DropdownPanel>
                )
            }}
        </DropDown>
    )
}
