import { Toggle } from "@designSystem/inputs/Toggle"
import { useState } from "react"
import { IRecipient } from "../awarenessTypes"
import { useAuth0 } from "@auth0/auth0-react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { patchRequest } from "@/api/requests"
import { getAwarenessRecipientsEndpoint, patchAwarenessRecipientEndpoint } from "@/api/endpoints"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useTranslation } from "react-i18next"
import Modal from "@designSystem/overlays/modal/Modal"
import ContactIllustration from "@/assets/illustrations/contactIllustration.svg?react"
import { Typography } from "@designSystem/typography/Typography"
import { Button } from "@designSystem/buttons/Button"
import { ModalFooter } from "@designSystem/overlays/modal/ModalFooter"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { toastService } from "@/services/toastService"
interface Props {
    recipient: IRecipient
}

export const RecipientToggle: React.FC<Props> = ({ recipient }) => {
    const [isActive, setIsActive] = useState(recipient.isActive)
    const [isWarningModalActive, setIsWarningModalActive] = useState(false)
    const queryClient = useQueryClient()
    const { getAccessTokenSilently } = useAuth0()
    const activeEyed = useActiveEyed()
    const { t } = useTranslation()
    const { captureEvent } = useCapturePostHogEvent()

    const { mutate } = useMutation({
        mutationFn: (variables: { isActive: boolean }) => {
            const recipientsPatchEndpoint = patchAwarenessRecipientEndpoint(activeEyed, recipient.id)

            return patchRequest<{ isActive: boolean }, { data: IRecipient }>(
                recipientsPatchEndpoint.url,
                variables,
                getAccessTokenSilently,
            )
        },
        onMutate: (data) => {
            // When the mutation starts, update the active value to show the toggle changes
            // We can revert this in the onError callback.
            setIsActive(data.isActive)
            // capture the event through posthog analytics.
            captureEvent(POSTHOG_CAPTURE_TYPES.AWARENESS.TOGGLE_RECIPIENT_EVENT)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: getAwarenessRecipientsEndpoint(activeEyed).queryKey })
            toastService.successMessage(t("awareness.recipientInfo.table.update.updateSuccess"))
        },
        onError: (_, variables) => {
            // Return the state to it's previous value ( the updated wasn't succesfully saved to the server )
            setIsActive(!variables.isActive)
            toastService.errorMessage(t("awareness.recipientInfo.table.update.updateError"))
        },
    })

    return (
        <div>
            <Modal open={isWarningModalActive} setOpen={setIsWarningModalActive} withCloseButton>
                <div className="w-[460px] p-10">
                    <div className="flex justify-center mb-4">
                        <ContactIllustration />
                    </div>

                    <Typography variant="header-2">{t("awareness.recipientInfo.deactivateModal.title")}</Typography>
                    <Typography color="text-secondary" className="mt-4">
                        {t("awareness.recipientInfo.deactivateModal.message")}
                    </Typography>
                </div>

                <ModalFooter>
                    <div className="flex justify-end items-center space-x-4">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsWarningModalActive(false)
                            }}
                        >
                            {t("common:cancel")}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setIsWarningModalActive(false)
                                mutate({ isActive: !isActive })
                            }}
                        >
                            {t("common:yes")}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>

            <Toggle
                testId="recipient-active-toggle"
                enabled={isActive}
                setEnabled={() => {
                    if (isActive) {
                        // When toggling from active > inactive, we should show the warning modal
                        setIsWarningModalActive(true)
                    } else {
                        // When toggling between inactive > active we can mutate directly.
                        mutate({ isActive: !isActive })
                    }
                }}
            />
        </div>
    )
}
