import React, { useState } from "react"
import { Hotspot } from "./Hotspot"
import { IHotspotProps } from "./hotspotTypes"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { useApplicationStore } from "@/stores/applicationStore"
import { localStorageSettings } from "@/localStorage/localStorage"

// This is a special kind of Hotspot component
// It checks a feature flag to determine if it should be showed, and also saves a viewed state in the local storage.
export const IntroductionHotspot: React.FC<IHotspotProps> = ({ buttonText, description, hotspotId, title }) => {
    const featureFlagEnabled = useFeatureFlagEnabled(featureFlagConfig.dashboardHotspotTour)

    const hasClosedEastNotification = useApplicationStore((state) => state.hasClosedEastNotification)
    const eastRecommendationStatus = useApplicationStore((state) => state.eastRecommendationStatus)
    // Check the local storage to see if the hotspot has been visited before.
    const [hotspotVisited, setHotspotVisited] = useState<boolean>(localStorageSettings.getHotspotVisited(hotspotId))

    // Checks if the hotspot has been visited before
    // Checks if the feature flag is disabled
    // If either case applies, we don't show the hotspot.
    if (hotspotVisited === true || featureFlagEnabled !== true) {
        return null
    }

    // The frontend is still fetching recommendations and checking if the east recommendation is available, don't show the hotspots untill then.
    if (eastRecommendationStatus === "PENDING_RECOMMENDATIONS") {
        return null
    }

    // Additional check, if the user has the east recommendation & has not clicked away the popup yet we also hide the hotspots.
    // The hotspots should hide while the east recommendation is visible.
    if (eastRecommendationStatus === "HAS_EAST_RECOMMENDATION" && hasClosedEastNotification === false) {
        return null
    }

    const onHotspotButtonClick = () => {
        setHotspotVisited(true)
        localStorageSettings.setHotspotVisited(hotspotId)
    }

    return (
        <Hotspot
            buttonText={buttonText}
            description={description}
            hotspotId={hotspotId}
            title={title}
            onHotspotButtonClick={onHotspotButtonClick}
        />
    )
}
