import { useActiveEyed } from "@hooks/useActiveEyed"
import { useDashboardMetrics } from "./useDashboardMetrics"
import { useAwarenessMetrics } from "@features/awareness/hooks/useAwarenessMetrics"
import { useRecommendations } from "@features/recommendations/hooks/useRecommendations"
import { useThreatHuntsWithAssets } from "@features/threatHunting/hooks/useThreatHuntsWithAssets"
import { useDetections } from "@features/detections/hooks/useDetections"
import { getValidDateRange } from "@utils/dateRangeUtils"
import { getCurrentTimestamp, getTimestampDaysAgo } from "@utils/dateUtils"
import { useContext, useMemo } from "react"
import { UserContext } from "@/providers/UserContext"
import { isNullish } from "@utils/formatUtils"
import { useAwarenessState } from "@features/awareness/hooks/useAwarenessState"

/**
 * Hook that triggers all queries needed for the dashboard.
 * Then checks all their loading states and data to determine which widgets need to be visible.
 *
 * @returns
 */
export const useDashboardData = () => {
    const activeEyed = useActiveEyed()
    const { language } = useContext(UserContext)
    const awarenessState = useAwarenessState()

    const defaultRange = useMemo(() => {
        return getValidDateRange({
            from: getTimestampDaysAgo(30),
            to: getCurrentTimestamp(),
        })
    }, [])

    // Dashboard Metrics Data
    const dashboardMetricsQuery = useDashboardMetrics(activeEyed)
    // Awareness Data
    const awarenessMetricsQuery = useAwarenessMetrics(activeEyed)
    // Detections Data
    const detectionsQuery = useDetections(activeEyed, defaultRange)
    // Recomzendations Data
    const recommendationsQuery = useRecommendations(activeEyed, language)
    // Threat hunting data
    const threatHuntingQuery = useThreatHuntsWithAssets(activeEyed)

    // Loading state for the entire dashboard
    const isLoadingDashboard =
        dashboardMetricsQuery.isPending ||
        awarenessMetricsQuery.isPending ||
        detectionsQuery.isPending ||
        recommendationsQuery.isPending ||
        threatHuntingQuery.isPending ||
        recommendationsQuery.isPending ||
        threatHuntingQuery.isPending

    const showAwarenessEventsGraph = awarenessMetricsQuery.data?.data && awarenessMetricsQuery.data?.data?.length >= 3

    const dashboardWidgetStates = {
        endpointCoverageWidget: !isNullish(dashboardMetricsQuery.data?.data?.endpoint_coverage),
        diskEncryptionWidget: !isNullish(dashboardMetricsQuery.data?.data?.disk_encryption),
        mfaWidget: !isNullish(dashboardMetricsQuery.data?.data?.mfa),
        recommendationsWidget: true,
        detectionsWidget: true,
        awarenessGraphWidget: awarenessState.showAwarenessWidget && showAwarenessEventsGraph,
        awarenessMetricWidget: awarenessState.showAwarenessWidget && showAwarenessEventsGraph === false,
        threatHuntingWidget: true,
    }

    return { dashboardWidgetStates, isLoadingDashboard }
}
