import { DateTime } from "luxon"

// Preferably this file is the only place where Luxon is being used.
// The functions act as a public api wrapper for Luxon, this way we can easily test all the date functionality in one place.

// BACKEND DATE FORMAT: ISO STRING ( ISO 8601 / RFC3339 )
// 2023-06-28T15:01:00Z
// 2023-06-01T10:28:23Z

export const EYE_BIRTH_YEAR = 2020

// Luxon date formats
const DATE_TIME_FORMAT_ZONED = "dd-LL-yyyy HH:mm ZZZZ" // 16-03-2023 20:31 GMT+1
const DATE_TIME_FORMAT = "dd-LL-yyyy HH:mm" // 16-03-2023 20:31
const DATE_FORMAT = "dd-LL-yyyy" // 16-03-2023
const DATE_TIME_FORMAT_DETECTIONS_CACHE = "dd-LL-yyyy HH" // 16-03-2023 20 -> cache by date/hour

export const DATE_FORMATS = {
    DATE_TIME_FORMAT_ZONED,
    DATE_TIME_FORMAT,
    DATE_FORMAT,
    DATE_TIME_FORMAT_DETECTIONS_CACHE,
}

/**
 * Returns a date representation relative to now.
 * So it returns "2 hours ago", "last month", "next month", etc.
 * @param timestamp
 * @returns
 */
export const getRelativeDateRepresentationToNow = (timestamp: string): string | null => {
    return DateTime.fromISO(timestamp).toRelative({ locale: "en" })
}

export const formatDate = (timestamp: string, format: string = DATE_TIME_FORMAT_ZONED): string => {
    return DateTime.fromISO(timestamp).toFormat(format)
}

export const isDateValid = (timestamp: string): boolean => {
    return DateTime.fromISO(timestamp).isValid
}

export const formatToUTC = (timestamp: string): string => {
    return DateTime.fromISO(timestamp).toUTC().toFormat(DATE_TIME_FORMAT_ZONED)
}

export const getCurrentTimestamp = (): string => {
    return DateTime.now().toISO() ?? ""
}

export const getTimestampDaysAgo = (daysAgo: number): string => {
    return DateTime.now().minus({ days: daysAgo }).toISO() ?? ""
}

export const getJSDateFromTimestamp = (timestamp: string): Date => {
    return DateTime.fromISO(timestamp).toJSDate()
}

export const getTimestampFromJSDate = (date: Date): string => {
    return DateTime.fromJSDate(date).toISO() ?? ""
}

export const getStartofDayTimestampFromDate = (date: Date): string => {
    return DateTime.fromJSDate(date).startOf("day").toISO() ?? ""
}

export const getEndofDayTimestampFromDate = (date: Date): string => {
    return DateTime.fromJSDate(date).endOf("day").toISO() ?? ""
}

export const getDeconstructedISODate = (timestamp: string) => {
    return DateTime.fromISO(timestamp).toObject()
}

export const formatYearMonthToMonth = (yearMonth: string): string => {
    return DateTime.fromFormat(yearMonth, "yyyy-LL").toFormat("LLLL")
}

export const formatYearMonth = (yearMonth: string): string => {
    return DateTime.fromFormat(yearMonth, "yyyy-LL").toFormat("LLLL y")
}

export const getCurrentYearMonth = (): string => {
    return DateTime.now().toFormat("yyyy-LL")
}

export const addHoursToTimestamp = (timestamp: string, hours: number) => {
    return DateTime.fromISO(timestamp).plus({ hours }).toISO()
}

/**
 * Takes a timestamp and compares it to the current time.
 * Then returns the difference in hours.
 * A positive number ->  in the future
 * A negative number ->  in the past
 * @param timestamp
 * @returns
 */
export const getHoursMinutesBetweenNowAndTimestamp = (timestamp: string) => {
    const now = DateTime.now()
    const dateTime = DateTime.fromISO(timestamp)

    const difference = dateTime.diff(now, ["hours", "minutes"])

    return { hours: difference.hours, minutes: difference.minutes }
}

export const buildLocalISODate = (year: number, month: number, day: number): string | null => {
    const date = DateTime.local(year, month, day)
    if (date && date.isValid && date.year) {
        return date.toISO()
    }

    return null
}

export const isDateInFuture = (date: string) => {
    const now = DateTime.now()

    if (DateTime.fromISO(date) > now) {
        return true
    }

    return false
}

export const isDateBeforeEyeBirthday = (date: string) => {
    if (DateTime.fromISO(date).year < EYE_BIRTH_YEAR) {
        return true
    }

    return false
}
