import React from "react"
import { useTranslation } from "react-i18next"
import { useAwarenessState } from "../hooks/useAwarenessState"
import Tippy from "@tippyjs/react"
import { Typography } from "@designSystem/typography/Typography"

export const AwarenessLabel: React.FC = () => {
    const { showIsAwarenessNewLabel, hasFinishedAwarenessOnboarding, hasAwareness } = useAwarenessState()

    const { t } = useTranslation()

    if (showIsAwarenessNewLabel) {
        return <div className="bg-brand-eye-orange rounded-xl px-2 text-xs text-invert">{t("common:new")}</div>
    }

    if (hasAwareness && hasFinishedAwarenessOnboarding === false) {
        return (
            <Tippy
                arrow={false}
                content={
                    <div className="dark">
                        <Typography variant="body-2-semibold">
                            {t("navigation:Awareness.OnboardingMessage.title")}
                        </Typography>
                        <Typography variant="body-2">
                            {t("navigation:Awareness.OnboardingMessage.description")}
                        </Typography>
                    </div>
                }
            >
                <div className="rounded-full w-2 h-2 bg-brand-eye-orange animate-pulse"></div>
            </Tippy>
        )
    }

    if (!hasAwareness) {
        return (
            <div className="bg-background-brand-hover-light rounded-md p-1">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9 11.25V12.75M4.5 15.75H13.5C14.3284 15.75 15 15.0784 15 14.25V9.75C15 8.92157 14.3284 8.25 13.5 8.25H4.5C3.67157 8.25 3 8.92157 3 9.75V14.25C3 15.0784 3.67157 15.75 4.5 15.75ZM12 8.25V5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25V8.25H12Z"
                        stroke="white"
                        strokeLinecap="round"
                    />
                </svg>
            </div>
        )
    }

    return null
}
