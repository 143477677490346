import {
    IRecommendation,
    IRecommendationPriority,
    MFAPolicy,
    PolicyStatus,
    UserMFAInfo,
} from "@features/recommendations/recommendationTypes"

export const sortPoliciesTableData = (policies: MFAPolicy[]) => {
    const statusses: PolicyStatus[] = ["Bad", "Neutral", "Good"]

    return policies.sort((left, right) => {
        // First sort on tenant
        if (left.tenant > right.tenant) {
            return 1
        }

        if (left.tenant < right.tenant) {
            return -1
        }

        // Then sort on status, in order as defined above
        if (statusses.indexOf(left.status) > statusses.indexOf(right.status)) {
            return 1
        }

        if (statusses.indexOf(left.status) < statusses.indexOf(right.status)) {
            return -1
        }

        // Then sort on name
        if (left.name > right.name) {
            return 1
        }

        if (left.name < right.name) {
            return -1
        }

        // Otherwise treat them as equals
        return 0
    })
}

export const sortUserInfoTable = (userInfo: UserMFAInfo[]) => {
    return userInfo.sort((left, right) => {
        // Sort on tenant first

        if (left.tenant && right.tenant && left.tenant > right.tenant) {
            return 1
        }

        if (left.tenant && right.tenant && left.tenant < right.tenant) {
            return -1
        }

        // Then sort by account type ( admin > user, this can simply be alphabetically sorted)
        if (left.account_type > right.account_type) {
            return 1
        }

        if (left.account_type < right.account_type) {
            return -1
        }

        // Then sort on status text, alphabetically.
        if (left.status > right.status) {
            return 1
        }

        if (left.status < right.status) {
            return -1
        }

        // Lastly sort on username
        if (left.username?.toLowerCase() > right.username?.toLowerCase()) {
            return 1
        }

        if (left.username?.toLowerCase() < right.username?.toLowerCase()) {
            return -1
        }

        return 0
    })
}

export const sortRecommendationsByPriority = (recommendationA: IRecommendation, recommendationB: IRecommendation) => {
    const priority: Record<IRecommendationPriority, number> = {
        Critical: 1,
        Medium: 2,
        Low: 3,
        Unknown: 4,
    }

    return priority[recommendationA.priority] - priority[recommendationB.priority]
}

export const sortRecommendationsArrayByPriority = (recommendations: IRecommendation[]) => {
    return recommendations.sort(sortRecommendationsByPriority)
}
