import Modal from "./Modal"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import { EyeElementBackground } from "../../../header/EyeHeaderBackground"
import { useMutation } from "@tanstack/react-query"
import { postRequest } from "../../../../api/requests"
import { useAuth0 } from "@auth0/auth0-react"
import { AppRating, RatingInput } from "../../inputs/ratingInput/RatingInput"
import { useTranslation } from "react-i18next"
import { Typography } from "../../typography/Typography"
import { TextArea } from "../../inputs/TextArea"
import { Button } from "@designSystem/buttons/Button"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { getFeedbackEndpoint } from "@/api/endpoints"
import { toastService } from "@/services/toastService"

interface Props {
    open: boolean
    setOpen: (open: boolean) => void
    hideRating?: boolean
    subject?: string
}

interface FeedbackData {
    name: string
    email: string
    eyed: string
    message: string
    score: AppRating
    url: string
}

const formatFeedback = (text: string, subject: string | undefined): string => {
    if (subject) {
        return `${subject} \n${text}`
    }

    return text
}

const FeedbackModal: React.FC<Props> = ({ open, setOpen, hideRating = false, subject }) => {
    const [feedbackText, setFeedbackText] = useState("")
    const { getAccessTokenSilently, user } = useAuth0()
    const activeEyed = useActiveEyed()
    const [rating, setRating] = useState<AppRating>(3)
    const { t } = useTranslation()

    const resetForm = (): void => {
        // clear the form
        setFeedbackText("")
        setRating(3)
        setOpen(false)
    }

    const { mutate, isPending } = useMutation({
        mutationFn: (feedback: FeedbackData) => {
            const feedbackEndpoint = getFeedbackEndpoint()
            return postRequest(feedbackEndpoint.url, feedback, getAccessTokenSilently)
        },
        onSuccess: () => {
            toastService.successMessage(t("toastMessages:feedback.success"))
            resetForm()
        },
        onError: () => {
            toastService.errorMessage(t("toastMessages:feedback.error"))
            // If this errors, there's no major problem to be handled for the user. We can simply close the modal.
            setOpen(false)
        },
    })

    return (
        <Modal open={open} setOpen={setOpen} className="">
            <div className="relative overflow-hidden rounded-xl">
                <EyeElementBackground className="absolute top-6 right-8 scale-[2.5] -z-10" />
                <div className="max-h-[90vh] overflow-auto z-20 ">
                    <button className="absolute top-4 right-4 hover:stroke-neutral-300" onClick={() => setOpen(false)}>
                        <XMarkIcon className="h-8 w-6 fill-brand-eye-blue" />
                    </button>

                    <div className="p-12 z-20">
                        <Typography variant="header-3" className="text-left">
                            {t("feedback.title")}
                        </Typography>

                        <div className="mt-6 space-y-4">
                            {hideRating === false && (
                                <div>
                                    <p className="text-left font-semibold font-sans mb-2">{t("feedback.question1")}</p>
                                    <RatingInput rating={rating} setRating={setRating} />
                                </div>
                            )}

                            <div className="flex flex-col">
                                <p className="text-left font-semibold font-sans mb-2">{t("feedback.question2")}</p>
                                {subject && (
                                    <div className="mb-1">
                                        <Typography variant="body-1" className="text-left">
                                            {subject}
                                        </Typography>
                                    </div>
                                )}

                                <TextArea
                                    placeholder="I want you to know that..."
                                    value={feedbackText}
                                    className="resize-none flex-1 w-[100%] lg:w-[500px] min-h-[120px]"
                                    onChange={(event) => {
                                        setFeedbackText(event.target.value)
                                    }}
                                />
                            </div>

                            <div className="flex space-x-2">
                                <Button
                                    disabled={isPending}
                                    isLoading={isPending}
                                    variant="primary"
                                    size="small"
                                    onClick={() => {
                                        mutate({
                                            name: user?.name ?? "",
                                            email: user?.email ?? "",
                                            eyed: activeEyed,
                                            message: formatFeedback(feedbackText, subject),
                                            score: rating,
                                            url: window.location.href,
                                        })
                                    }}
                                >
                                    {t("pages:feedback.send")}
                                </Button>

                                <Button variant="secondary" size="small" onClick={() => setOpen(false)}>
                                    {t("feedback.cancel")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default FeedbackModal
