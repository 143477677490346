import React from "react"
import { Switch } from "@headlessui/react"

interface Props {
    enabled: boolean
    setEnabled: (enabled: boolean) => void
    label?: string | React.ReactNode
    testId?: string
    className?: string
    toggleDisabled?: boolean
}

export const Toggle: React.FC<Props> = ({
    enabled,
    setEnabled,
    label,
    testId,
    className = "",
    toggleDisabled = false,
}) => {
    return (
        <Switch.Group>
            <div className="flex items-center">
                <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    disabled={toggleDisabled}
                    data-testid={testId}
                    className={`${
                        enabled ? "bg-brand-eye-orange" : "bg-neutral-300"
                    } ${className} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
                >
                    <span
                        className={`${
                            enabled ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                </Switch>
                {label && <Switch.Label className="ml-4">{label}</Switch.Label>}
            </div>
        </Switch.Group>
    )
}
