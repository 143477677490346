import { NumberIndicator } from "@designSystem/display/NumberIndicator"
import { Collapse } from "@designSystem/layout/Collapse"
import { Typography } from "@designSystem/typography/Typography"
import React from "react"

interface Props {
    number: number
    title: string
    content: React.ReactNode
}

export const SentinelOnboardingStep: React.FC<Props> = ({ content, number, title }) => {
    return (
        <section>
            <div className="border rounded-md">
                <Collapse
                    defaultOpen
                    title={
                        <div className="steps-onboarding-title">
                            <NumberIndicator>
                                <Typography variant="body-2-semibold">{number}</Typography>
                            </NumberIndicator>
                            <Typography variant="body-1-semibold">{title}</Typography>
                        </div>
                    }
                    buttonClassName="steps-onboarding-button"
                >
                    <div className="px-5 pb-5">
                        {/* Spacing left: 8 width of rounded indicator + for of the flex gap. */}
                        <div className="ml-12 space-y-4">{content}</div>
                    </div>
                </Collapse>
            </div>
        </section>
    )
}
