import { PRIVACY_POLICY_LINK } from "@/config/linkConfig"
import { useApplicationStore } from "@/stores/applicationStore"
import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "@designSystem/buttons/Button"
import FeedbackModal from "@designSystem/overlays/modal/FeedbackModal"
import { Typography } from "@designSystem/typography/Typography"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

export const AccountSettings: React.FC = () => {
    const { t } = useTranslation(["common"])
    const { user } = useAuth0()
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)
    const setShowConsentBanner = useApplicationStore((state) => state.setShowConsentBanner)

    return (
        <div>
            <div className="relative p-6 flex border-b">
                <div className="flex flex-col sm:flex-row gap-8 text-left">
                    <div>
                        <Typography variant="header-5">{t("common:userMenu.account.name")}</Typography>
                        <Typography>{user?.name}</Typography>
                    </div>
                    <div>
                        <Typography variant="header-5">{t("common:userMenu.account.email")}</Typography>
                        <Typography>{user?.email}</Typography>
                    </div>
                </div>
            </div>

            <div className="p-6">
                <FeedbackModal open={isFeedbackOpen} setOpen={() => setIsFeedbackOpen(false)} />

                <div className={`flex gap-4 items-center flex-col sm:flex-row`}>
                    <Button variant="text" size="small" withPadding={false} onClick={() => setIsFeedbackOpen(true)}>
                        {t("common:userMenu.account.feedback")}
                    </Button>

                    <Button variant="text" size="small" withPadding={false} onClick={() => setIsFeedbackOpen(true)}>
                        {t("common:userMenu.account.bugReport")}
                    </Button>

                    <Button variant="text" size="small" withPadding={false} onClick={() => setShowConsentBanner(true)}>
                        {t("common:userMenu.account.cookiePreferences")}
                    </Button>

                    <a
                        rel="noopener noreferrer"
                        href={PRIVACY_POLICY_LINK}
                        target="_blank"
                        className="hover:underline pb-1"
                    >
                        <Button variant="text" size="small" withPadding={false}>
                            {t("common:userMenu.account.privacyPolicy")}
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    )
}
