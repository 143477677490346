import { Typography } from "@designSystem/typography/Typography"
import { IAzureTenant } from "@features/accountSwitcher/hooks/useUserOrganisations"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { SentinelOnboardingStep } from "./steps/SentinelOnboardingStep"
import { Toggle } from "@designSystem/inputs/Toggle"
import { Button } from "@designSystem/buttons/Button"
import { SentinelInfoBlock } from "./SentinelInfoBlock"

interface Props {
    showTenants: boolean
    tenants: IAzureTenant[]
    onMarkAsDone: () => void
}

export const SentinelChecklistContent: React.FC<Props> = ({ showTenants, tenants, onMarkAsDone }) => {
    const { t } = useTranslation()
    const [confirmedTenants, setConfirmedTenants] = useState(false)
    const [grantedPermission, setGrantedPermission] = useState(false)

    return (
        <div className="mt-8 flex flex-col lg:flex-row gap-4">
            <div className="space-y-4 flex-1">
                {showTenants && (
                    <div className="bg-background-page-secondary-light p-5 rounded-md border border-text-link-light">
                        <Typography variant="body-2-semibold">
                            <Trans i18nKey="sentinelOnboarding:sections.tenantsBlock.title" />
                        </Typography>

                        <div>
                            <ul className="list-disc ml-6">
                                {tenants.map((tenant) => (
                                    <li key={`tenant-${tenant.id}`}>
                                        <Typography variant="body-2">{tenant.name}</Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}

                <SentinelOnboardingStep
                    number={1}
                    title={t("sentinelOnboarding:sections.accountCreation.title")}
                    content={
                        <Typography variant="body-2">
                            <Trans
                                i18nKey="sentinelOnboarding:sections.accountCreation.step1"
                                components={[
                                    <a
                                        href="https://azure.microsoft.com/"
                                        key="azure-link"
                                        className="inline underline text-text-link-light"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />,
                                ]}
                            />
                        </Typography>
                    }
                />

                <SentinelOnboardingStep
                    number={2}
                    title={t("sentinelOnboarding:sections.createProfile.title")}
                    content={
                        <Typography variant="body-2">
                            <Trans i18nKey="sentinelOnboarding:sections.createProfile.step1" />
                        </Typography>
                    }
                />

                <SentinelOnboardingStep
                    number={3}
                    title={t("sentinelOnboarding:sections.verifyCreditCard.title")}
                    content={
                        <div className="space-y-4">
                            <Typography variant="body-2">
                                <Trans i18nKey="sentinelOnboarding:sections.verifyCreditCard.step1" />
                            </Typography>

                            <div>
                                <Typography variant="body-2-semibold">
                                    <Trans i18nKey="sentinelOnboarding:sections.verifyCreditCard.step2Title" />
                                </Typography>
                                <Typography variant="body-2">
                                    <Trans i18nKey="sentinelOnboarding:sections.verifyCreditCard.step2" />
                                </Typography>
                            </div>

                            <div>
                                <Typography variant="body-2-semibold">
                                    <Trans i18nKey="sentinelOnboarding:sections.verifyCreditCard.step3Title" />
                                </Typography>
                                <Typography variant="body-2">
                                    <Trans
                                        i18nKey="sentinelOnboarding:sections.verifyCreditCard.step3"
                                        components={[
                                            <a
                                                key="support-link"
                                                className="inline underline text-text-link-light"
                                                href="mailto:info@eye.security"
                                            />,
                                        ]}
                                    />
                                </Typography>
                            </div>
                        </div>
                    }
                />

                <SentinelOnboardingStep
                    number={4}
                    title={t("sentinelOnboarding:sections.addSubscription.title")}
                    content={
                        <Typography variant="body-2">
                            <Trans
                                i18nKey="sentinelOnboarding:sections.addSubscription.step1"
                                components={[
                                    <a
                                        href="https://portal.azure.com/?utm_source=hs_email&utm_medium=email/Microsoft_Azure_Billing/CatalogBlade/appId/AddSubscriptionButton"
                                        key="support-link"
                                        className="inline underline text-text-link-light"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />,
                                ]}
                            />
                        </Typography>
                    }
                />

                <SentinelOnboardingStep
                    number={5}
                    title={t("sentinelOnboarding:sections.setupSubscription.title")}
                    content={
                        <Typography variant="body-2">
                            <Trans i18nKey="sentinelOnboarding:sections.setupSubscription.step1" />
                        </Typography>
                    }
                />

                <SentinelOnboardingStep
                    number={6}
                    title={t("sentinelOnboarding:sections.markAsDone.title")}
                    content={
                        <div className="space-y-5">
                            <Typography variant="body-2">
                                <Trans i18nKey="sentinelOnboarding:sections.markAsDone.step1" />
                            </Typography>

                            <div>
                                <Toggle
                                    testId="sentinel-toggle"
                                    enabled={confirmedTenants}
                                    setEnabled={(enabled) => {
                                        setConfirmedTenants(enabled)
                                    }}
                                    label={
                                        <Typography variant="body-2-semibold">
                                            {t("sentinelOnboarding:sections.markAsDone.acknowledge1")}
                                        </Typography>
                                    }
                                />

                                <div className="ml-14">
                                    {showTenants && (
                                        <ul className="list-disc ml-6">
                                            {tenants.map((tenant) => (
                                                <li key={`tenant-${tenant.id}`}>
                                                    <Typography variant="body-2">{tenant.name}</Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <Toggle
                                testId="sentinel-toggle"
                                enabled={grantedPermission}
                                setEnabled={(enabled) => {
                                    setGrantedPermission(enabled)
                                }}
                                label={
                                    <Typography variant="body-2-semibold">
                                        {t("sentinelOnboarding:sections.markAsDone.acknowledge2")}
                                    </Typography>
                                }
                            />

                            <Button
                                variant="primary"
                                size="small"
                                onClick={() => onMarkAsDone()}
                                disabled={confirmedTenants === false || grantedPermission === false}
                            >
                                {t("sentinelOnboarding:sections.markAsDone.button")}
                            </Button>
                        </div>
                    }
                />
            </div>
            <SentinelInfoBlock />
        </div>
    )
}
