import { useTranslation } from "react-i18next"
import { Fragment, useContext } from "react"
import { Alert } from "../components/designSystem/feedback/notifications/Alert"
import { RecommendationSkeleton } from "../components/designSystem/feedback/skeletons/RecomendationSkeleton"
import { PageHeader } from "../components/header/PageHeader"
import RecommendationsTable from "../features/recommendations/components/tables/RecommendationsTable"
import { useRecommendations } from "../features/recommendations/hooks/useRecommendations"
import { UserContext } from "../providers/UserContext"
import { Typography } from "../components/designSystem/typography/Typography"
import { PageLayout } from "../components/layout/PageLayout"
import { TimeAgoDate } from "../components/dates/TimeAgoDate"
import { NoDataMessage } from "../components/designSystem/feedback/notifications/NoDataMessage"
import CoffeeIcon from "@assets/illustrations/coffee.svg"
import { useActiveEyed } from "@hooks/useActiveEyed"

export const Recommendations: React.FC = () => {
    const { language } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    const { t } = useTranslation(["pages"])
    const { isError, error, isPending, data, isSuccess } = useRecommendations(activeEyed, language)
    const recommendations = data?.data ?? []

    if (isPending) {
        return <RecommendationSkeleton />
    }

    return (
        <PageLayout variant="detailPage">
            <PageHeader
                title={t("recommendations.title")}
                subtitle={
                    <div className="flex gap-2 items-center">
                        <Typography variant="body-1-semibold">
                            {t("recommendations.subtitle", { count: recommendations?.length ?? 0 })}
                        </Typography>

                        {data?.meta_data.last_updated && (
                            <Fragment>
                                <div className="h-3 w-[2px] bg-neutral-100" />

                                <TimeAgoDate
                                    prefix={t("details.timestamp_updated")}
                                    timestamp={data?.meta_data.last_updated}
                                />
                            </Fragment>
                        )}
                    </div>
                }
                withBreadcrumbs
            />

            <div className="relative">
                {isError && <Alert type="error" title="Something went wrong" text={error?.message} />}

                {isSuccess && (
                    <>
                        <div className="relative overflow-x-auto">
                            {recommendations.length > 0 ? (
                                <RecommendationsTable data={recommendations} />
                            ) : (
                                <NoDataMessage
                                    title={t("recommendations.messages.noDataTitle")}
                                    subtitle={t("recommendations.messages.noDataSubtitle")}
                                    variant="row"
                                    image={<img className="w-[100px] h-[100px]" src={CoffeeIcon} />}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </PageLayout>
    )
}
