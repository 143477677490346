import { createColumnHelper } from "@tanstack/react-table"
import { IAwarenessEvent } from "../awarenessTypes"
import i18n from "@/i18next"
import { Typography } from "@designSystem/typography/Typography"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import { isNullish, showWithFallbackValue } from "@utils/formatUtils"
import Tippy from "@tippyjs/react"

const columnHelper = createColumnHelper<IAwarenessEvent>()

export const eventsTableColumns = [
    columnHelper.accessor("date", {
        header: i18n.t("pages:awareness.overview.eventsTable.headers.date"),
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping color="text-secondary">
                {formatDate(info.getValue(), DATE_FORMATS.DATE_TIME_FORMAT)}
            </Typography>
        ),
    }),
    columnHelper.accessor("email", {
        header: i18n.t("pages:awareness.overview.eventsTable.headers.recipient"),
        cell: (info) => {
            const value = info.getValue()
            const isDeleted = value === "" || isNullish(value)

            if (isDeleted) {
                return (
                    <Typography textElipsis shouldPreventWrapping color="text-inactive">
                        {i18n.t("pages:awareness.overview.eventsTable.deletedUser")}
                    </Typography>
                )
            }

            return (
                <Typography textElipsis shouldPreventWrapping>
                    {info.getValue()}
                </Typography>
            )
        },
    }),
    columnHelper.accessor("simulation_name", {
        header: i18n.t("pages:awareness.overview.eventsTable.headers.simulationName"),
        cell: (info) => {
            const simulationName = info.getValue()

            if (simulationName && simulationName.length >= 10) {
                return (
                    <Tippy content={info.getValue()}>
                        <Typography textElipsis shouldPreventWrapping color="text-secondary">
                            {info.getValue()}
                        </Typography>
                    </Tippy>
                )
            }

            return (
                <Typography textElipsis shouldPreventWrapping color="text-secondary">
                    {showWithFallbackValue(info.getValue())}
                </Typography>
            )
        },
    }),
]
