import React from "react"
import { ChargebeeDatasource, useChargebeeSession } from "./useChargebeeSession"
import { useActiveEyed } from "@hooks/useActiveEyed"
import NavigationButton from "@components/navigation/navigationItems/NavigationButton"
import { useTranslation } from "react-i18next"
import { BanknotesIcon } from "@heroicons/react/24/outline"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { toastService } from "@/services/toastService"
import { ArrowPathIcon } from "@heroicons/react/24/solid"
import * as Sentry from "@sentry/react"

const fallbackTestId = "eye-security-nl-test"

export const ChargebeeWidget: React.FC = () => {
    const activeEyed = useActiveEyed()
    const { t } = useTranslation()
    const { refetch, isLoading, isFetching } = useChargebeeSession(activeEyed)
    const featureEnabled = useFeatureFlagEnabled(featureFlagConfig.chargebeeWidget)

    const handleChargebeeError = () => {
        Sentry.captureMessage(`Error loading Chargebee widget`)
        toastService.errorMessage(t("navigation:Chargebee.error"))
    }

    const onClickChargebeeButton = async () => {
        try {
            const { data: chargebeeSession } = await refetch()
            if (chargebeeSession) {
                loadChargebeePortal(chargebeeSession)
            } else {
                handleChargebeeError()
            }
        } catch (error) {
            handleChargebeeError()
        }
    }

    const loadChargebeePortal = async (chargeBeeSession: ChargebeeDatasource) => {
        try {
            // @ts-ignore ( ts doesn't know chargebee is available on the window object )
            const chargebee = window.Chargebee.init({
                site: chargeBeeSession.meta_data.chargebeeTenant ?? fallbackTestId,
            })

            chargebee.setPortalSession(chargeBeeSession.data)
            chargebee.createChargebeePortal().open()
        } catch (error) {
            handleChargebeeError()
        }
    }

    if (featureEnabled === false || featureEnabled === undefined) {
        return null
    }

    return (
        <NavigationButton
            onClick={onClickChargebeeButton}
            label={t("navigation:Chargebee.label")}
            contentLeft={<BanknotesIcon width={24} />}
            contentRight={isLoading || isFetching ? <ArrowPathIcon className="animate-spin" width={18} /> : null}
        />
    )
}
