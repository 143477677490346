import { createColumnHelper } from "@tanstack/react-table"
import i18n from "@/i18next"
import { MFAPolicy, PolicyStatus } from "@features/recommendations/recommendationTypes"
import { Typography } from "@designSystem/typography/Typography"
import { ClassificationBadge } from "@designSystem/layout/ClassificationBadge"

const columnHelper = createColumnHelper<MFAPolicy>()

const BASE_COLUMNS = [
    columnHelper.accessor("status", {
        header: i18n.t("pages:recommendations.policiesTable.status"),
        sortingFn: (left, right, column) => {
            const statusses: PolicyStatus[] = ["Bad", "Neutral", "Good"]

            // Then sort on status, in order as defined above
            if (statusses.indexOf(left.getValue(column)) > statusses.indexOf(right.getValue(column))) {
                return 1
            }

            if (statusses.indexOf(left.getValue(column)) < statusses.indexOf(right.getValue(column))) {
                return -1
            }

            return 0
        },
        cell: (info) => <ClassificationBadge status={info.getValue()} />,
        enableHiding: true,
    }),
    columnHelper.accessor("name", {
        header: i18n.t("pages:recommendations.policiesTable.name"),
        cell: (info) => <Typography textElipsis>{info.getValue()}</Typography>,
        enableHiding: true,
    }),
    columnHelper.accessor("details", {
        size: 400,
        header: i18n.t("pages:recommendations.policiesTable.details"),
        cell: (info) => <Typography>{info.getValue()}</Typography>,
        enableHiding: true,
    }),
]

export const getBaseMFAPoliciesV2Columns = () => {
    return [...BASE_COLUMNS]
}

export const getExtendedMFAPoliciesV2Columns = () => {
    return [
        columnHelper.accessor("tenant", {
            header: i18n.t("pages:recommendations.policiesTable.tenant"),
            cell: (info) => <Typography textElipsis>{info.getValue()}</Typography>,
            enableHiding: true,
        }),
        ...BASE_COLUMNS,
    ]
}
