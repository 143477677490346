import React from "react"
import {
    Table,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    SortableTableHeaderCell,
} from "../../../../components/designSystem/layout/table/Table"
import { IRecommendation } from "../../recommendationTypes"
import { getAssetColumns, orderAssetColumns } from "../../../../utils/recommendationUtils"
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { getRecommendationAssetTableColumns } from "./columns/RecommendationAssetColumns"
import { SortableTableHeader } from "@designSystem/layout/table/SortableTableHeader"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"

interface Props {
    assets: IRecommendation["assets"]
}

export const AssetsTable: React.FC<Props> = ({ assets }) => {
    // To prevent re-rendering after re-calculating the columns and the table we have to wrap the functions that determine the columns
    // and table layout in memo's. This is because we don't know beforehand which columns can be shown ( like most other tables can )
    // In those other cases you can define the columns beforehand and it does not need to happen on render time.
    const assetColumns = React.useMemo(() => {
        // This gets a list of unique column names based on the asset data
        return orderAssetColumns(getAssetColumns(assets))
    }, [assets])

    const tableColumns = React.useMemo(() => {
        // Builds the actual columns given the list of unique column names
        return getRecommendationAssetTableColumns(assetColumns)
    }, [assetColumns])

    const assetsData = React.useMemo(() => {
        return Object.values(assets)
    }, [assets])

    const [sorting, setSorting] = React.useState<SortingState>([])

    const table = useReactTable({
        data: assetsData,
        columns: tableColumns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <TablePaginationWrapper isRounded data={assetsData} table={table}>
            <Table isRounded isFixed={false}>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <SortableTableHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader {...header.getContext()}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </SortableTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <TableCell key={cell.id} width={cell.column.getSize()}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}
