import React from "react"
import { Breadcrumbs } from "../designSystem/layout/Breadcrumbs"
import { Typography } from "../designSystem/typography/Typography"

interface HeaderProps {
    title: string
    subtitle?: React.ReactNode
    withBreadcrumbs?: boolean
    gutterBottom?: boolean
    titleRightContent?: React.ReactNode
    contentRight?: React.ReactNode
    bannerContent?: React.ReactNode
}

export const PageHeader: React.FunctionComponent<HeaderProps> = ({
    title,
    subtitle,
    withBreadcrumbs = false,
    gutterBottom = true,
    contentRight,
    titleRightContent,
    bannerContent,
}) => {
    return (
        <div className={`w-full  ${gutterBottom ? "mb-6" : ""}`}>
            {withBreadcrumbs && (
                <div className="mb-6">
                    <Breadcrumbs />
                </div>
            )}

            {bannerContent !== undefined && bannerContent}

            <div className="relative flex flex-col lg:flex-row justify-between z-10">
                <div>
                    <div className="flex items-center">
                        <Typography variant="header-1" color="text-primary">
                            {title}
                        </Typography>
                        {titleRightContent && titleRightContent}
                    </div>

                    {subtitle && <div className="mt-2">{subtitle} </div>}
                </div>

                {contentRight && <div className="z-20 mt-4 lg:mt-0">{contentRight}</div>}
            </div>
        </div>
    )
}
