import React from "react"
import { WidgetSize } from "../dashboardTypes"

const gridClass = "grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-4"
const stackedWidgetsClass = "flex flex-row lg:flex-col flex-wrap gap-4"
const wrapperClass = "flex flex-col gap-4"
interface Props {
    renderRecommendationsWidget?: () => React.ReactNode
    renderThreatHuntingWidget?: () => React.ReactNode
    renderAwarenessGraphWidget?: () => React.ReactNode
    renderAwarenessMetricWidget?: () => React.ReactNode
    renderDetectionsWidget: (size: WidgetSize) => React.ReactNode
    renderActionListWidget?: () => React.ReactNode
}

interface DetectionsOnboardingRowProps {
    renderDetectionsWidget: (size: WidgetSize) => React.ReactNode
    renderActionListWidget?: () => React.ReactNode
}

/**
 * Renders the row which contains the detections widget and the action list widget.
 * Checks if the action list should be rendered, otherwise only shows the detections widget.
 * @param param0
 * @returns
 */
const DetectionsOnboardingRow: React.FC<DetectionsOnboardingRowProps> = ({
    renderDetectionsWidget,
    renderActionListWidget,
}) => {
    return (
        <>
            {renderActionListWidget ? (
                <div className={gridClass}>
                    <div className="col-span-3 xl:col-span-2 flex">{renderDetectionsWidget("2/3")}</div>
                    <div className="col-span-3 xl:col-span-1 flex">{renderActionListWidget?.()}</div>
                </div>
            ) : (
                <div className={gridClass}>
                    <div className="col-span-3 flex">{renderDetectionsWidget("full")}</div>
                </div>
            )}
        </>
    )
}

/**
 * Takes in all the widgets that are showed in the center of the dashboard and renders them in the correct order within the layout
 * @param param0
 * @returns
 */
export const DashboardCenterLayout: React.FC<Props> = ({
    renderRecommendationsWidget,
    renderThreatHuntingWidget,
    renderAwarenessGraphWidget,
    renderAwarenessMetricWidget,
    renderDetectionsWidget,
    renderActionListWidget,
}) => {
    if (renderAwarenessGraphWidget) {
        return (
            <div className={wrapperClass}>
                <DetectionsOnboardingRow
                    renderDetectionsWidget={renderDetectionsWidget}
                    renderActionListWidget={renderActionListWidget}
                />

                <div className={gridClass}>
                    {/* ALERT: RECOMMENDATIONS WIDGET & THREAT HUNTING WIDGET COULD BE MISSING BECAUSE OF THE FEATURE FLAG */}
                    {renderRecommendationsWidget && renderThreatHuntingWidget && (
                        <div className={stackedWidgetsClass}>
                            {renderRecommendationsWidget()}
                            {renderThreatHuntingWidget()}
                        </div>
                    )}

                    {renderThreatHuntingWidget ? (
                        <div className="col-span-2 flex">{renderAwarenessGraphWidget()}</div>
                    ) : (
                        <div className="col-span-3 flex">{renderAwarenessGraphWidget()}</div>
                    )}
                </div>
            </div>
        )
    }

    if (renderAwarenessMetricWidget) {
        return (
            <div className={wrapperClass}>
                <DetectionsOnboardingRow
                    renderDetectionsWidget={renderDetectionsWidget}
                    renderActionListWidget={renderActionListWidget}
                />

                <div className="flex flex-wrap flex-col md:flex-row gap-6 items-stretch">
                    {renderRecommendationsWidget?.()}
                    {renderThreatHuntingWidget?.()}
                    {renderAwarenessMetricWidget?.()}
                </div>
            </div>
        )
    }

    return (
        <div className={wrapperClass}>
            <DetectionsOnboardingRow
                renderDetectionsWidget={renderDetectionsWidget}
                renderActionListWidget={renderActionListWidget}
            />

            <div className={gridClass}>
                {/* ALERT: RECOMMENDATIONS WIDGET & THREAT HUNTING WIDGET COULD BE MISSING BECAUSE OF THE FEATURE FLAG */}
                {renderRecommendationsWidget && renderThreatHuntingWidget && (
                    <>
                        {renderRecommendationsWidget()}
                        {renderThreatHuntingWidget()}
                    </>
                )}
            </div>
        </div>
    )
}
