import { Route, Routes, useRouteError } from "react-router-dom"
import { ProtectedRoute } from "./ProtectedRoute"
import { Dashboard } from "../pages/Dashboard"
import { Support } from "../pages/Support"
import { Detections } from "../pages/Detections"
import { Recommendations } from "../pages/Recommendations"
import { AppLayout } from "../components/layout/AppLayout"
import { RecommendationDetails } from "../pages/RecommendationDetails"
import React, { Fragment } from "react"
import { NotFound } from "../pages/NotFound"
import { EyedRoute } from "./EyedRoute"
import { EyedRedirect } from "./EyedRedirect"
import { RiskLocales } from "@pages/RiskLocales"
import { Logout } from "@pages/Logout"
import { getFallbackRoutes, getRoutes } from "@/config/routes/routeConfig"
import { AwarenessLanding } from "@pages/awareness/AwarenessLanding"
import { AdminDashboard } from "@pages/admin/AdminDashboard"
import { AwarenessCSVAdmin } from "@pages/admin/AwarenessCSVAdmin"
import { AwarenessFaq } from "@pages/awareness/AwarenessFaq"
import { AwarenessOverviewPage } from "@pages/awareness/AwarenessOverviewPage"
import * as Sentry from "@sentry/react"
import { AdminRoute } from "./AdminRoute"
import { Announcements } from "@pages/Announcements"
import { Reports } from "@pages/Reports"
import { AwarenessRecipients } from "@pages/awareness/AwarenessRecipients"
import { ThreatHuntingPage } from "@pages/ThreatHuntingPage"
import { ThreatHuntDetailPage } from "@pages/ThreatHuntDetailPage"
import { AwarenessOrganisationsAdmin } from "@pages/admin/AwarenessOrganisationsAdmin"
import { SentinelOnboardingPage } from "@pages/SentinelOnboardingPage"

/**
 * Workaround for the integrated error boundary that is introduced in react-router
 * This grabs the error from react-router and re-throws it so it ends up in our Sentry error boundary
 */
const BubbleErrorBoundary: React.FC = () => {
    const error = useRouteError()
    throw error
}

export const Router: React.FC = () => {
    const routes = getRoutes()
    const fallbackRoutes = getFallbackRoutes()
    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

    return (
        <SentryRoutes>
            <Route element={<ProtectedRoute component={AppLayout} />} errorElement={<BubbleErrorBoundary />}>
                {/* if a user goes to the / path, we should redirect them to the /dashboard path */}
                <Route path="/" element={<EyedRoute path={routes.dashboard.path} />} />

                <Route path={routes.dashboard.path} element={<Dashboard />} />

                {/* Recommendation paths */}
                <Route path={routes.recommendations.root.path} element={<Recommendations />} />
                <Route path={routes.recommendations.detail.path} element={<RecommendationDetails />} />

                {/* Detection Paths */}
                <Route path={routes.detections.root.path} element={<Detections />} />

                {/* Awareness Paths */}
                <Route path={routes.awareness.root.path} element={<AwarenessLanding />} />

                <Route path={routes.awareness.overview.path} element={<AwarenessOverviewPage />} />

                <Route path={routes.awareness.recipientInfo.path} element={<AwarenessRecipients />} />
                <Route path={routes.awareness.additionalInfo.path} element={<AwarenessFaq />} />

                {/* Threat Hunting Paths */}
                <Route path={routes.threatHunting.root.path} element={<ThreatHuntingPage />} />
                <Route path={routes.threatHunting.detail.path} element={<ThreatHuntDetailPage />} />

                {/* Management Reporting */}
                <Route path={routes.reports.path} element={<Reports />} />

                {/* Sentinel Onboarding */}
                <Route path={routes.sentinelOnboarding.path} element={<SentinelOnboardingPage />} />

                {/* Admin Paths */}
                <Route
                    path={routes.admin.root.path}
                    element={
                        <AdminRoute>
                            <AdminDashboard />
                        </AdminRoute>
                    }
                />

                <Route
                    path={routes.admin.awarenessCSVAdmin.path}
                    element={
                        <AdminRoute>
                            <AwarenessCSVAdmin />
                        </AdminRoute>
                    }
                />

                <Route
                    path={routes.admin.awarenessOrganisations.path}
                    element={
                        <AdminRoute>
                            <AwarenessOrganisationsAdmin />
                        </AdminRoute>
                    }
                />

                <Route
                    path={routes.riskLocales.path}
                    element={
                        <AdminRoute>
                            <RiskLocales />
                        </AdminRoute>
                    }
                />

                {/* Other Paths */}
                <Route path={routes.announcements.path} element={<Announcements />} />

                <Route path={routes.support.path} element={<Support />} />
                <Route path={routes.logout.path} element={<Logout />} />

                {/* Renders the fallback routes */}
                {/* Fallback routes exist as a fallback for users who go to a url WITHOUT an eyed supplied */}
                {/* The Fallback route then looks up the eyed and redirects the user to the appropriate path */}
                <Fragment>
                    {...Object.values(fallbackRoutes).map((value) => {
                        if (typeof value === "string") {
                            return <Route key={value} path={value} element={<EyedRoute path={value} />} />
                        }

                        return Object.values(value).map((nestedValue) => {
                            return (
                                <Route
                                    key={nestedValue}
                                    path={nestedValue}
                                    element={<EyedRoute path={nestedValue} />}
                                />
                            )
                        })
                    })}
                </Fragment>

                {/* Fallback, redirect user to the dashboard route if there's only an eyed present */}
                <Route path="/:eyed" element={<EyedRedirect path={routes.dashboard.path} />} />

                {/* 404 Fallback Path */}
                <Route path="*" element={<NotFound />} />
            </Route>
        </SentryRoutes>
    )
}
