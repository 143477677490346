import { Typography } from "@designSystem/typography/Typography"
import React, { PropsWithChildren } from "react"

interface Props {
    title: string
    subtitle: string
}

export const AdminStep: React.FC<PropsWithChildren<Props>> = ({ title, subtitle, children }) => {
    return (
        <section className="p-8 border rounded-md">
            <div className="mb-4">
                <Typography variant="body-1-semibold">{subtitle}</Typography>
                <Typography variant="header-5">{title}</Typography>
            </div>

            {children}
        </section>
    )
}
