import React, { useMemo } from "react"
import { MetricBlock } from "../metricBlock/MetricBlock"
import { useTranslation } from "react-i18next"
import { useRecommendations } from "@features/recommendations/hooks/useRecommendations"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { groupBy } from "lodash"
import { Typography } from "@designSystem/typography/Typography"
import { isNullish } from "@utils/formatUtils"
import { MetricBlockSkeleton } from "@designSystem/feedback/skeletons/MetricBlockSkeleton"
import { AntiSpoofingToolNotification } from "@components/AntiSpoofingToolNotification/AntiSpoofingToolNotification"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { MetricBlockWithMessage } from "../metricBlock/MetricBlockWithMessage"

interface Props {
    eyed: string
    language: string
}

export const RecommendationsWidget: React.FC<Props> = ({ eyed, language }) => {
    const { t } = useTranslation()
    const { data, isPending, isError } = useRecommendations(eyed, language)
    const activeEyed = useActiveEyed()
    const recommendations = useMemo(() => {
        return data?.data ?? []
    }, [data])

    const navigate = useNavigate()
    const routerLinks = getRouterLinks()

    const groupedByPrio = useMemo(() => {
        return groupBy(recommendations, "priority")
    }, [recommendations])

    const highPrioCount: number | undefined = groupedByPrio["Critical"]?.length
    const mediumPrioCount: number | undefined = groupedByPrio["Medium"]?.length
    const lowPrioCount: number | undefined = groupedByPrio["Low"]?.length

    if (isPending) {
        return <MetricBlockSkeleton />
    }

    if (isError) {
        return <MetricBlockWithMessage title={t(`dashboard.metrics.recommendations.heading`)} variant="ERROR" />
    }

    if (recommendations.length === 0) {
        return <MetricBlockWithMessage title={t(`dashboard.metrics.recommendations.heading`)} variant="COMPLETED" />
    }

    return (
        <>
            <AntiSpoofingToolNotification eyed={activeEyed} />
            <MetricBlock
                testId="recommendations-widget"
                title={t(`dashboard.metrics.recommendations.heading`)}
                metrics={[
                    {
                        value: recommendations.length,
                        subtitle: (
                            <div className="flex divide-x">
                                {!isNullish(highPrioCount) && (
                                    <Typography variant="body-2-semibold" color="text-critical" className="px-2">
                                        {highPrioCount} {t(`dashboard.metrics.recommendations.priorities.critical`)}
                                    </Typography>
                                )}

                                {!isNullish(mediumPrioCount) && (
                                    <Typography variant="body-2-semibold" color="text-warning" className="px-2">
                                        {mediumPrioCount} {t(`dashboard.metrics.recommendations.priorities.medium`)}
                                    </Typography>
                                )}

                                {!isNullish(lowPrioCount) && (
                                    <Typography variant="body-2-semibold" color="text-secondary" className="px-2">
                                        {lowPrioCount} {t(`dashboard.metrics.recommendations.priorities.low`)}
                                    </Typography>
                                )}
                            </div>
                        ),
                    },
                ]}
                link={{
                    value: t("dashboard.metrics.recommendations.link"),
                    onNavigate: () => {
                        navigate(routerLinks.recommendations.root({ eyed }))
                    },
                }}
            />
        </>
    )
}
