import React from "react"
import { Typography } from "../typography/Typography"

interface Props {
    title: string
    subtitle?: string
    button: React.ReactNode
    className?: string
}

export const ActionBlock: React.FC<Props> = ({ title, subtitle, button, className }) => {
    return (
        <div className={`flex flex-col flex-1 border p-6 rounded-md ${className ?? ""}`}>
            <Typography variant="header-5">{title}</Typography>
            {subtitle && (
                <Typography variant="body-1" color="text-secondary" className="mt-2">
                    {subtitle}
                </Typography>
            )}

            <div className="mt-4">{button}</div>
        </div>
    )
}
