import { DateTime } from "luxon"
import { isNullish } from "./formatUtils"
import {
    EYE_BIRTH_YEAR,
    buildLocalISODate,
    getCurrentTimestamp,
    getTimestampDaysAgo,
    isDateBeforeEyeBirthday,
} from "./dateUtils"

export interface IDateRange {
    from: string | undefined
    to: string | undefined
}

export type DefaultRange =
    | "today"
    | "last7Days"
    | "thisWeek"
    | "last30Days"
    | "thisMonth"
    | "thisYear"
    | "allTime"
    | "custom"

const TODAY: DefaultRange = "today"
const LAST_7_DAYS: DefaultRange = "last7Days"
const THIS_WEEK: DefaultRange = "thisWeek"
const LAST_30_DAYS: DefaultRange = "last30Days"
const THIS_MONTH: DefaultRange = "thisMonth"
const THIS_YEAR: DefaultRange = "thisYear"
const ALL_TIME: DefaultRange = "allTime"
const CUSTOM: DefaultRange = "custom"

export const DEFAULT_DATE_RANGES = {
    TODAY,
    LAST_7_DAYS,
    THIS_WEEK,
    LAST_30_DAYS,
    THIS_MONTH,
    THIS_YEAR,
    ALL_TIME,
    CUSTOM,
} as const

const DEFAULT_DATE_RANGE = {
    from: getTimestampDaysAgo(30),
    to: getCurrentTimestamp(),
}

export const getInitialDateRange = (fromTime?: string, toTime?: string): IDateRange => {
    if (!isNullish(fromTime) || !isNullish(toTime)) {
        return {
            from: fromTime,
            to: toTime,
        }
    }

    return DEFAULT_DATE_RANGE
}

/**
 * Outputs a date as the starting position for the day picker ( which is based on javascript date objects )
 * Prioritises the to date, then the from date and if neither is present it uses the current browser date.
 * @param range
 * @returns
 */
export const getRangePickerStart = (range?: IDateRange): Date => {
    if (isNullish(range)) {
        return new Date()
    }

    if (range.to) {
        return DateTime.fromISO(range.to).toJSDate()
    }

    if (range.from) {
        return DateTime.fromISO(range.from).toJSDate()
    }

    return new Date()
}

/**
 * Makes sure the to / from values are in correct order
 * The from should always be before the to timestamp.
 * @param from
 * @param to
 * @returns
 */
export const setCorrectFromToOrder = (from?: string, to?: string): IDateRange => {
    // If there is both a from & to
    // AND the from is after the to, we should reverse the from to
    if (!isNullish(from) && !isNullish(to)) {
        if (DateTime.fromISO(from) > DateTime.fromISO(to)) {
            return {
                from: to,
                to: from,
            }
        }
    }

    // If none of the above is applicable, the range was valid and we can return it safely.
    return { from, to }
}

/**
 * Checks the date range.
 * If either the from or the to is in the future. It replaces the date with the current browser time.
 * @param range a date range
 * @returns IDateRange a corrected date range without future dates
 */
export const setCorrectFutureDatesInRange = (range: IDateRange): IDateRange => {
    const now = DateTime.now()

    const correctedFrom = range.from && DateTime.fromISO(range.from) < now ? range.from : now.toISO()
    const correctedTo = range.to && DateTime.fromISO(range.to) < now ? range.to : now.toISO()

    return {
        from: correctedFrom ?? undefined,
        to: correctedTo ?? undefined,
    }
}

/**
 * Checks the date range.
 * If either the from or the to is before the eye start date. It replaces the date with the start of 2020
 * @param range a date range
 * @returns IDateRange a corrected date range without future dates
 */
const setCorrectPastDatesInRange = (range: IDateRange): IDateRange => {
    const correctedFrom =
        range.from && isDateBeforeEyeBirthday(range.from) ? buildLocalISODate(EYE_BIRTH_YEAR, 1, 1) : range.from
    const correctedTo =
        range.to && isDateBeforeEyeBirthday(range.to) ? buildLocalISODate(EYE_BIRTH_YEAR, 1, 1) : range.to

    return {
        from: correctedFrom ?? undefined,
        to: correctedTo ?? undefined,
    }
}
/**
 * Takes in a date range, based on the contents of the date range it returns a from/to pair with start + end of day set.
 * The from ( start of day )
 * The to ( end of day )
 * @param range
 * @returns
 */
export const setCorrectStartAndEndDates = (range: IDateRange): IDateRange => {
    // Both from & date are defined in the date range.
    // Parse both dates, and define the range as: { from: ( start of day ), to: ( end of day ) }
    if (range.from && range.to) {
        const fromDate = range.from ? DateTime.fromISO(range.from).startOf("day").toISO() : undefined
        const toDate = range.to ? DateTime.fromISO(range.to).endOf("day").toISO() : undefined

        return { from: fromDate ?? undefined, to: toDate ?? undefined }
    }

    // Only the from date is set in the range
    // Parse the from date, and define the range as: { from: ( from start of day ), to: ( from end of day ) }
    if (range.from && isNullish(range.to)) {
        const fromDate = DateTime.fromISO(range.from).startOf("day").toISO()
        const toDate = DateTime.fromISO(range.from).endOf("day").toISO()

        return { from: fromDate ?? undefined, to: toDate ?? undefined }
    }

    // Only the to date is set in the range
    // Parse the to date, and define the range as: { from: ( to start of day ), to: ( to end of day ) }
    if (range.to && isNullish(range.from)) {
        const fromDate = DateTime.fromISO(range.to).startOf("day").toISO()
        const toDate = DateTime.fromISO(range.to).endOf("day").toISO()

        return { from: fromDate ?? undefined, to: toDate ?? undefined }
    }

    return { from: undefined, to: undefined }
}

/**
 * Returns a pre determined date range for each option.
 * @param range
 * @returns
 */
export const getSpecifiedDateRange = (range: DefaultRange): IDateRange => {
    // Luxon's toIso function can theoretically return NULL. Hence we have to provide some fallbacks with the ?? undefined vars
    // The IDateRange can't handle the null values

    if (range === "last7Days") {
        return {
            // Last 7 days should include today, so it's today minus 6 days to get a total of 7 days.
            from: DateTime.now().startOf("day").minus({ days: 6 }).toISO() ?? undefined,
            to: DateTime.now().endOf("day").toISO() ?? undefined,
        }
    }

    if (range === "thisWeek") {
        return {
            from: DateTime.now().startOf("week").toISO() ?? undefined,
            to: DateTime.now().endOf("day").toISO() ?? undefined,
        }
    }

    if (range === "last30Days") {
        return {
            // The last 30 days should include today, so it's today minus 29 days.
            from: DateTime.now().startOf("day").minus({ days: 29 }).toISO() ?? undefined,
            to: DateTime.now().endOf("day").toISO() ?? undefined,
        }
    }

    if (range === "thisMonth") {
        return {
            from: DateTime.now().startOf("month").toISO() ?? undefined,
            to: DateTime.now().endOf("day").toISO() ?? undefined,
        }
    }

    if (range === "thisYear") {
        return {
            from: DateTime.now().startOf("year").toISO() ?? undefined,
            to: DateTime.now().endOf("day").toISO() ?? undefined,
        }
    }

    if (range === "allTime") {
        return {
            from: DateTime.local(EYE_BIRTH_YEAR, 1, 1).startOf("year").toISO() ?? undefined,
            to: DateTime.now().endOf("day").toISO() ?? undefined,
        }
    }

    // Returns Today by default
    return {
        from: DateTime.now().startOf("day").toISO() ?? undefined,
        to: DateTime.now().endOf("day").toISO() ?? undefined,
    }
}

/** Based on the current date range, it returns the previous date range.
 *  This is the difference in days between the current range, minus the current fromDate
 */
export const calculatePreviousDateRange = (range: IDateRange): IDateRange => {
    if (range.from && range.to) {
        // Makes sure to also cast to end and start of day to prevent strange time difference calculations
        const fromDateTime = DateTime.fromISO(range.from).startOf("day")
        const toDateTime = DateTime.fromISO(range.to).endOf("day")
        const difference = toDateTime.diff(fromDateTime, "days").toObject()

        return {
            from: fromDateTime.minus({ days: difference.days }).startOf("day").toISO() ?? undefined,
            to: fromDateTime.minus({ days: 1 }).endOf("day").toISO() ?? undefined,
        }
    }

    if (range.from) {
        const fromDateTime = DateTime.fromISO(range.from).minus({ days: 1 })
        return {
            from: fromDateTime.startOf("day").toISO() ?? undefined,
            to: fromDateTime.endOf("day").toISO() ?? undefined,
        }
    }

    if (range.to) {
        const toDateTime = DateTime.fromISO(range.to).minus({ days: 1 })
        return {
            from: toDateTime.startOf("day").toISO() ?? undefined,
            to: toDateTime.endOf("day").toISO() ?? undefined,
        }
    }

    return {
        from: undefined,
        to: undefined,
    }
}

/** Based on the current date range, it returns the previous date range.
 *  This is the difference in days between the current range, plus the current toDate
 */
export const calculateNextDateRange = (range: IDateRange): IDateRange => {
    if (range.from && range.to) {
        const fromDateTime = DateTime.fromISO(range.from).startOf("day")
        const toDateTime = DateTime.fromISO(range.to).endOf("day")
        const difference = toDateTime.diff(fromDateTime, "days").toObject()

        return {
            from: toDateTime.plus({ days: 1 }).startOf("day").toISO() ?? undefined,
            to: toDateTime.plus({ days: difference.days }).endOf("day").toISO() ?? undefined,
        }
    }

    if (range.from) {
        const fromDateTime = DateTime.fromISO(range.from).plus({ days: 1 })
        return {
            from: fromDateTime.startOf("day").toISO() ?? undefined,
            to: fromDateTime.endOf("day").toISO() ?? undefined,
        }
    }

    if (range.to) {
        const toDateTime = DateTime.fromISO(range.to).plus({ days: 1 })
        return {
            from: toDateTime.startOf("day").toISO() ?? undefined,
            to: toDateTime.endOf("day").toISO() ?? undefined,
        }
    }

    return {
        from: undefined,
        to: undefined,
    }
}

/**
 * Combines all the steps required to get a valid previous date range
 * @param range
 * @returns
 */
export const getPreviousDateRange = (range: IDateRange): IDateRange => {
    const withCorrectedFromToDates = setCorrectFromToOrder(range.from, range.to)
    const previousRange = calculatePreviousDateRange(withCorrectedFromToDates)
    const previousRangeWithCorrectedFutureDates = setCorrectFutureDatesInRange(previousRange)

    return previousRangeWithCorrectedFutureDates
}

/**
 * Combines all the steps required to get a valid next date range
 * @param range
 * @returns
 */
export const getNextDateRange = (range: IDateRange): IDateRange => {
    const withCorrectedFromToDates = setCorrectFromToOrder(range.from, range.to)
    const nextRange = calculateNextDateRange(withCorrectedFromToDates)
    const nextRangeWithCorrectedFutureDates = setCorrectFutureDatesInRange(nextRange)

    return nextRangeWithCorrectedFutureDates
}

// If the daterange from & to are both undefined, this function sets a default value for the from/to
// This is set to current days start of day -> to now.
export const handleEmptyDateRange = (range: IDateRange): IDateRange => {
    // Fallback to ensure completely empty ranges have a fallback value
    if (isNullish(range.from) && isNullish(range.to)) {
        return {
            from: DateTime.now().startOf("day").toISO() ?? undefined,
            to: DateTime.now().toISO() ?? undefined,
        }
    }

    return range
}

/**
 * Combines all the steps to build a valid date range.
 * This has the following corrected:
 * 1. The from to order is set chronologically
 * 2. The from and to have their startOfDay and endOfDay set
 * 3. The range is stripped of of future dates.
 * 4. The range is stripped of of date too far in the past.
 * @param range
 * @returns
 */
export const getValidDateRange = (range: IDateRange): IDateRange => {
    // First we make sure the range has either a from/to set ( or both )
    const definedRange = handleEmptyDateRange(range)

    // Then we make sure the from / to are in the correct order chronologically
    const orderedDateRange: IDateRange = setCorrectFromToOrder(definedRange?.from, definedRange?.to)

    // Then we make sure the range has a start & end dates
    // This also sets the from to startOfDay
    // This also sets the to to endOfDay
    const withStartAndEndDates = setCorrectStartAndEndDates(orderedDateRange)

    // Then we make sure that the range does not contain any dates in the future
    const withCorrectedFutureDates = setCorrectFutureDatesInRange(withStartAndEndDates)

    const withCorrectedPastDates = setCorrectPastDatesInRange(withCorrectedFutureDates)

    // Finally the date range is valid and can be returned.
    return withCorrectedPastDates
}
