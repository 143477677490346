import React from "react"
import { useFeatureFlagPayload } from "posthog-js/react"
import { Typography } from "@designSystem/typography/Typography"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Button } from "@designSystem/buttons/Button"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { isAnnouncement } from "@utils/announcementUtils"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { featureFlagConfig } from "@config/featureFlagConfig"

export const AnnouncementBanner: React.FC = () => {
    const announcementFlag = useFeatureFlagPayload(featureFlagConfig.announcementBanner)

    const { t } = useTranslation(["common"])

    if (isAnnouncement(announcementFlag)) {
        return (
            <div className="w-full bg-brand-eye-ice-100 border-b p-3 bg-background-page-primary-light">
                <div className="flex items-center">
                    <InformationCircleIcon width={24} className="mr-2" />
                    <Typography variant="body-1-semibold">{announcementFlag.announcementBarText}</Typography>

                    <Link to={getRouterLinks().announcements()}>
                        <Button variant="text" className="ml-2" withPadding={false}>
                            {t("common:buttons.viewDetails")}
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }

    return null
}
