import React, { useContext } from "react"
import { UserContext } from "../providers/UserContext"
import { PageLayout } from "../components/layout/PageLayout"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { DashboardMetrics } from "@features/dashboard/components/DashboardMetrics"
import { RecommendationsWidget } from "@features/dashboard/components/widgets/RecommendationsWidget"
import { DetectionsWidget } from "@features/dashboard/components/widgets/DetectionsWidget"
import { AwarenessWidget } from "@features/dashboard/components/widgets/AwarenessWidget"
import { DownloadCurrentReportButton } from "@features/managementReport/components/DownloadCurrentReportButton"
import { Typography } from "@designSystem/typography/Typography"
import { ThreatHuntingWidget } from "@features/dashboard/components/widgets/ThreatHuntingWidget"
import { useDashboardData } from "@features/dashboard/hooks/useDashboardData"
import { DashboardCenterLayout } from "@features/dashboard/components/DashboardCenterLayout"
import { MetricBlockSkeleton } from "@designSystem/feedback/skeletons/MetricBlockSkeleton"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { InsuranceUpsellQuiz } from "@features/insuranceUpsell/InsuranceUpsellQuiz"
import { ActionListWidget } from "@features/dashboard/components/widgets/actionList/ActionListWidget"
import { useOnboardingState } from "@hooks/useOnboardingState"
import { TenantsIndicator } from "@components/tenantsIndicator/TenantsIndicator"

export const Dashboard: React.FC = () => {
    const { language, activeOrganisation } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    const threatHuntingEnabled = useFeatureFlagEnabled(featureFlagConfig.threatHunting)
    const { isLoadingDashboard, dashboardWidgetStates } = useDashboardData()
    const { showOnboardingActionList, onboardingState } = useOnboardingState()

    if (isLoadingDashboard) {
        return (
            <PageLayout variant="dashboard">
                <div className="relative p-4 lg:p-8 flex flex-col h-full gap-4 z-10">
                    <Typography variant="header-1">{activeOrganisation?.name}</Typography>

                    <div className="flex flex-wrap flex-col md:flex-row gap-6">
                        <MetricBlockSkeleton />
                        <MetricBlockSkeleton />
                        <MetricBlockSkeleton />
                    </div>

                    <DashboardCenterLayout
                        renderRecommendationsWidget={() => <MetricBlockSkeleton />}
                        renderThreatHuntingWidget={() => <MetricBlockSkeleton />}
                        renderAwarenessGraphWidget={() => <MetricBlockSkeleton />}
                        renderAwarenessMetricWidget={undefined}
                        renderDetectionsWidget={() => <MetricBlockSkeleton />}
                        renderActionListWidget={() => <MetricBlockSkeleton />}
                    />
                </div>
            </PageLayout>
        )
    }

    return (
        <PageLayout variant="dashboard">
            <div className="relative p-4 lg:p-8 flex flex-col h-full gap-4 z-10 w-full overflow-hidden">
                <div className="flex justify-between items-center">
                    <div>
                        <Typography variant="header-1">{activeOrganisation?.name}</Typography>
                        <div className="mt-2">
                            <TenantsIndicator />
                        </div>
                    </div>
                    <DownloadCurrentReportButton />
                </div>

                <div className="flex flex-wrap flex-col md:flex-row gap-4">
                    <DashboardMetrics activeEyed={activeEyed} />
                    {threatHuntingEnabled !== true ? (
                        <RecommendationsWidget eyed={activeEyed} language={language} />
                    ) : undefined}
                </div>

                <DashboardCenterLayout
                    renderRecommendationsWidget={
                        threatHuntingEnabled
                            ? () => <RecommendationsWidget eyed={activeEyed} language={language} />
                            : undefined
                    }
                    renderThreatHuntingWidget={
                        threatHuntingEnabled ? () => <ThreatHuntingWidget eyed={activeEyed} /> : undefined
                    }
                    renderAwarenessGraphWidget={
                        dashboardWidgetStates.awarenessGraphWidget
                            ? () => <AwarenessWidget eyed={activeEyed} showEventsGraph={true} />
                            : undefined
                    }
                    renderAwarenessMetricWidget={
                        dashboardWidgetStates.awarenessMetricWidget
                            ? () => <AwarenessWidget eyed={activeEyed} showEventsGraph={false} />
                            : undefined
                    }
                    renderDetectionsWidget={(size) => <DetectionsWidget eyed={activeEyed} size={size} />}
                    renderActionListWidget={
                        showOnboardingActionList
                            ? () => <ActionListWidget onboardingState={onboardingState} />
                            : undefined
                    }
                />

                <InsuranceUpsellQuiz />
            </div>
        </PageLayout>
    )
}
