import { createColumnHelper } from "@tanstack/react-table"
import { Typography } from "@designSystem/typography/Typography"
import i18n from "@/i18next"
import { PriorityBadge } from "@designSystem/layout/PriorityBadge"
import { Circles } from "@designSystem/layout/Circles"
import { TimeAgoDate } from "@components/dates/TimeAgoDate"
import { IRecommendation } from "@features/recommendations/recommendationTypes"
import { sortRecommendationsByPriority } from "@utils/sortingUtils"

export const getRecommendationTableColumns = () => {
    const columnHelper = createColumnHelper<IRecommendation>()

    return [
        columnHelper.accessor("label", {
            size: 350,
            header: i18n.t("pages:recommendations.table.issue"),
            cell: (info) => (
                <Typography textElipsis shouldPreventWrapping>
                    {info.getValue()}
                </Typography>
            ),
            enableHiding: true,
        }),
        columnHelper.accessor("priority", {
            size: 200,
            header: i18n.t("pages:recommendations.table.priority"),
            cell: (info) => <PriorityBadge priority={info.getValue()} />,
            enableHiding: true,
            sortingFn: (rowA, rowB) => {
                return sortRecommendationsByPriority(rowA.original, rowB.original)
            },
        }),
        columnHelper.accessor("effort", {
            size: 200,
            header: i18n.t("pages:recommendations.table.effort"),
            cell: (info) => (
                <div className="flex gap-1">
                    <Circles
                        N={3}
                        alternativeAfter={info.getValue()}
                        className="bg-button-primary-background-disabled-light"
                        alternativeClass="bg-background-brand-primary-light"
                    />
                </div>
            ),
            enableHiding: true,
        }),
        columnHelper.accessor("last_scanned", {
            size: 200,
            header: i18n.t("pages:recommendations.table.last_scanned"),
            cell: (info) => <TimeAgoDate tooltipPlacement="right" timestamp={info.getValue()} id={info.getValue()} />,
            enableHiding: true,
        }),
    ]
}
