import React from "react"

import { UserMFAInfo } from "../../recommendationTypes"
import { SortableTableHeader } from "@designSystem/layout/table/SortableTableHeader"
import {
    Table,
    TableHeader,
    TableRow,
    SortableTableHeaderCell,
    TableBody,
    TableCell,
} from "@designSystem/layout/table/Table"
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { getMfaUserInfoBaseColumns, getMfaUserInfoExtendedColumns } from "./columns/MFAUserInfoColumns"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import { sortUserInfoTable } from "@utils/sortingUtils"

interface Props {
    UserMfaInfo: UserMFAInfo[]
}

const baseColumns = getMfaUserInfoBaseColumns()
const extendedColumns = getMfaUserInfoExtendedColumns()

export const MFAUserInfoTable: React.FC<Props> = ({ UserMfaInfo }) => {
    const [sorting, setSorting] = React.useState<SortingState>([])

    // Gather all the unique tenants in a set.
    const uniqueTenants = Array.from(
        new Set(
            UserMfaInfo.map((info) => {
                return info.tenant
            }),
        ),
        // Also filter out any nullish values
    ).filter((tenant) => {
        return tenant !== "" && tenant !== undefined && tenant !== null
    })

    // We can hide the tenant column if we only have 0-1 tenants which also include an empty tenant string.
    const hideTenantColumn: boolean = uniqueTenants.length <= 1

    // Sets the default sorted data order.
    // Only needs to be done if the tenant column is not available
    const preSortedData = React.useMemo(() => {
        return sortUserInfoTable(UserMfaInfo)
    }, [UserMfaInfo])

    const table = useReactTable({
        data: preSortedData,
        columns: hideTenantColumn ? baseColumns : extendedColumns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <TablePaginationWrapper isRounded data={UserMfaInfo} table={table}>
            <Table isRounded isFixed={false}>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <SortableTableHeaderCell key={header.id}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader {...header.getContext()}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </SortableTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}
