import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
import { StateIllustration } from "@components/stateIllustration/StateIllustration"

export const SentinelOnboardingCompleted: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="flex justify-center">
            <div className="max-w-[720px]">
                <div className="flex justify-center mt-12">
                    <StateIllustration variant="Success" />
                </div>
                <div className="space-y-4 mt-8">
                    <Typography variant="header-1" className="text-center">
                        {t("sentinelOnboarding:completedOnboardingEye.title")}
                    </Typography>
                    <Typography variant="body-1" className="text-center">
                        {t("sentinelOnboarding:completedOnboardingEye.description")}
                    </Typography>
                </div>
            </div>
        </div>
    )
}
