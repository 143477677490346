import { localStorageSettings } from "@/localStorage/localStorage"
import { UserContext } from "@/providers/UserContext"
import { isNullish } from "@utils/formatUtils"
import { useContext, useMemo } from "react"
import { useLocation } from "react-router-dom"

export const useAwarenessState = () => {
    const location = useLocation()
    const { activeOrganisation } = useContext(UserContext)
    const hasAwareness = !isNullish(activeOrganisation?.awareness)
    const hasAwarenessInContract = activeOrganisation?.awareness_in_contract === true
    const awarenessStep = activeOrganisation?.awareness?.onboarding_step
    const hasFinishedAwarenessOnboarding = !isNullish(activeOrganisation?.awareness?.onboarded_at)
    const showAwarenessReporting = activeOrganisation?.awareness?.show_reporting === true
    const showExpandableAwarenessMenu = hasAwareness && hasAwarenessInContract && hasFinishedAwarenessOnboarding
    const expandAwarenessMenuByDefault = useMemo(() => {
        return location.pathname.includes("/awareness/")
    }, [location.pathname])

    const showAwarenessWidget = hasAwareness && hasFinishedAwarenessOnboarding && showAwarenessReporting
    const recipientSource = activeOrganisation?.awareness?.recipient_source
    const showAwarenessRecipientsPage = recipientSource === "CSV" || recipientSource === "AzureAD"

    // The inverse of has visited, if the user has visited awareness we don't want to show the is new label
    const showIsAwarenessNewLabel = !localStorageSettings.getHasVisitedAwareness()
    // if a default langauge is present it will contain the phished object
    const defaultLanguage = activeOrganisation?.awareness?.default_language

    return {
        hasAwareness,
        showAwarenessWidget,
        awarenessStep,
        hasFinishedAwarenessOnboarding,
        showAwarenessReporting,
        showExpandableAwarenessMenu,
        showIsAwarenessNewLabel,
        defaultLanguage,
        showAwarenessRecipientsPage,
        recipientSource,
        expandAwarenessMenuByDefault,
    }
}
