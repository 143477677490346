import { Button } from "@designSystem/buttons/Button"
import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import { useTranslation } from "react-i18next"

export const SentinelInfoBlock: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="bg-brand-eye-ice rounded-md p-6 space-y-4 w-full lg:w-[320px]">
                <Typography variant="header-3">{t("sentinelOnboarding:infoBlock.title")}</Typography>
                <Typography>{t("sentinelOnboarding:infoBlock.content")}</Typography>
                <div className="mt-4">
                    <a
                        rel="noopener noreferrer"
                        href={"https://www.eye.security/blog/how-eye-security-can-defend-you-against-evilproxies"}
                        target="_blank"
                    >
                        <Button variant="secondary" className="w-full" size="small">
                            {t("sentinelOnboarding:infoBlock.button")}
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    )
}
