import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import CheckCircleIcon from "@assets/icons/checkCircleIcon.svg?react"
import EmptyCircleIcon from "@assets/icons/emptyCircleIcon.svg?react"
import { ArrowPathIcon } from "@heroicons/react/24/solid"

interface Props {
    title: string
    subtitle: string
    status: "COMPLETED" | "IN_PROGRESS" | "TODO"
    renderActionLink: () => React.ReactNode
}

export const ActionListItem: React.FC<Props> = ({ title, subtitle, renderActionLink, status }) => {
    const statusIcon =
        status === "COMPLETED" ? (
            <CheckCircleIcon />
        ) : status === "IN_PROGRESS" ? (
            <ArrowPathIcon width={16} className="animate-spin-slow-reverse" />
        ) : (
            <EmptyCircleIcon />
        )

    return (
        <div className="bg-white rounded-md flex justify-between px-4 py-3 items-center">
            <div className="flex items-center gap-3">
                <div>{statusIcon}</div>
                <div>
                    <Typography variant="body-2">{title}</Typography>
                    <Typography variant="body-3" color="text-secondary">
                        {subtitle}
                    </Typography>
                </div>
            </div>
            <div>{renderActionLink()}</div>
        </div>
    )
}
