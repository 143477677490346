import React, { FunctionComponent } from "react"
import { FeedbackLabel } from "../feedback/FeedbackLabel"
interface Props {
    className?: string
    children: React.ReactNode
    footer?: React.ReactNode
    variant: "detailPage" | "dashboard" | "emptyPage"
}

export const PageLayout: FunctionComponent<Props> = ({ children, className, variant, footer }) => {
    if (variant === "dashboard") {
        return (
            <div className={`relative flex flex-col flex-1 items-center w-full ${className ?? ""}`}>
                <FeedbackLabel />

                {/* The pr ( padding right ) is in place to make some space for the feedback label */}
                <div className={`flex flex-col flex-1 pr-8 eye-page-width`}>{children}</div>
                {footer}
            </div>
        )
    }

    if (variant === "detailPage") {
        return (
            <div
                className={`relative flex flex-col w-full overflow-x-hidden flex-1 items-center bg-white ${
                    className ?? ""
                }`}
            >
                <FeedbackLabel />

                {/* The pr ( padding right ) is in place to make some space for the feedback label */}
                <div className={`detail-page-container`}>{children}</div>

                {footer}
            </div>
        )
    }

    return (
        <div className={`relative h-full overflow-y-hidden overflow-x-hidden w-full bg-white  ${className ?? ""}`}>
            <FeedbackLabel />

            {/* The pr ( padding right ) is in place to make some space for the feedback label */}
            <div className={`h-full bg-white`}>{children}</div>
            {footer}
        </div>
    )
}
