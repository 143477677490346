import React, { Fragment, PropsWithChildren } from "react"
import { Typography, TypographyColor } from "../../../../components/designSystem/typography/Typography"
import { Button } from "@designSystem/buttons/Button"
import { ArrowLongRightIcon } from "@heroicons/react/24/solid"

interface Props {
    title: string
    titleIcon?: React.ReactNode
    metrics: {
        value: string | number
        subtitle: string | React.ReactNode
        color?: TypographyColor
        infoIcon?: React.ReactNode
    }[]
    link?: {
        value: string
        onNavigate?: () => void
    }
    topRightIndicator?: React.ReactNode
    testId?: string
    className?: string
    renderIntroductionHotspot?: () => JSX.Element
}

export const MetricBlockWrapper: React.FC<PropsWithChildren<{ testId?: string; className?: string }>> = ({
    children,
    testId,
    className,
}) => {
    return (
        <div
            className={`flex-1 border bg-white rounded-lg flex flex-col justify-between relative min-h-[210px] ${className ?? ""}`}
            data-testid={testId}
        >
            {children}
        </div>
    )
}

export const MetricBlockFooter: React.FC<PropsWithChildren> = ({ children }) => {
    return <div className="flex p-1 border-t justify-end">{children}</div>
}

export const MetricBlockContent: React.FC<PropsWithChildren> = ({ children }) => {
    return <div className="p-6">{children}</div>
}

export const MetricBlockHeader: React.FC<
    PropsWithChildren<{ title: string; titleIcon?: React.ReactNode; topRightIndicator?: React.ReactNode }>
> = ({ title, titleIcon, topRightIndicator }) => {
    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
                <Typography variant="body-1-semibold" color="text-primary">
                    {title}
                </Typography>
                {titleIcon}
            </div>
            {topRightIndicator && <>{topRightIndicator}</>}
        </div>
    )
}

export const MetricBlock: React.FC<Props> = ({
    title,
    metrics,
    link,
    topRightIndicator,
    testId,
    renderIntroductionHotspot,
    className,
    titleIcon,
}) => {
    return (
        <MetricBlockWrapper testId={testId} className={className}>
            {renderIntroductionHotspot && <div className="absolute -top-2 -right-2">{renderIntroductionHotspot()}</div>}
            <MetricBlockContent>
                <MetricBlockHeader title={title} titleIcon={titleIcon} topRightIndicator={topRightIndicator} />

                <div className="flex gap-10 items-center">
                    {metrics.map((metric, index) => {
                        const hasMetricSibling = index < metrics.length - 1

                        return (
                            <Fragment key={`${title}-metric-${metric.value}-${index}`}>
                                <div>
                                    <div className="flex gap-2 py-4">
                                        <Typography variant="header-1-light" color={metric.color}>
                                            {metric.value}
                                        </Typography>
                                        <Typography variant="span" color={metric.color}>
                                            {metric.infoIcon}
                                        </Typography>
                                    </div>

                                    {typeof metric.subtitle === "string" && (
                                        <Typography variant="body-2" color="text-secondary">
                                            {metric.subtitle}
                                        </Typography>
                                    )}
                                    {typeof metric.subtitle !== "string" && <>{metric.subtitle}</>}
                                </div>

                                {/* Shows a divider between multiple metrics */}
                                {hasMetricSibling && <div className="h-[75px] w-[1px] bg-border-primary-light"></div>}
                            </Fragment>
                        )
                    })}
                </div>
            </MetricBlockContent>

            {link && (
                <MetricBlockFooter>
                    <div>
                        <Button
                            variant="text"
                            size="small"
                            disabled={link.onNavigate === undefined}
                            onClick={link.onNavigate}
                            endIcon={<ArrowLongRightIcon width={16} height={16} />}
                        >
                            {link.value}
                        </Button>
                    </div>
                </MetricBlockFooter>
            )}
        </MetricBlockWrapper>
    )
}
