import React, { PropsWithChildren, forwardRef } from "react"

interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        PropsWithChildren {}

// This component requires some forwardRef magic to work together with the Dropdown's ref magic given by floatingUI.
export const DropdownPanel: React.FC<Props> = forwardRef((props, ref) => {
    const { children, ...rest } = props
    return (
        <div className="px-2 py-3 border rounded-md space-y-2 z-40" ref={ref} {...rest}>
            {children}
        </div>
    )
})

DropdownPanel.displayName = "DropdownPanel"
