import { Typography } from "@designSystem/typography/Typography"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { IThreatHunt, IThreatHuntWithAssets } from "../threatHuntingTypes"
import { UserContext } from "@/providers/UserContext"

interface Props {
    threatHunt: IThreatHunt | IThreatHuntWithAssets
}

export const InsuranceActionRequiredBanner: React.FC<Props> = ({ threatHunt }) => {
    const { t } = useTranslation()
    const { activeOrganisation } = useContext(UserContext)

    if (activeOrganisation?.insured !== true || "assets" in threatHunt === false) {
        return null
    }

    return (
        <div className="border border-brand-eye-ice p-5 bg-brand-eye-ice/5 mb-4">
            <Typography variant="header-5" color="text-link">
                {t("threatHunting:detailPage.actionRequired")}
            </Typography>
            <Typography variant="body-1" className="mt-2" color="text-link">
                {t("threatHunting:detailPage.actionDescription")}
            </Typography>
        </div>
    )
}
