import { Button } from "@designSystem/buttons/Button"
import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { useApplicationStore } from "@/stores/applicationStore"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { EAST_RECOMMENDATION_ID } from "@utils/recommendationUtils"
import { useTranslation } from "react-i18next"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { localStorageSettings } from "@/localStorage/localStorage"

interface Props {
    eyed: string
}

export const AntiSpoofingToolNotification: React.FC<Props> = ({ eyed }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { captureEvent } = useCapturePostHogEvent()

    const eastNotificationEnabledPostHog = useFeatureFlagEnabled(featureFlagConfig.eastNotification)

    // Checks the local storage for any previous closes of the east popup.
    const showConsentBanner = useApplicationStore((state) => state.showConsentBanner)
    const hasClosedEastNotification = useApplicationStore((state) => state.hasClosedEastNotification)
    const setHasClosedEastNotificationState = useApplicationStore((state) => state.setHasClosedEastNotification)
    const eastRecommendationStatus = useApplicationStore((state) => state.eastRecommendationStatus)

    const onCloseNotification = () => {
        // Sets the global state in the zustand store.
        setHasClosedEastNotificationState(true)
        // Syncs the localstorage so the notification will be hidden on next visit.
        localStorageSettings.setHasClosedEastNotification()
    }

    const showNotification =
        hasClosedEastNotification === false &&
        showConsentBanner === false &&
        eastRecommendationStatus === "HAS_EAST_RECOMMENDATION" &&
        eastNotificationEnabledPostHog === true

    const navigateToEastRecommendation = () => {
        navigate(
            getRouterLinks().recommendations.detail({
                id: EAST_RECOMMENDATION_ID,
                eyed,
            }),
        )
    }

    if (showNotification === false) {
        return null
    }

    return (
        <div className="fixed bottom-5 right-5 bg-brand-eye-blue w-[320px] sm:w-[400px] rounded-lg z-50">
            <div className="relative p-8">
                <Typography color="text-invert" variant="header-4" className="text-center">
                    {t("eastNotification:title")}
                </Typography>

                <button
                    className="absolute top-4 right-4 hover:stroke-eye-gray-30"
                    onClick={() => {
                        onCloseNotification()
                        captureEvent(POSTHOG_CAPTURE_TYPES.EAST.CLOSE)
                    }}
                >
                    <XMarkIcon width={24} className="fill-white" />
                </button>

                <Typography color="text-invert" className="mt-3">
                    {t("eastNotification:description")}
                </Typography>
                <div className="flex items-center justify-center gap-3 mt-6 flex-col sm:flex-row dark">
                    <Button
                        variant="primary"
                        onClick={() => {
                            navigateToEastRecommendation()
                            onCloseNotification()
                            captureEvent(POSTHOG_CAPTURE_TYPES.EAST.INSTALL)
                        }}
                    >
                        {t("eastNotification:buttons.install")}
                    </Button>
                </div>
            </div>
        </div>
    )
}
