import React from "react"
import { Typography } from "../typography/Typography"
import { BuildingOfficeIcon, PhoneIcon } from "@heroicons/react/24/outline"

interface Props {
    title: string
    address?: string
    zipCode?: string
    phoneNumber?: string
    mapsLink?: string
}

const ICON_DIMENSIONS = 20

export const ContactBlock: React.FC<Props> = ({ title, address, phoneNumber, mapsLink, zipCode }) => {
    return (
        <div className="border p-6 rounded-md min-w-[240px]">
            <Typography variant="header-5">{title}</Typography>

            <div className="mt-3 space-y-2">
                {address && mapsLink && zipCode && (
                    <div className="flex space-x-3">
                        <div className="mt-1">
                            <BuildingOfficeIcon width={ICON_DIMENSIONS} height={ICON_DIMENSIONS} />
                        </div>
                        <a rel="noopener noreferrer" href={mapsLink} target="_blank" className="hover:underline">
                            <div>{address}</div>
                            <div>{zipCode}</div>
                        </a>
                    </div>
                )}

                {phoneNumber && (
                    <div className="flex items-center space-x-3">
                        <PhoneIcon width={ICON_DIMENSIONS} height={ICON_DIMENSIONS} />

                        <a className="hover:underline" href={`tel:${phoneNumber}`}>
                            {phoneNumber}
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}
