import { colorPalette } from "./palette"

const textTokens = {
    ["text-primary"]: {
        light: colorPalette.blue[700],
        dark: colorPalette.neutral.white,
    },
    ["text-secondary"]: {
        light: colorPalette.neutral[600],
        dark: colorPalette.neutral[300],
    },
    ["text-inactive"]: {
        light: colorPalette.neutral[500],
        dark: colorPalette.neutral[500],
    },
    ["text-invert"]: {
        light: colorPalette.neutral.white,
        dark: colorPalette.blue[700],
    },
    ["text-link"]: {
        light: colorPalette.blue[500],
        dark: colorPalette.blue[300],
    },
    ["text-critical"]: {
        light: colorPalette.red[400],
        dark: colorPalette.red[300],
    },
    ["text-warning"]: {
        light: colorPalette.orange[400],
        dark: colorPalette.orange[300],
    },
    ["text-success"]: {
        light: colorPalette.green[500],
        dark: colorPalette.green[400],
    },
    ["text-purple"]: {
        light: colorPalette.purple[700],
        dark: colorPalette.purple[700],
    },
    ["text-abnormal"]: {
        light: colorPalette.yellow[600],
        dark: colorPalette.neutral.white,
    },
    ["text-low"]: {
        light: colorPalette.yellow[300],
        dark: colorPalette.yellow[300],
    },
}

const backgroundTokens = {
    ["background-page-primary"]: {
        light: colorPalette.neutral.white,
        dark: colorPalette.blue[700],
    },
    ["background-page-secondary"]: {
        light: colorPalette.neutral[200],
        dark: colorPalette.neutral[200],
    },
    ["background-brand-primary"]: {
        light: colorPalette.brand["eye-blue"],
        dark: colorPalette.brand["eye-blue"],
    },
    ["background-brand-secondary"]: {
        light: colorPalette.brand["eye-orange"],
        dark: colorPalette.brand["eye-orange"],
    },
    ["background-brand-secondary-hover"]: {
        light: colorPalette.orange[300],
        dark: colorPalette.orange[300],
    },
    ["background-brand-tertiary"]: {
        light: colorPalette.brand["eye-ice"],
        dark: colorPalette.brand["eye-ice"],
    },
    ["background-brand-hover"]: {
        light: colorPalette.blue[600],
        dark: colorPalette.blue[600],
    },
    ["background-brand-inactive"]: {
        light: colorPalette.neutral[300],
        dark: colorPalette.neutral[300],
    },
    ["background-critical"]: {
        light: colorPalette.red[100],
        dark: colorPalette.neutral.white,
    },
    ["background-warning"]: {
        light: colorPalette.orange[100],
        dark: colorPalette.orange[300],
    },
    ["background-success"]: {
        light: colorPalette.green[100],
        dark: colorPalette.neutral.white,
    },
    ["background-purple"]: {
        light: colorPalette.purple[100],
        dark: colorPalette.purple[100],
    },
    ["background-abnormal"]: {
        light: colorPalette.yellow[100],
        dark: colorPalette.neutral.white,
    },
}

const borderTokens = {
    ["border-primary"]: {
        light: colorPalette.neutral[300],
        dark: colorPalette.blue[600],
    },
}

const buttonTokens = {
    ["button-primary-background-default"]: {
        light: colorPalette.blue[700],
        dark: colorPalette.brand["eye-orange"],
    },
    ["button-primary-background-hover"]: {
        light: colorPalette.blue[600],
        dark: colorPalette.orange[300],
    },
    ["button-primary-background-disabled"]: {
        light: colorPalette.neutral[300],
        dark: colorPalette.neutral[300],
    },
    ["button-primary-text"]: {
        light: colorPalette.neutral.white,
        dark: colorPalette.neutral.white,
    },
    ["button-secondary-background-default"]: {
        light: colorPalette.neutral.white,
        dark: colorPalette.neutral.white,
    },
    ["button-secondary-background-hover"]: {
        light: colorPalette.blue[600],
        dark: colorPalette.blue[600],
    },
    ["button-secondary-background-disabled"]: {
        light: colorPalette.neutral.white,
        dark: colorPalette.neutral.white,
    },
    ["button-secondary-text"]: {
        light: colorPalette.blue[700],
        dark: colorPalette.blue[700],
    },
}

const tagTokens = {
    ["tag-text"]: {
        light: colorPalette.purple[100],
        dark: colorPalette.purple[100],
    },
    ["tag-background"]: {
        light: colorPalette.purple[700],
        dark: colorPalette.purple[700],
    },
}

const stepperTokens = {
    ["stepper-background-default"]: {
        light: colorPalette.neutral[300],
        dark: colorPalette.neutral[300],
    },
    ["stepper-background-done"]: {
        light: colorPalette.blue[300],
        dark: colorPalette.blue[300],
    },
}

const widgetTokens = {
    ["widget-background-primary"]: {
        light: colorPalette.blue[500],
        dark: colorPalette.neutral.white,
    },
    ["widget-background-secondary"]: {
        light: colorPalette.blue[200],
        dark: colorPalette.neutral.white,
    },
    ["widget-background-tertiary"]: {
        light: colorPalette.neutral[500],
        dark: colorPalette.neutral.white,
    },
}

export const designTokens = {
    textTokens,
    backgroundTokens,
    borderTokens,
    buttonTokens,
    tagTokens,
    stepperTokens,
    widgetTokens,
}
