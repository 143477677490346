import React from "react"

export type Ref = HTMLInputElement

interface Props {
    icon?: React.ReactNode
    iconLocation?: "start" | "end"
}

export const TextInput = React.forwardRef<
    Ref,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & Props
>((props, ref) => {
    const { icon, iconLocation = "end", className, ...rest } = props
    const iconPaddingClass = icon && iconLocation === "start" ? "pl-12" : icon && iconLocation === "end" ? "pr-12" : ""

    const iconWrapper = (
        <div
            className={`pointer-events-none absolute inset-y-0 flex items-center pr-3 ${
                iconLocation === "start" ? "left-4" : "right-2"
            }`}
        >
            {icon}
        </div>
    )

    return (
        <div className={`${className ?? ""} relative rounded-md `}>
            {iconLocation === "start" && <>{iconWrapper}</>}

            <input
                ref={ref}
                {...rest}
                type="text"
                className={`${iconPaddingClass} block rounded-md border-0 py-2.5  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-brand-eye-orange sm:leading-6 w-full`}
            />

            {iconLocation === "end" && <>{iconWrapper}</>}
        </div>
    )
})

TextInput.displayName = "TextInput"
