import React, { PropsWithChildren } from "react"
import { IThreatHunt, IThreatHuntWithAssets } from "../threatHuntingTypes"
import { Typography } from "@designSystem/typography/Typography"
import { useTranslation } from "react-i18next"
import { ThreatHuntInsuranceLabel } from "./ThreatHuntInsuranceLabel"
import { getAcknowledgeStatusText } from "@utils/threatHuntingUtils"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import { showWithFallbackValue } from "@utils/formatUtils"

interface Props {
    threatHunt: IThreatHunt | IThreatHuntWithAssets
}

const InfoBlockRow: React.FC<PropsWithChildren> = ({ children }) => {
    return <div className="grid grid-cols-2 mt-1">{children}</div>
}

export const ThreatHuntInfoBlock: React.FC<Props> = ({ threatHunt }) => {
    const { t } = useTranslation()
    return (
        <div className="min-w-[500px] max-w-[800px]">
            <InfoBlockRow>
                <Typography variant="body-1" color="text-secondary">
                    {t("threatHunting:detailPage.infoBlock.reported")}
                </Typography>
                <Typography variant="body-1">{formatDate(threatHunt.startDate, DATE_FORMATS.DATE_FORMAT)}</Typography>
            </InfoBlockRow>

            <InfoBlockRow>
                <Typography variant="body-1" color="text-secondary">
                    {t("threatHunting:detailPage.infoBlock.status")}
                </Typography>
                <div className="flex items-center gap-2">
                    <Typography variant="body-1">{getAcknowledgeStatusText(threatHunt)}</Typography>
                    <ThreatHuntInsuranceLabel threatHunt={threatHunt} />
                </div>
            </InfoBlockRow>
            <InfoBlockRow>
                <Typography variant="body-1" color="text-secondary">
                    {t("threatHunting:detailPage.infoBlock.cve")}
                </Typography>
                <Typography variant="body-1">{showWithFallbackValue(threatHunt.cve?.cveId)}</Typography>
            </InfoBlockRow>

            {threatHunt.cve?.relatedCveIds && (
                <InfoBlockRow>
                    <Typography variant="body-1" color="text-secondary">
                        {t("threatHunting:detailPage.infoBlock.relatedCveIds")}
                    </Typography>
                    <Typography variant="body-1">
                        {showWithFallbackValue(threatHunt.cve?.relatedCveIds.join(", "))}
                    </Typography>
                </InfoBlockRow>
            )}

            <InfoBlockRow>
                <Typography variant="body-1" color="text-secondary">
                    {t("threatHunting:detailPage.infoBlock.product")}
                </Typography>
                <Typography variant="body-1">{showWithFallbackValue(threatHunt.cve?.affectedProduct?.name)}</Typography>
            </InfoBlockRow>
            <InfoBlockRow>
                <Typography variant="body-1" color="text-secondary">
                    {t("threatHunting:detailPage.infoBlock.vulnerableVersions")}
                </Typography>
                <Typography variant="body-1">
                    {showWithFallbackValue(threatHunt.cve?.affectedProduct?.versions.join(", "))}
                </Typography>
            </InfoBlockRow>
        </div>
    )
}
