import * as React from "react"
import { useState } from "react"
import { LogoutOptions, User, useAuth0 } from "@auth0/auth0-react"
import { Alert } from "../components/designSystem/feedback/notifications/Alert"
import { LOCAL_STORAGE_ACTIVE_EYED_KEY } from "../localStorage/localStorage"
import { EyeOrganisation, useUserOrganisations } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { useParams } from "react-router-dom"
import { Env, getEnv } from "@/config/envConfig"
import { clearAxiosAuthToken } from "@/api/requests"
import { useUserInformation } from "@features/userMenu/hooks/useUserInformation"
import { usePostHog } from "posthog-js/react"
import { PortalLoadingScreen } from "@components/portalLoadingScreen/PortalLoadingScreen"
import { useTranslation } from "react-i18next"
import { PostHogPropertySync } from "@/posthog/PostHogPropertySync"
import { LoginErrorMessage } from "@components/loginErrorMessage/LoginErrorMessage"
import { UserIdentifier } from "@/posthog/UserIdentifier"
import * as Sentry from "@sentry/browser"

interface IUserContext {
    initialEyed: string
    activeOrganisation: EyeOrganisation | undefined
    isRiskDevelopmentModeEnabled: boolean
    setRiskDevelopmentModeEnabled: (enabled: boolean) => void
    language: string
    userOrganisations: EyeOrganisation[]
    logoutUser: (options?: LogoutOptions | undefined) => void
    isPortalAdmin: boolean
    user: User
}

export const UserContext = React.createContext<IUserContext>({
    initialEyed: "",
    activeOrganisation: undefined,
    language: "",
    userOrganisations: [],
    isRiskDevelopmentModeEnabled: false,
    logoutUser: () => {
        console.warn("logout call not implemented in user context")
    },
    setRiskDevelopmentModeEnabled: (enabled: boolean): void => {
        // placeholder context function to make sure the function always exists
        // eslint-disable-next-line
        console.warn(`risk switch not implemented: ${enabled}`)
    },
    isPortalAdmin: false,
    user: {},
})

const determineInitialEyed = (organisationsData: EyeOrganisation[] | undefined): string | null => {
    const lastActiveEyed = localStorage.getItem(LOCAL_STORAGE_ACTIVE_EYED_KEY)

    // If there's a lastActiveEyed ( from account switching )
    // AND the lastActiveEyed is present in the users organisations
    // Set this as the initial eyed.
    if (
        lastActiveEyed &&
        organisationsData &&
        organisationsData.some((organistion) => organistion.eyed === lastActiveEyed)
    ) {
        return lastActiveEyed
    }

    // If no activeEyed is present in localstorage we can set the initial active eyed as the first eyed in the organisations data
    if (organisationsData && organisationsData[0]) {
        return organisationsData[0].eyed
    }

    return null
}

export const UserContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const language = "en"
    const environment = getEnv()
    const posthog = usePostHog()
    const { t } = useTranslation()

    // State handling
    const { isAuthenticated, user, error: auth0Error, logout } = useAuth0()
    const [isRiskDevelopmentModeEnabled, setIsRiskDevelopmentModeEnabled] = useState(false)
    const { eyed: activeEyed } = useParams()

    // Data fetching
    const { data: userOrganisationsData, error: organisationsError } = useUserOrganisations()
    const userHasNoOrganisations = userOrganisationsData?.data === null || userOrganisationsData?.data?.length === 0
    const { data: userInformationData } = useUserInformation()
    const userOrganisations = userOrganisationsData?.data ?? []

    //  Additional check, only the actual boolean value should return true. Empty strings, nulls, "true" should not validate correctly.
    const isPortalAdmin = userInformationData?.data?.is_admin === true ? true : false

    // The initial Eyed is the
    const initialEyed = activeEyed ? activeEyed : determineInitialEyed(userOrganisationsData?.data ?? [])
    const activeOrganisation = userOrganisationsData?.data?.find((organisation) => organisation.eyed === initialEyed)

    const setRiskDevelopmentModeEnabled = (enabled: boolean): void => {
        if (environment === Env.development || environment === Env.test) {
            setIsRiskDevelopmentModeEnabled(enabled)
        }

        return
    }

    const logoutUser = (): void => {
        // Logout the user in auth0
        logout({ logoutParams: { returnTo: window.location.origin } })
        // reset the user sentry context
        Sentry.setUser(null)
        // reset makes sure the user is reset to an "unknown user" until they login again.
        posthog.reset()
        // clears the default auth token in axios config
        clearAxiosAuthToken()
        // Remove the active eyed from the local storage.
        localStorage.removeItem(LOCAL_STORAGE_ACTIVE_EYED_KEY)
    }

    if (auth0Error || organisationsError || userHasNoOrganisations) {
        return (
            <PortalLoadingScreen>
                <LoginErrorMessage logoutUser={logoutUser} />
            </PortalLoadingScreen>
        )
    }

    // If auth0 is still authenticated OR the active organisation / eyed is still being setup, we show the loading screen.
    // We want to ensure auth0 is loaded AND an organisation is active before loading any other components
    if (!isAuthenticated || !initialEyed || activeOrganisation === undefined || user === undefined) {
        return (
            <PortalLoadingScreen>
                <div className="relative">
                    <Alert title={t("common:loadingMessages.loadingPortal")} type="loading" />
                </div>
            </PortalLoadingScreen>
        )
    }

    return (
        <UserContext.Provider
            value={{
                language,
                initialEyed,
                activeOrganisation,
                userOrganisations: userOrganisations,
                logoutUser,
                isRiskDevelopmentModeEnabled,
                setRiskDevelopmentModeEnabled,
                isPortalAdmin,
                user,
            }}
        >
            {/* Component that awaits the auth0 user and then identifies this user with the PostHog instance provided by the PostHogContext */}
            <UserIdentifier>
                {/* Component that adds user properties once the user and organisation have been identified */}
                <PostHogPropertySync activeOrganisation={activeOrganisation} userOrganisations={userOrganisations}>
                    {children}
                </PostHogPropertySync>
            </UserIdentifier>
        </UserContext.Provider>
    )
}
