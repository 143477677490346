import React from "react"
import { Typography } from "../../../components/designSystem/typography/Typography"
import { Circles } from "../../../components/designSystem/layout/Circles"
import { IRecommendation } from "../recommendationTypes"
import { PriorityBadge } from "../../../components/designSystem/layout/PriorityBadge"
import { useTranslation } from "react-i18next"
import { Button } from "@designSystem/buttons/Button"

interface Props {
    recommendation: IRecommendation
    setIsFeedbackOpen?: (open: boolean) => void
}

export const RecommendationHeader: React.FC<Props> = ({ recommendation, setIsFeedbackOpen }) => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="flex flex-col space-y-3">
                <div className="flex items-center ">
                    <Typography variant="body-1" color="text-secondary" className="w-[125px]">
                        {t("pages:recommendations.infoBlock.created_for")}
                    </Typography>
                    <Typography variant="body-1">System Administrator</Typography>
                </div>

                <div className="flex items-center">
                    <Typography variant="body-1" color="text-secondary" className="w-[125px]">
                        {t("recommendations.infoBlock.effort")}
                    </Typography>
                    <div className="flex gap-1">
                        <Circles
                            N={3}
                            alternativeAfter={recommendation.effort}
                            className="bg-button-primary-background-disabled-light"
                            alternativeClass="bg-background-brand-primary-light"
                        />
                    </div>
                </div>

                <div className="flex items-center">
                    <Typography variant="body-1" color="text-secondary" className="w-[125px]">
                        {t("recommendations.infoBlock.priority")}
                    </Typography>
                    <PriorityBadge priority={recommendation.priority} />
                </div>

                {setIsFeedbackOpen && (
                    <div className="flex items-center">
                        <Button variant="text" onClick={() => setIsFeedbackOpen(true)} withPadding={false}>
                            {t("common:provideFeedback")}
                        </Button>
                    </div>
                )}
            </div>

            <div className="h-[1px] w-full mt-8 mb-8 bg-blue-100"></div>
        </div>
    )
}
