import React, { Fragment } from "react"
import NavigationButton from "./navigationItems/NavigationButton"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { getNavigationItems } from "@/config/navigationConfig"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { Collapse } from "@designSystem/layout/Collapse"
import { Typography } from "@designSystem/typography/Typography"
import { useAwarenessState } from "@features/awareness/hooks/useAwarenessState"
import { AwarenessLabel } from "@features/awareness/components/AwarenessLabel"

const Navigation: React.FC = () => {
    const navigationItems = getNavigationItems()
    const activeEyed = useActiveEyed()
    const threatHuntingEnabled = useFeatureFlagEnabled(featureFlagConfig.threatHunting)
    const { showExpandableAwarenessMenu, expandAwarenessMenuByDefault, showAwarenessRecipientsPage } =
        useAwarenessState()

    return (
        <div className="flex flex-col w-full relative flex-1 overflow-auto">
            <nav className="mt-1 flex-1 space-y-2" data-testid="desktop-navigation">
                <NavigationButton
                    label={navigationItems.dashboard.label}
                    iconLeft={{ component: navigationItems.dashboard.icon }}
                    onNavigate={() => {
                        return navigationItems.dashboard.onNavigate({ eyed: activeEyed })
                    }}
                />
                <NavigationButton
                    label={navigationItems.detections.label}
                    iconLeft={{ component: navigationItems.detections.icon }}
                    onNavigate={() => {
                        return navigationItems.detections.onNavigate({ eyed: activeEyed })
                    }}
                />
                <NavigationButton
                    label={navigationItems.recommendations.label}
                    iconLeft={{ component: navigationItems.recommendations.icon }}
                    onNavigate={() => {
                        return navigationItems.recommendations.onNavigate({ eyed: activeEyed })
                    }}
                />

                {showExpandableAwarenessMenu ? (
                    <Collapse
                        defaultOpen={expandAwarenessMenuByDefault}
                        buttonClassName="navigation-button w-full"
                        title={
                            <Fragment>
                                <div className="flex items-center flex-1">
                                    <navigationItems.awareness.overview.icon
                                        className="mr-3 flex-shrink-0 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    <Typography variant="body-1-semibold" color="inherit">
                                        {navigationItems.awareness.root.label}
                                    </Typography>

                                    <div className="ml-2">
                                        <AwarenessLabel />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    >
                        <div className="pl-4 gap-2 flex flex-col">
                            <NavigationButton
                                label={navigationItems.awareness.overview.label}
                                onNavigate={() => {
                                    return navigationItems.awareness.overview.onNavigate({ eyed: activeEyed })
                                }}
                            />

                            {showAwarenessRecipientsPage && (
                                <NavigationButton
                                    label={navigationItems.awareness.recipientInfo.label}
                                    onNavigate={() => {
                                        return navigationItems.awareness.recipientInfo.onNavigate({
                                            eyed: activeEyed,
                                        })
                                    }}
                                />
                            )}

                            <NavigationButton
                                label={navigationItems.awareness.additionalInfo.label}
                                onNavigate={() => {
                                    return navigationItems.awareness.additionalInfo.onNavigate({
                                        eyed: activeEyed,
                                    })
                                }}
                            />
                        </div>
                    </Collapse>
                ) : (
                    <NavigationButton
                        label={navigationItems.awareness.root.label}
                        iconLeft={{ component: navigationItems.awareness.root.icon }}
                        contentRight={<AwarenessLabel />}
                        onNavigate={() => {
                            return navigationItems.awareness.root.onNavigate({ eyed: activeEyed })
                        }}
                    />
                )}

                {threatHuntingEnabled && (
                    <NavigationButton
                        label={navigationItems.threatHunting.root.label}
                        contentLeft={navigationItems.threatHunting.root.icon}
                        onNavigate={() => {
                            return navigationItems.threatHunting.root.onNavigate({ eyed: activeEyed })
                        }}
                    />
                )}

                <NavigationButton
                    label={navigationItems.reports.root.label}
                    contentLeft={navigationItems.reports.root.icon}
                    onNavigate={() => {
                        return navigationItems.reports.root.onNavigate({ eyed: activeEyed })
                    }}
                />
            </nav>
        </div>
    )
}

export default Navigation
