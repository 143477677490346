import React, { useState } from "react"
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
} from "@floating-ui/react"
import { Transition } from "@headlessui/react"

interface Props {
    button: JSX.Element
    buttonClass?: string
    buttonType?: "button" | "div"
    // Determines the floating panel strategy ( absolute is easier to render for the browser, but can be harder to position when wrapped in relative elements ( our tables ) )
    dropdownPositioning?: "absolute" | "fixed"
    children: (closePanel: () => void) => React.ReactNode
}

const DropDown: React.FC<Props> = ({
    button,
    children,
    buttonClass = "",
    buttonType = "button",
    dropdownPositioning = "absolute",
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
        strategy: dropdownPositioning,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)

    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

    return (
        <>
            {buttonType === "button" ? (
                <button
                    ref={refs.setReference}
                    {...getReferenceProps()}
                    className={`hover:cursor-pointer ${buttonClass}`}
                >
                    {button}
                </button>
            ) : (
                <div ref={refs.setReference} {...getReferenceProps()} className={`hover:cursor-pointer ${buttonClass}`}>
                    {button}
                </div>
            )}

            {isOpen && (
                <FloatingFocusManager context={context} modal={false}>
                    <div
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="rounded-md bg-white shadow-lg z-30"
                        {...getFloatingProps()}
                    >
                        <Transition
                            show={isOpen}
                            as="div"
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            {children(() => {
                                setIsOpen(false)
                            })}
                        </Transition>
                    </div>
                </FloatingFocusManager>
            )}
        </>
    )
}

export default DropDown
