import Tippy from "@tippyjs/react"
import React from "react"
import { HotspotTooltip } from "./HotspotTooltip"
import { IHotspotProps } from "./hotspotTypes"

export const Hotspot: React.FC<IHotspotProps & { onHotspotButtonClick?: () => void }> = ({
    title,
    description,
    buttonText,
    hotspotId,
    onHotspotButtonClick,
}) => {
    return (
        <Tippy
            content={
                <HotspotTooltip title={title} description={description} hotspotId={hotspotId} buttonText={buttonText} />
            }
            interactive={true}
            trigger="mouseenter"
            hideOnClick
            onShow={(instance) => {
                // OnShow triggers if the user hovers over de hotspot
                // Add a event listener to all the buttons in the tooltip, these then close the hotspot
                instance.popper.querySelectorAll("button")?.forEach((button) => {
                    button.addEventListener("click", () => {
                        instance.hide()
                        onHotspotButtonClick?.()
                    })
                })
            }}
        >
            <div className="rounded-full border-spacing-1 border-[1px] border-brand-eye-orange flex justify-center items-center animate-breathe w-[23px] h-[23px]">
                <div className="w-[13px] h-[13px] bg-brand-eye-orange rounded-full "></div>
            </div>
        </Tippy>
    )
}
