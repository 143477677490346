import { Button } from "@designSystem/buttons/Button"
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from "@designSystem/layout/table/Table"
import { SelectMenu } from "@designSystem/overlays/SelectMenu"
import { Typography } from "@designSystem/typography/Typography"
import {
    RecipientColumnName,
    RecipientColumnSelection,
    RecipientRegistration,
    RecipientsUploadStep,
    UploadedCSV,
} from "@features/awareness/awarenessTypes"
import { AdminStep } from "@features/awareness/components/AdminStep"
import { isEmail } from "@utils/validationUtils"
import React, { useState } from "react"
import { ViewColumnsIcon, BellIcon } from "@heroicons/react/24/outline"
import { isNullish } from "@utils/formatUtils"

interface Props {
    uploadedCSV?: UploadedCSV
    columnOptions: RecipientColumnSelection[]
    recipientsData: RecipientRegistration[] | undefined
    setRecipientsData: (recipients: RecipientRegistration[]) => void
    clearRecipientsStep: (step: RecipientsUploadStep) => void
}

const TABLE_COLUMN_WIDTH = 250
const REQUIRED_COLUMNS: RecipientColumnName[] = ["email", "language"]

export const ColumnSelectionStep: React.FC<Props> = ({
    uploadedCSV,
    setRecipientsData,
    columnOptions,
    recipientsData,
    clearRecipientsStep,
}) => {
    const topRow = uploadedCSV ? uploadedCSV.data[0] : []
    // If the first row contains an email, we can assume that this row is also a data row and does not contain any headers.
    const isMissingColumnHeaders = topRow.some((value) => isEmail(value))
    const data = uploadedCSV?.data ?? []
    // If the data is missing headers, we can use all the data. Otherwise use the data after row 1.
    const tableData = isMissingColumnHeaders ? data : data.slice(1)
    const [selection, setSelection] = useState<RecipientColumnSelection[]>(columnOptions)

    const onConfirmSelection = () => {
        const firstNameIndex = selection.find((col) => col.columnName === "firstName")?.index
        const lastNameIndex = selection.find((col) => col.columnName === "lastName")?.index
        const emailIndex = selection.find((col) => col.columnName === "email")?.index
        const languageIndex = selection.find((col) => col.columnName === "language")?.index
        const departmentIndex = selection.find((col) => col.columnName === "department")?.index
        const jobTitleIndex = selection.find((col) => col.columnName === "jobTitle")?.index

        const recipientsToRegister: RecipientRegistration[] = tableData.map((row) => {
            return {
                firstName: !isNullish(firstNameIndex) ? row[firstNameIndex]?.trim() : undefined,
                lastName: !isNullish(lastNameIndex) ? row[lastNameIndex]?.trim() : undefined,
                email: !isNullish(emailIndex) ? row[emailIndex]?.trim() : undefined,
                language: !isNullish(languageIndex) ? row[languageIndex]?.trim() : undefined,
                department: !isNullish(departmentIndex) ? row[departmentIndex]?.trim() : undefined,
                jobTitle: !isNullish(jobTitleIndex) ? row[jobTitleIndex]?.trim() : undefined,
            }
        })

        setRecipientsData(recipientsToRegister)
    }

    // Checks the columnOptions length ( for now 2 ( email / language ) items. ) If some rows contain less items than that, it indicates that data is missing.
    const isMissingData = tableData.some((row) => row.length < REQUIRED_COLUMNS.length - 1)

    const updateSelection = (columnIndex: number, selectedName: string) => {
        const updatedSelection: RecipientColumnSelection[] = [...selection].map((col) => {
            // If the columnName matches the selected columnname, we update the index
            if (col.columnName === selectedName) {
                return {
                    index: columnIndex,
                    columnName: selectedName,
                }
            }

            // If the index, matches on a different item, we reset it's index.
            if (col.index === columnIndex) {
                return { columnName: col.columnName, index: null }
            }

            return col
        })

        setSelection(updatedSelection)
    }

    if (recipientsData) {
        return (
            <AdminStep subtitle="Step 3" title="Verify results">
                <div className="border p-4 rounded-md flex items-center justify-between shadow-sm">
                    <div className="flex items-center gap-2">
                        <ViewColumnsIcon width={24} className="text-brand-eye-orange" />
                        <Typography>Column Selection</Typography>
                    </div>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            clearRecipientsStep("ColumnSelectionStep")
                        }}
                    >
                        Clear Column Selection
                    </Button>
                </div>
            </AdminStep>
        )
    }

    if (uploadedCSV === undefined) {
        return (
            <AdminStep subtitle="Step 3" title="Verify results">
                <Typography>Please upload a CSV file first.</Typography>
            </AdminStep>
        )
    }

    return (
        <AdminStep subtitle="Step 3" title="Select the correct columns">
            <div>
                <Typography>
                    Select which column represents which field in the table below. It is fine for now if the language
                    column is missing. These can be filled in during the next step.
                </Typography>

                <div className="mt-4">
                    <Table isFixed className="h-[400px]" isRounded>
                        <TableHeader>
                            <TableRow>
                                {topRow.map((columnOption, index) => {
                                    const matchingOption = selection.find((col) => col.index === index)

                                    // Shows the key of the key/value pair as the selected option. Falls back to a '-' value if none is found.
                                    const selectedOption = matchingOption ? matchingOption.columnName : "-"

                                    return (
                                        <TableHeaderCell
                                            key={`table-header-${columnOption}-${index}`}
                                            width={TABLE_COLUMN_WIDTH}
                                        >
                                            <div className="flex items-center w-full justify-between">
                                                <SelectMenu
                                                    options={columnOptions.map((col) => col.columnName)}
                                                    onSelectOption={(option) => {
                                                        updateSelection(index, option as string)
                                                    }}
                                                    selectedOption={selectedOption}
                                                    yOrientation="bottom"
                                                    xOrientation={index === topRow.length - 1 ? "left" : "right"}
                                                />
                                            </div>
                                        </TableHeaderCell>
                                    )
                                })}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {tableData.map((row, rowIndex) => {
                                return (
                                    <TableRow key={`file-row-${rowIndex}`}>
                                        {row.map((column, columnIndex) => {
                                            return (
                                                <TableCell
                                                    key={`file-column-${columnIndex}`}
                                                    width={TABLE_COLUMN_WIDTH}
                                                >
                                                    <Typography shouldPreventWrapping textElipsis>
                                                        {column === "" ? "Empty Column" : column}
                                                    </Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>

                <div className="mt-4 space-y-2">
                    {isMissingData && (
                        <div className="border p-2 rounded-md flex items-center gap-2">
                            <BellIcon width={24} className="text-red-500" />
                            <Typography>{`The table is misssing data, some rows miss data for the fields.`}</Typography>
                        </div>
                    )}
                </div>

                <div className="mt-6">
                    <Button variant="primary" onClick={onConfirmSelection} disabled={isMissingData === true}>
                        Confirm Selection
                    </Button>
                </div>
            </div>
        </AdminStep>
    )
}
