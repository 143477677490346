import React from "react"
import { IRecommendation } from "../../recommendationTypes"
import { useNavigate } from "react-router-dom"
import {
    Table,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    SortableTableHeaderCell,
} from "../../../../components/designSystem/layout/table/Table"
import { useActiveEyed } from "@hooks/useActiveEyed"
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"

import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import { SortableTableHeader } from "@designSystem/layout/table/SortableTableHeader"
import { useRecommendationsColumnVisibility } from "../../hooks/useRecommendationColumnVisibility"
import { getRecommendationTableColumns } from "./columns/RecommendationColumns"
import { getRouterLinks } from "@/config/routes/routeLinksConfig"

interface Props {
    data: IRecommendation[]
}

const tableColumns = getRecommendationTableColumns()

const RecommendationsTable: React.FC<Props> = ({ data }) => {
    const navigate = useNavigate()
    const routerLinks = getRouterLinks()
    const activeEyed = useActiveEyed()

    const navigateToDetailPage = (id: string): void => {
        navigate(routerLinks.recommendations.detail({ id, eyed: activeEyed }))
    }

    const [sorting, setSorting] = React.useState<SortingState>([{ id: "priority", desc: false }])
    const { columnVisibility } = useRecommendationsColumnVisibility()

    const table = useReactTable({
        data,
        columns: tableColumns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <TablePaginationWrapper isRounded data={data} table={table}>
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <SortableTableHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader {...header.getContext()}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </SortableTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow
                                key={row.id}
                                onClick={() => {
                                    navigateToDetailPage(row.original.id)
                                }}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}

export default RecommendationsTable
