import {
    Table,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    SortableTableHeaderCell,
} from "@designSystem/layout/table/Table"
import { MFAPolicy } from "@features/recommendations/recommendationTypes"
import React from "react"

import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { SortableTableHeader } from "@designSystem/layout/table/SortableTableHeader"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import { getBaseMFAPoliciesV2Columns, getExtendedMFAPoliciesV2Columns } from "./columns/MFAPoliciesV2Columns"
import { sortPoliciesTableData } from "@utils/sortingUtils"

interface Props {
    policies: MFAPolicy[]
}

const baseColumns = getBaseMFAPoliciesV2Columns()
const extendedColumns = getExtendedMFAPoliciesV2Columns()

export const MFAPoliciesv2Table: React.FC<Props> = ({ policies }) => {
    // Gather all the unique tenants in a set.
    const uniqueTenants = new Set(
        policies.map((policy) => {
            return policy.tenant
        }),
    )

    // We can hide the tenant column if we only have 0-1 tenants which also include an empty tenant string.
    const hideTenantColumn: boolean = uniqueTenants.size <= 1 && uniqueTenants.has("")
    const defaultSorting: SortingState = hideTenantColumn ? [] : [{ id: "tenant", desc: false }]
    const [sorting, setSorting] = React.useState<SortingState>(defaultSorting)

    // Sets the default sorted data order.
    // Only needs to be done if the tenant column is not available
    const preSortedData = React.useMemo(() => {
        return sortPoliciesTableData(policies)
    }, [policies])

    const table = useReactTable({
        data: preSortedData,
        columns: hideTenantColumn ? baseColumns : extendedColumns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <TablePaginationWrapper isRounded data={preSortedData} table={table}>
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <SortableTableHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader {...header.getContext()}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </SortableTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}
