import { useTranslation } from "react-i18next"
import { PageHeader } from "../components/header/PageHeader"
import { Typography } from "../components/designSystem/typography/Typography"
import { LinkBlock } from "../components/designSystem/buttons/LinkBlock"
import { BookOpenIcon, DocumentIcon } from "@heroicons/react/24/outline"
import { PhoneIcon } from "@heroicons/react/24/solid"
import { ActionBlock } from "../components/designSystem/buttons/ActionBlock"
import { ContactBlock } from "../components/designSystem/layout/ContactBlock"
import { PageLayout } from "../components/layout/PageLayout"
import { useContext } from "react"
import { UserContext } from "@/providers/UserContext"
import { SupportButton } from "@components/supportButton/SupportButton"
interface IContact {
    name: string
    email?: string
    phoneNumber?: string
    address?: string
    zipcode?: string
    location?: string
    mapsLinks?: string
}

type EyeOffice = "eye_nl_the_hague" | "eye_nl_eindhoven" | "eye_be" | "eye_de"

export const Support: React.FC = () => {
    const { t } = useTranslation(["pages"])
    const { activeOrganisation } = useContext(UserContext)

    const contacts: Record<EyeOffice, IContact> = {
        eye_nl_the_hague: {
            name: t("contact.eye_nl_the_hague"),
            email: "service@eyesecurity.nl",
            phoneNumber: "+31 88 644 4888",
            address: "Saturnusstraat 60-54",
            zipcode: "2516 AH Den Haag",
            location: "Nederland",
            mapsLinks: "https://goo.gl/maps/WXbGAJbiLBpWHzEn9",
        },
        eye_nl_eindhoven: {
            name: t("contact.eye_nl_eindhoven"),
            email: "service@eyesecurity.nl",
            phoneNumber: "+31 88 644 4888",
            address: "Emmasingel 33",
            zipcode: "5611 AZ Eindhoven",
            location: "Nederland",
            mapsLinks: "https://maps.app.goo.gl/HMcCktSpH4tUHtMo6",
        },
        eye_be: {
            name: t("contact.eye_be"),
            email: "service@eyesecurity.be",
            phoneNumber: "+32 50 89 35 84",
            address: "Kapellestraat 138",
            zipcode: "8020 Oostkamp",
            location: "Belgium",
            mapsLinks: "https://goo.gl/maps/K2gFsd5WTdEst1Lp6",
        },
        eye_de: {
            name: t("contact.eye_de"),
            email: "service@eyesecurity.de",
            phoneNumber: "+49 203 6688 1900",
            address: "Franz‑Haniel‑Platz 1",
            zipcode: "47119 Duisburg",
            location: "Germany",
            mapsLinks: "https://goo.gl/maps/gySDHh6C1S6hKrwd7",
        },
    }

    const phoneNumberToShow =
        activeOrganisation?.eye_office === "Eye Belgium"
            ? contacts.eye_be.phoneNumber
            : activeOrganisation?.eye_office === "Eye Germany"
              ? contacts.eye_de.phoneNumber
              : contacts.eye_nl_the_hague.phoneNumber

    return (
        <PageLayout variant="detailPage">
            <PageHeader title={t("contact.header")}></PageHeader>

            <p>{t("contact.subtitle")}</p>

            <div className="flex flex-wrap mt-8 max-w-[700px] gap-x-4 gap-y-4">
                <a className="flex-1" rel="noopener noreferrer" href={"https://kb.eye.security/"} target="_blank">
                    <LinkBlock title={t("contact.knowledge_base")} icon={<BookOpenIcon />} />
                </a>

                <a className="flex-1" rel="noopener noreferrer" href={"https://eye.sharefile.com/"} target="_blank">
                    <LinkBlock title={t("contact.sharefile")} icon={<DocumentIcon />} />
                </a>
            </div>

            <div>
                <Typography variant="header-2" spacingTop spacingBottom>
                    {t("contact.title_2")}
                </Typography>

                <div className="flex flex-wrap gap-6">
                    <ActionBlock
                        title="Create a support ticket"
                        subtitle="Contact us and we'll get back to you as soon as possible"
                        button={<SupportButton variant="primary" />}
                    />
                    <ActionBlock
                        title={t("contact.phone_title")}
                        subtitle={t("contact.phone_subtitle")}
                        button={
                            <div className="flex text-brand-eye-orange">
                                <PhoneIcon width={20} height={20} className="mr-2" />
                                <Typography
                                    variant="header-5"
                                    color="text-warning"
                                    className="hover:underline"
                                    shouldPreventWrapping
                                >
                                    <a href={`tel:${phoneNumberToShow}`}>{phoneNumberToShow}</a>
                                </Typography>
                            </div>
                        }
                    />
                </div>
            </div>

            <div>
                <Typography variant="header-2" spacingTop spacingBottom>
                    {t("contact.title_3")}
                </Typography>

                <div className="grid gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mb-8">
                    {Object.values(contacts).map((contact) => {
                        return (
                            <ContactBlock
                                key={contact.name}
                                title={contact.name}
                                address={contact.address}
                                phoneNumber={contact.phoneNumber}
                                mapsLink={contact.mapsLinks}
                                zipCode={contact.zipcode}
                            />
                        )
                    })}
                </div>
            </div>
        </PageLayout>
    )
}
