import { ActionIcon } from "@designSystem/icons/ActionIcon"
import { StatisticBlock } from "@designSystem/layout/StatisticBlock"
import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
import { ChevronDownIcon, CalendarDaysIcon } from "@heroicons/react/24/outline"
import { FlagIcon, EnvelopeIcon } from "@heroicons/react/24/solid"
import DropDown from "@designSystem/overlays/Dropdown/Dropdown"
import Phished from "@assets/awareness/phishedIcon.svg?react"
import { IAwarenessMetric } from "../awarenessTypes"
import { formatYearMonth } from "@utils/dateUtils"
import { calculatePercentage } from "@utils/calculationUtils"
import { DropdownPanel } from "@designSystem/overlays/Dropdown/DropdownPanel"
import { DropDownListItem } from "@designSystem/overlays/Dropdown/DropdownListItem"

interface Props {
    months: string[]
    selectedMonth: string | undefined
    setSelectedMonth: (month: string) => void
    data: IAwarenessMetric | undefined
    isPending: boolean
}

export const MonthlyMetrics: React.FC<Props> = ({ months, selectedMonth, data, isPending, setSelectedMonth }) => {
    const { t } = useTranslation()

    const calculateMetricPercentage = (total?: number, part?: number) => {
        if (total === undefined || part === undefined || total === 0 || part === 0) {
            return 0
        }

        return calculatePercentage(part, total)
    }

    return (
        <div>
            <div className="flex justify-between">
                <Typography variant="header-3">{t("awareness.overview.monthlyInfo.title")}</Typography>

                <DropDown
                    button={
                        <div
                            data-testid="metrics-month-picker"
                            className="h-[36px] w-[200px] border rounded-md px-4 items-center flex gap-2 justify-between"
                        >
                            <div className="flex gap-2 items-center">
                                <CalendarDaysIcon height={24} width={24} className="text-brand-eye-blue" />
                                {selectedMonth ? formatYearMonth(selectedMonth) : ""}
                            </div>
                            <ChevronDownIcon height={16} width={16} className="text-brand-eye-blue" />
                        </div>
                    }
                >
                    {(onClosePanel) => {
                        return (
                            <DropdownPanel>
                                {months.map((month) => {
                                    return (
                                        <DropDownListItem
                                            key={month}
                                            onClick={() => {
                                                setSelectedMonth(month)
                                                onClosePanel()
                                            }}
                                        >
                                            {formatYearMonth(month)}
                                        </DropDownListItem>
                                    )
                                })}
                            </DropdownPanel>
                        )
                    }}
                </DropDown>
            </div>

            <div className="flex gap-6 w-full flex-wrap mt-8">
                <StatisticBlock
                    showSkeleton={isPending}
                    title={t("awareness.overview.monthlyInfo.headers.sent")}
                    errorMessage={data?.sent === undefined ? t("common:dataNotFound") : undefined}
                    icon={<ActionIcon color="gray" icon={<EnvelopeIcon width={16} height={16} />} />}
                    statistic={{
                        number: data?.sent ?? 0,
                        text: "mails",
                    }}
                />

                <StatisticBlock
                    showSkeleton={isPending}
                    title={t("awareness.overview.monthlyInfo.headers.reported")}
                    errorMessage={data?.reported === undefined ? t("common:dataNotFound") : undefined}
                    icon={<ActionIcon color="blue" icon={<FlagIcon width={16} height={16} />} />}
                    statistic={{
                        number: data?.reported ?? 0,
                        text: "mails",
                        description: t("awareness.overview.monthlyInfo.ofSentMails", {
                            count: calculateMetricPercentage(data?.sent, data?.reported),
                        }),
                    }}
                />

                <StatisticBlock
                    showSkeleton={isPending}
                    title={t("awareness.overview.monthlyInfo.headers.phished")}
                    errorMessage={data?.phished === undefined ? t("common:dataNotFound") : undefined}
                    icon={<ActionIcon color="orange" size="small" icon={<Phished width={16} height={16} />} />}
                    statistic={{
                        number: data?.phished ?? 0,
                        text: "mails",
                        description: t("awareness.overview.monthlyInfo.ofSentMails", {
                            count: calculateMetricPercentage(data?.sent, data?.phished),
                        }),
                    }}
                />
            </div>
        </div>
    )
}
