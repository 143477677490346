import { Typography } from "@designSystem/typography/Typography"
import { useTranslation } from "react-i18next"
import { Bar, XAxis, Tooltip, ResponsiveContainer, ComposedChart, Line, TooltipProps } from "recharts"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import ErrorIllustration from "@assets/illustrations/powercordIllustration.svg?react"
import { IAwarenessMetric } from "../awarenessTypes"
import { formatYearMonthToMonth } from "@utils/dateUtils"
import i18n from "@/i18next"
import { BarChartSkeleton } from "@designSystem/feedback/skeletons/BarChartSkeleton"
import { enrichMetricsWithNormalisedSentData } from "@utils/awarenessUtils"
import { designTokens } from "@/styles/variables/designTokens"

const legendItems = {
    sent: {
        color: designTokens.widgetTokens["widget-background-tertiary"].light,
        label: i18n.t("pages:awareness.overview.eventsGraph.legend.sent"),
    },
    reported: {
        color: designTokens.widgetTokens["widget-background-primary"].light,
        label: i18n.t("pages:awareness.overview.eventsGraph.legend.reported"),
    },
    phished: {
        color: designTokens.widgetTokens["widget-background-secondary"].light,
        label: i18n.t("pages:awareness.overview.eventsGraph.legend.phished"),
    },
}

interface Props {
    data: IAwarenessMetric[] | undefined
    isPending: boolean
    isError: boolean
    title?: React.ReactNode
    size?: "small" | "large"
}

const CustomTooltip = ({ active, payload }: TooltipProps<string, string>) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip bg-white border rounded-md px-4 py-2 w-[150px]">
                {payload.map((dataSet) => {
                    if (dataSet.dataKey === "normalisedSent") {
                        return (
                            <div className="flex items-center justify-between" key={dataSet.name}>
                                <div className="flex items-center gap-2">
                                    <div
                                        className="w-[12px] h-[12px] rounded-full"
                                        style={{ background: dataSet.color }}
                                    />
                                    <Typography>{dataSet.name}</Typography>
                                </div>

                                <Typography>{dataSet.payload.sent}</Typography>
                            </div>
                        )
                    }

                    return (
                        <div className="flex items-center justify-between" key={dataSet.name}>
                            <div className="flex items-center gap-2">
                                <div className="w-[12px] h-[12px] rounded-full" style={{ background: dataSet.color }} />
                                <Typography>{dataSet.name}</Typography>
                            </div>

                            <Typography>{dataSet.value}</Typography>
                        </div>
                    )
                })}
            </div>
        )
    }

    return null
}

export const HistoricalEventsGraph: React.FC<Props> = ({ data, isPending, isError, title, size = "large" }) => {
    const { t } = useTranslation()

    // Map the key/value pairs to an array where the key is called label.
    const withNormalisedSentData = data ? enrichMetricsWithNormalisedSentData(data) : []

    const tableData = withNormalisedSentData
        ? withNormalisedSentData
              .map((dataPoint) => {
                  return {
                      label: formatYearMonthToMonth(dataPoint.period),
                      ...dataPoint,
                  }
              })
              // We reverse the array. The API Spits out the data from present -> past
              // But on the screen it looks better to see Jun, Jul, Aug in past -> present format.
              .reverse()
        : []

    const isMissingData = isPending === false && tableData.length === 0

    return (
        <div
            className={`w-full p-6 pb-0 flex flex-col ${size === "small" ? "h-[350px]" : "h-[450px]"}`}
            data-testid="awareness-graph"
        >
            <div className="w-full border-b pb-6 mb-6 flex justify-between items-center">
                {title ? (
                    title
                ) : (
                    <Typography variant="header-3">{t("awareness.overview.eventsGraph.title")}</Typography>
                )}

                <div className="gap-6 hidden lg:flex">
                    {Object.entries(legendItems).map(([key, value]) => {
                        return (
                            <div key={key} className="flex items-center gap-2">
                                <div className="w-[12px] h-[12px] rounded-full" style={{ background: value.color }} />
                                <Typography color="text-secondary" variant="body-2" className="capitalize ">
                                    {value.label}
                                </Typography>
                            </div>
                        )
                    })}
                </div>
            </div>

            {isError && (
                <div className="flex-1 flex items-center ml-10">
                    <NoDataMessage
                        title={t("awareness.overview.eventsGraph.messages.errorMessage.title")}
                        subtitle={t("awareness.overview.eventsGraph.messages.errorMessage.description")}
                        variant="row"
                        image={<ErrorIllustration className="w-[250px]" />}
                    />
                </div>
            )}

            {isMissingData && !isError && (
                <div className="flex-1 flex items-center ml-10">
                    <NoDataMessage
                        title={t("awareness.overview.eventsGraph.messages.noDataMessage.title")}
                        subtitle={t("awareness.overview.eventsGraph.messages.noDataMessage.description")}
                        variant="row"
                        image={<SearchIllustration className="w-[250px]" />}
                    />
                </div>
            )}

            {isPending && <BarChartSkeleton />}

            {!isMissingData && !isPending && (
                <ResponsiveContainer width="100%">
                    <ComposedChart
                        data={tableData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                        barGap={0}
                    >
                        <XAxis dataKey="label" />

                        <Tooltip content={<CustomTooltip />} />

                        <Line
                            name={legendItems.sent.label}
                            type="monotone"
                            dataKey="normalisedSent"
                            strokeWidth={2}
                            stroke={legendItems.sent.color}
                            fill={legendItems.sent.color}
                            dot={{ r: 5, width: 1 }}
                        />

                        <Bar
                            name={legendItems.reported.label}
                            dataKey="reported"
                            fill={legendItems.reported.color}
                            radius={[5, 5, 0, 0]}
                            barSize={24}
                        />
                        <Bar
                            name={legendItems.phished.label}
                            dataKey="phished"
                            fill={legendItems.phished.color}
                            radius={[5, 5, 0, 0]}
                            barSize={24}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </div>
    )
}
