import { toastService } from "@/services/toastService"
import { Mutation, MutationCache, Query, QueryCache, QueryClient, QueryKey } from "@tanstack/react-query"
import { AxiosError } from "axios"

// Indicates network statusses that should throw an error
// Therefore these errors will trigger the error page to be shown.
export const THROWABLE_NETWORK_ERRORS = [403]

// The amount of retries react-query will do before going into error state
const RETRY_COUNT = 3

export const setupQueryClient = (): QueryClient => {
    // Setup of React Query
    const queryClient = new QueryClient({
        // Setup of custom query/mutation handling functions
        defaultOptions: {
            // For certain request errors we will use the error boundary to show a global error screen.
            queries: {
                throwOnError: isThrowableNetworkError,
                refetchOnWindowFocus: false,
                staleTime: 10 * (60 * 4000),
                retry(failureCount, error) {
                    // Throwable network errors should never retry.
                    if (isThrowableNetworkError(error)) {
                        return false
                    }

                    // The function will keep retrying untill the failurecount exceeds the RETRY_COUNT
                    if (failureCount < RETRY_COUNT) {
                        return true
                    }

                    return false
                },
            },
            mutations: {
                throwOnError: isThrowableNetworkError,
            },
        },
        queryCache: new QueryCache({
            onError: handleQueryError,
        }),
        mutationCache: new MutationCache({
            onError: handleMutationError,
        }),
    })

    return queryClient
}

// Custom query error handler. Looks at a possible error message in the meta object.
// If so, sends a toast message with the meta info.
export const handleQueryError = (_error: unknown, query: Query<unknown, unknown, unknown, QueryKey>) => {
    const errorMessage = query.meta?.errorMessage as string | undefined
    if (errorMessage) {
        toastService.errorMessage(errorMessage)
    }
}

// Custom mutation error handler. Looks at a possible error message in the meta object.
// If so, sends a toast message with the meta info.
export const handleMutationError = (
    _error: unknown,
    _variables: unknown,
    _context: unknown,
    mutation: Mutation<unknown, unknown, unknown, unknown>,
) => {
    const errorMessage = mutation.meta?.errorMessage as string | undefined

    if (errorMessage) {
        toastService.errorMessage(errorMessage)
    }
}

// Based on the error type, this function will determine if the error will be thrown
// by react-query into our error boundary component.
export const isThrowableNetworkError = (error: unknown): boolean => {
    if (error instanceof AxiosError) {
        const status = error?.response?.status

        if (status !== undefined && THROWABLE_NETWORK_ERRORS.includes(status)) {
            return true
        }
    }

    return false
}
