import React from "react"
import { IAwarenessEvent } from "../awarenessTypes"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import { Table, TableHeader, TableRow, TableBody, TableCell, TableHeaderCell } from "@designSystem/layout/table/Table"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { Button } from "@designSystem/buttons/Button"
import { eventsTableColumns } from "./eventsTableColumns"
import { Skeleton } from "@designSystem/feedback/skeletons/Skeleton"
import { useTranslation } from "react-i18next"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import CelebrationIllustration from "@assets/illustrations/celebrationIllustration.svg?react"

interface Props {
    awarenessEvents: IAwarenessEvent[]
    hasNextPage: boolean
    fetchNextPage: () => void
    isPending: boolean
    isFetching: boolean
    eventsType: IAwarenessEvent["type"]
}

const EventsTableSkeleton: React.FC = () => {
    return (
        <>
            <tr>
                <TableCell>
                    <Skeleton className="w-[60%] h-[25px]" />
                </TableCell>
                <TableCell>
                    <Skeleton className="w-[60%] h-[25px]" />
                </TableCell>
            </tr>
            <tr>
                <TableCell>
                    <Skeleton className="w-[60%] h-[25px]" />
                </TableCell>
                <TableCell>
                    <Skeleton className="w-[60%] h-[25px]" />
                </TableCell>
            </tr>
            <tr>
                <TableCell>
                    <Skeleton className="w-[60%] h-[25px]" />
                </TableCell>
                <TableCell>
                    <Skeleton className="w-[60%] h-[25px]" />
                </TableCell>
            </tr>
        </>
    )
}

export const EventsTable: React.FC<Props> = ({
    awarenessEvents,
    hasNextPage,
    fetchNextPage,
    isPending,
    isFetching,
    eventsType,
}) => {
    const table = useReactTable({
        data: awarenessEvents,
        columns: eventsTableColumns,
        getCoreRowModel: getCoreRowModel(),
    })

    const { t } = useTranslation()
    const showEmptyDataMessage = table.getRowModel().rows.length === 0 && isPending === false

    return (
        <div className="border rounded-md">
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHeaderCell key={header.id} width={header.getSize()}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>

                <TableBody>
                    {showEmptyDataMessage && (
                        <TableRow>
                            <td colSpan={table.getAllColumns().length} className="p-12">
                                {eventsType === "phished" ? (
                                    <NoDataMessage
                                        title={t(
                                            "awareness.overview.eventsGraph.messages.noPhisingEventsMessage.title",
                                        )}
                                        subtitle={t(
                                            "awareness.overview.eventsGraph.messages.noPhisingEventsMessage.description",
                                        )}
                                        variant="row"
                                        image={<CelebrationIllustration className="w-[250px]" />}
                                    />
                                ) : (
                                    <NoDataMessage
                                        title={t("awareness.overview.eventsGraph.messages.noDataMessage.title")}
                                        variant="row"
                                        image={<SearchIllustration className="w-[250px]" />}
                                    />
                                )}
                            </td>
                        </TableRow>
                    )}

                    {isPending && <EventsTableSkeleton />}

                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            {hasNextPage && (
                <div className="border-t flex justify-center p-4">
                    <Button variant="text" onClick={() => fetchNextPage()} isLoading={isFetching} disabled={isFetching}>
                        {t("common:loadMore")}
                    </Button>
                </div>
            )}
        </div>
    )
}
