import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
import { InformationCircleIcon, ClockIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { StateIllustration } from "@components/stateIllustration/StateIllustration"

export const SentinelOnboardingInProgress: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="flex justify-center">
            <div className="max-w-[720px]">
                <div className="flex justify-center mt-12">
                    <StateIllustration variant="Loading" />
                </div>
                <div className="space-y-4 mt-8">
                    <Typography variant="header-1" className="text-center">
                        {t("sentinelOnboarding:finishedOnboarding.title")}
                    </Typography>
                    <Typography variant="body-1" className="text-center">
                        {t("sentinelOnboarding:finishedOnboarding.subtitle")}
                    </Typography>
                </div>

                <div className="space-y-8 flex-1 mt-8">
                    <div className="border rounded-md p-6">
                        <Typography variant="body-1-semibold">
                            {t("sentinelOnboarding:finishedOnboarding.additionalDetails")}
                        </Typography>

                        <div className="flex mt-4">
                            <div className="flex-1">
                                <div className="flex items-center gap-2 mb-2">
                                    <InformationCircleIcon width={20} className="text-text-secondary-light" />
                                    <Typography color="text-secondary">
                                        {t("sentinelOnboarding:finishedOnboarding.stepSections.status")}
                                    </Typography>
                                </div>

                                <div className="inline-block py-1 px-2 bg-background-warning-light rounded-md">
                                    <Typography color="text-warning" variant="body-2">
                                        {t("sentinelOnboarding:finishedOnboarding.stepSections.progress")}
                                    </Typography>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="flex items-center gap-2 mb-2">
                                    <ClockIcon width={20} className="text-text-secondary-light" />
                                    <Typography color="text-secondary">
                                        {t("sentinelOnboarding:finishedOnboarding.stepSections.timeIndication")}
                                    </Typography>
                                </div>
                                <Typography>
                                    {t("sentinelOnboarding:finishedOnboarding.stepSections.eyeTimeIndication")}
                                </Typography>
                            </div>
                            <div className="flex-1">
                                <div className="flex items-center gap-2 mb-2">
                                    <MagnifyingGlassIcon width={20} className="text-text-secondary-light" />
                                    <Typography color="text-secondary">
                                        {t("sentinelOnboarding:finishedOnboarding.stepSections.resources")}
                                    </Typography>
                                </div>
                                <a
                                    rel="noopener noreferrer"
                                    href={"https://kb.eye.security/faq/microsoft-sentinel"}
                                    target="_blank"
                                    className="hover:underline"
                                >
                                    <Typography color="text-link">
                                        {t("sentinelOnboarding:finishedOnboarding.stepSections.sentinelFAQ")}
                                    </Typography>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
