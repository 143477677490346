import { SortableTableHeader } from "@designSystem/layout/table/SortableTableHeader"
import {
    SortableTableHeaderCell,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "@designSystem/layout/table/Table"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { IThreatHuntAsset } from "../threatHuntingTypes"
import i18n from "@/i18next"
import { Typography } from "@designSystem/typography/Typography"
import React from "react"

interface Props {
    assets: Array<IThreatHuntAsset>
}

const columnHelper = createColumnHelper<IThreatHuntAsset>()

const tableColumns = [
    columnHelper.accessor("name", {
        header: i18n.t("threatHunting:table.columns.name"),
        size: 300,
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping>
                {info.getValue()}
            </Typography>
        ),
    }),
    columnHelper.accessor("type", {
        header: i18n.t("threatHunting:table.columns.type"),
        size: 300,
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping>
                {info.getValue()}
            </Typography>
        ),
    }),
]

export const ThreatHuntAssetsTable: React.FC<Props> = ({ assets }) => {
    const table = useReactTable({
        data: assets,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <TablePaginationWrapper isRounded data={assets} table={table}>
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <SortableTableHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader {...header.getContext()}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </SortableTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}
