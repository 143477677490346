import { Typography } from "@designSystem/typography/Typography"
import React, { useContext } from "react"
import { NotificationPreference } from "./NotificationPreference"
import { useTranslation } from "react-i18next"
import { UserContext } from "@/providers/UserContext"
import { useUserSettings } from "./hooks/useUserSettings"
import { Skeleton } from "@designSystem/feedback/skeletons/Skeleton"
import { isNullish } from "@utils/formatUtils"

export const NotificationSettings: React.FC = () => {
    const { t } = useTranslation(["common"])
    const { userOrganisations } = useContext(UserContext)
    const { data: userSettingsData, isPending } = useUserSettings()

    return (
        <div className="text-left flex flex-col">
            <div className="border-b p-6">
                <Typography variant="header-3" spacingBottom>
                    {t("common:userMenu.notifications.emailNotifications")}
                </Typography>
                <Typography> {t("common:userMenu.notifications.emailSubtitle")}</Typography>
            </div>

            {isPending && (
                <div className="border-b p-6 space-y-4">
                    <Skeleton className="h-[20px] w-[100%]" />
                    <Skeleton className="h-[40px] w-[100%" />
                </div>
            )}
            <div className="divide-y">
                {!isPending &&
                    userOrganisations.map((organisation) => {
                        // Check if a setting is found for the organistion
                        const organistionUserSetting = userSettingsData?.data.settings?.find(
                            (setting) => setting.eyed === organisation.eyed,
                        )

                        // If we have an organistion without a setting, we simply show nothing ( should not happen )
                        if (isNullish(organistionUserSetting)) {
                            return null
                        }

                        return (
                            <NotificationPreference
                                key={`notification-preference-${organisation.eyed}`}
                                organisation={organisation}
                                userSetting={organistionUserSetting}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
