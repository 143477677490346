import React, { Fragment } from "react"
import { Typography } from "../../typography/Typography"

interface Props {
    title: string
    subtitle?: string
    image?: React.ReactNode
    variant?: "row" | "column"
    action?: React.ReactNode
}

export const NoDataMessage: React.FC<Props> = ({ title, subtitle, image, action, variant = "widget" }) => {
    if (variant === "row") {
        return (
            <div className={`flex flow-row items-center`}>
                {image && <Fragment>{image}</Fragment>}
                <div className="ml-8">
                    <Typography variant="header-2" spacingTop spacingBottom>
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography variant="body-1" color="text-secondary" spacingBottom>
                            {subtitle}
                        </Typography>
                    )}
                </div>
                {action && action}
            </div>
        )
    }

    if (variant === "column") {
        return (
            <div className={`flex flex-col items-center justify-center`}>
                {image && <Fragment>{image}</Fragment>}
                <div className="ml-8">
                    <Typography variant="header-2" spacingTop spacingBottom className="text-center">
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography variant="body-1" spacingBottom className="text-center" withTextWrapping>
                            {subtitle}
                        </Typography>
                    )}
                    {action && action}
                </div>
            </div>
        )
    }
}
