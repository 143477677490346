import { Typography } from "@designSystem/typography/Typography"
import { IAnnouncement } from "@utils/announcementUtils"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
    announcement: IAnnouncement
}

export const AnnouncementBlock: React.FC<Props> = ({ announcement }) => {
    const { t } = useTranslation()
    return (
        <div className="flex gap-6">
            <div className="w-[45px]">
                <Typography color="text-secondary">{announcement.date}</Typography>
            </div>

            <div>
                <div className="border-l px-6">
                    <Typography spacingBottom variant="header-4">
                        {announcement.title}
                    </Typography>
                    <Typography>{announcement.description}</Typography>

                    {announcement.moreInfoLink && (
                        <div className="mt-4">
                            <Typography>{`${t("announcements.moreInfoLink")}:`}</Typography>
                            <a
                                rel="noopener noreferrer"
                                href={announcement.moreInfoLink}
                                target="_blank"
                                className="hover:underline text-text-link-light"
                            >
                                {announcement.moreInfoLink}
                            </a>
                        </div>
                    )}
                </div>

                <div className="flex px-6 items-center gap-4 mt-6">
                    <Typography color="text-secondary">Time:</Typography>
                    <Typography>{announcement.time}</Typography>
                </div>
            </div>
        </div>
    )
}
