import { IRecommendation } from "@features/recommendations/recommendationTypes"

export const getPriorityColor = (priority: IRecommendation["priority"]): string => {
    switch (priority) {
        case "Low":
        case "Unknown":
            return "bg-text-secondary-light"
        case "Medium":
            return "bg-text-warning-light"
        case "Critical":
            return "bg-text-critical-light"

        default:
            return ""
    }
}
