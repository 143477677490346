import { ClientDetectionAssset, IDetection } from "@features/detections/detectionsTypes"
import { groupBy } from "lodash"

export type ResolutionStatus =
    | "Normal behaviour, nothing wrong"
    | "Adversarial behaviour, resolved"
    | "Undesired behaviour, not dangerous"
    | "Unsuccessful adversarial behaviour, not dangerous"

export type ResolutionStatusSummary = "Normal behaviour" | "Abnormal behaviour" | "Malicious behaviour"

export const getResolutionStatus = (status?: string): { label: ResolutionStatusSummary; description: string } => {
    switch (status) {
        case "LA":
        case "FP":
        case "FP ESC":
            return {
                label: "Normal behaviour",
                description:
                    "In this situation everything is normal behaviour and no intervention or actions were needed.",
            }

        case "MA":
        case "TP":
        case "TP CONT":
        case "TP ESC":
            return {
                label: "Malicious behaviour",
                description: "In this situation there was malicious behaviour and is resolved by our analysts.",
            }

        case "LUND":
            return {
                label: "Abnormal behaviour",
                description: "In this situation there was abnormal behaviour, no intervention or actions was needed.",
            }

        case "MNOT":
        case "TP NOT":
        case "MA NOT":
            return {
                label: "Malicious behaviour",
                description:
                    "In this situation there was malicious behaviour, classified as an unsuccessful attempt and no intervention or action was needed.",
            }
    }

    return {
        label: "Normal behaviour",
        description: "In this situation everything is normal behaviour and no intervention or actions were needed.",
    }
}

export const getDetectionsSummary = (detections: IDetection[]): ClientDetectionAssset[] => {
    const groupedDetections = groupBy(detections, "category")

    return Object.entries(groupedDetections).map(([key, value]) => {
        return {
            category: key,
            occurrence: value.length,
        }
    })
}
