import React, { Fragment } from "react"
import { useBreadcrumbs } from "../../../hooks/useBreadcrumbs"
import { Link } from "react-router-dom"
import { ChevronRightIcon } from "@heroicons/react/24/solid"
import { HomeIcon } from "@heroicons/react/24/outline"
import { Typography } from "../typography/Typography"

export const Breadcrumbs: React.FC = () => {
    const breadcrumbs = useBreadcrumbs()

    return (
        <div>
            <div className="hidden lg:inline-flex items-center text-sans text-base text-brand-eye-blue cursor-pointer space-x-1 w-full">
                {breadcrumbs.map((breadcrumb, index) => {
                    return (
                        <Fragment key={`breadcrumb-${breadcrumb.to}`}>
                            {index === 0 && <HomeIcon className="inline text-blue-500 mr-1 " width={16} height={16} />}
                            {index > 0 && (
                                <ChevronRightIcon className=" text-blue-500 mt-[2px]" width={12} height={12} />
                            )}
                            <Link className={`text-eye-blue-700 hover:underline`} to={breadcrumb.to}>
                                <Typography shouldPreventWrapping>{breadcrumb.label}</Typography>
                            </Link>
                        </Fragment>
                    )
                })}
            </div>
        </div>
    )
}
