import { UserContext } from "@/providers/UserContext"
import { Typography } from "@designSystem/typography/Typography"
import Tippy from "@tippyjs/react"
import { OVERDUE_INDICATOR, getInsuranceLabelText } from "@utils/threatHuntingUtils"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { IThreatHunt, IThreatHuntWithAssets } from "../threatHuntingTypes"

interface Props {
    threatHunt: IThreatHunt | IThreatHuntWithAssets
}

const labelClass = "inline-flex rounded-2xl px-3 py-0.5"

export const ThreatHuntInsuranceLabel: React.FC<Props> = ({ threatHunt }) => {
    const { activeOrganisation } = useContext(UserContext)
    const labelText = getInsuranceLabelText(threatHunt.startDate)
    const { t } = useTranslation()

    if ("assets" in threatHunt === false) {
        return null
    }

    if (threatHunt.acknowledged === true) {
        return null
    }

    if (activeOrganisation?.insured !== true) {
        return null
    }

    if (labelText === null) {
        return null
    }

    if (labelText === OVERDUE_INDICATOR) {
        return (
            <Tippy content={t("threatHunting:table.insurance.overdueLabel")}>
                <div className={`bg-background-critical-light ${labelClass}`}>
                    <Typography variant="body-2-semibold" color="text-critical">
                        {t("threatHunting:table.insurance.overdue")}
                    </Typography>
                </div>
            </Tippy>
        )
    }

    return (
        <Tippy content={t("threatHunting:table.insurance.hoursLeftLabel")}>
            <div className={`bg-background-purple-light ${labelClass}`}>
                <Typography variant="body-2-semibold" color="text-purple">
                    {labelText}
                </Typography>
            </div>
        </Tippy>
    )
}
