import React, { PropsWithChildren } from "react"
import { ArrowPathIcon } from "@heroicons/react/24/solid"
import classNames from "classnames"

export type ButtonVariant = "primary" | "secondary" | "text"
export type ButtonSize = "small" | "large"

interface Props {
    onClick?: () => void
    className?: string
    variant: ButtonVariant
    size?: ButtonSize
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    isLoading?: boolean
    disabled?: boolean
    withPadding?: boolean
}

export const Button: React.FC<PropsWithChildren<Props>> = ({
    children,
    onClick,
    variant,
    className,
    startIcon,
    endIcon,
    size = "large",
    isLoading = false,
    disabled = false,
    withPadding = true,
}) => {
    const classes = classNames([
        { "base variant-primary": variant === "primary" },
        { "base variant-secondary": variant === "secondary" },
        { "base variant-text": variant === "text" },
        { "size-small": size === "small" },
        { "size-large": size === "large" },
        { "no-padding": withPadding === false },
        className,
    ])

    return (
        <button className={classes} onClick={onClick} disabled={disabled}>
            {isLoading && <ArrowPathIcon className="animate-spin h-4 w-4 mr-2" />}
            {startIcon && !isLoading && <div className="mr-1">{startIcon}</div>}
            <span>{children}</span>
            {endIcon && <div className="ml-1">{endIcon}</div>}
        </button>
    )
}
