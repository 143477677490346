import { pick } from "lodash"
import { IRecommendation, WhiteListedAsset } from "../features/recommendations/recommendationTypes"
import { isNullish } from "./formatUtils"

export const EAST_RECOMMENDATION_ID = "215"

export const experimentalRiskRecommendations = ["500-potentially-valid", "500-likely-valid", "631-phishing-statistic"]

const recommendationAssetsWhitelist: Array<WhiteListedAsset | string> = [
    "Hostname",
    "MAC address",
    "Local IP address",
    "External IP address",
    "Operating system",
    "Name",
    "Device type",
    "Type",
    "Tenant",
    "Username",
    "Agent ID",
    "CVE number",
    "CVE reference",
    "Domain",
    "Record",
    "Discovered by",
    "Implementation status",
    "Last scanned",
    "Port number",
    "Device vendor",
    "Version",
    "Date",
    "Time (UTC)",
    "Details",
    "Tenant ID",
    "Password",
    "Leak",
    "Leak date",
    "Password last changed",
    "Password strength",
]

export enum RecommendationColumn {
    "issue",
    "priority",
    "effort",
    "last_scanned",
}

/**
 * Returns a array with unique column names.
 * Goes through the asset data and finds all the asset value keys.
 * @param assets
 * @returns
 */
export const getAssetColumns = (assets?: IRecommendation["assets"] | null): string[] => {
    if (isNullish(assets)) {
        return []
    }

    const uniqueAssetColumns = new Set(
        Object.values(assets).flatMap((asset) => {
            if (isNullish(asset)) {
                // if the asset does not exist, we return an empty string ( to maintain the integrity of the set)
                return ""
            }

            // Go through the keys, filter out any keys that are not present in the recommendationAssetsWhitelist
            const whitelistedKeys = Object.keys(asset).filter((key) => recommendationAssetsWhitelist.includes(key))

            return whitelistedKeys
        }),
    )

    // Filter out empty strings so we only return actual column names
    return Array.from(uniqueAssetColumns).filter((column) => column !== "")
}

/**
 * Orders the columns it gets by the recommendationAssetsWhitelist ordering.
 * @param columns
 * @returns
 */
export const orderAssetColumns = (columns: string[]): string[] => {
    // Also use spread for mutability reasons
    // In the future we can use toSorted() when it's included in typescript.
    return [...columns].sort((a, b) => {
        return recommendationAssetsWhitelist.indexOf(a) - recommendationAssetsWhitelist.indexOf(b)
    })
}

export const getWhitelistedRecommendationAsset = (assets: IRecommendation["assets"]): IRecommendation["assets"] => {
    if (isNullish(assets)) {
        return {}
    }

    // Create a key/value array of the assets
    const assetCollection = Object.entries(assets)

    // Go through all assets and create an object with only the columns as defined in the recommendationAssetsWhitelist using the pick method.
    const whitelisted = assetCollection.map(([key, value]) => {
        const assetWithOnlyWhitelistedProperties = pick(value, recommendationAssetsWhitelist)
        return { key, value: assetWithOnlyWhitelistedProperties }
    })

    // Map the key/value assets array back to a dictionary as used in the recommendation
    const updatedAssetCollection = whitelisted.reduce((prev, current) => {
        return {
            ...prev,
            [current.key]: current.value,
        }
    }, {})

    return updatedAssetCollection
}
