import { Typography } from "@designSystem/typography/Typography"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import { formatDate, getTimestampFromJSDate } from "@utils/dateUtils"
import classNames from "classnames"
import React from "react"
import { CaptionProps, useNavigation } from "react-day-picker"

export const DateRangePickerHeader: React.FC<CaptionProps> = ({ displayMonth }) => {
    const { goToMonth, nextMonth, previousMonth } = useNavigation()

    return (
        <div>
            <div className="flex justify-between mb-4">
                <div
                    onClick={() => previousMonth && goToMonth(previousMonth)}
                    className={classNames(
                        { "text-neutral-300": previousMonth === undefined },
                        "hover:text-brand-eye-orange cursor-pointer",
                    )}
                >
                    <ChevronLeftIcon width={20} height={20} />
                </div>

                <Typography variant="header-4">
                    {formatDate(getTimestampFromJSDate(displayMonth), "MMMM yyyy")}
                </Typography>

                <div
                    onClick={() => nextMonth && goToMonth(nextMonth)}
                    className={classNames(
                        { "text-neutral-300": nextMonth === undefined },
                        { "hover:text-brand-eye-orange cursor-pointer": nextMonth !== undefined },
                        "",
                    )}
                >
                    <ChevronRightIcon width={20} height={20} />
                </div>
            </div>
        </div>
    )
}
