import React, { Fragment, useState } from "react"
import { Typography, TypographyProps } from "./Typography"
import { Button } from "@designSystem/buttons/Button"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid"

interface Props {
    text: string
    collapseAfter?: number
    typographyProps?: TypographyProps
    renderText?: (text: string) => React.ReactNode
    className?: string
    readMoreText?: string
    readLessText?: string
}

const collapseThreshold = 50

export const ReadMoreSection: React.FC<Props> = ({
    text,
    collapseAfter = 50,
    typographyProps,
    className = "",
    readLessText = "Read less",
    readMoreText = "Read more",
    renderText,
}) => {
    const shouldHaveCollapse = text.split(" ").length > collapseAfter + collapseThreshold
    const [open, setOpen] = useState(false)

    const renderTextValue = (value: string, isCollapsed: boolean): React.ReactNode => {
        const textWithDots = isCollapsed ? `${value}...` : value
        if (renderText) {
            return renderText(textWithDots)
        }

        return <Typography {...typographyProps}>{textWithDots}</Typography>
    }

    if (shouldHaveCollapse) {
        if (open) {
            return (
                <div className={className}>
                    {renderTextValue(text, false)}
                    <div className="mt-1">
                        <Button
                            variant="text"
                            onClick={() => setOpen(!open)}
                            startIcon={<ChevronUpIcon width={20} height={20} />}
                            withPadding={false}
                        >
                            {readLessText}
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div className={className}>
                {renderTextValue(text.split(" ").slice(0, collapseAfter).join(" "), true)}

                <div className="mt-1">
                    <Button
                        variant="text"
                        onClick={() => setOpen(!open)}
                        startIcon={<ChevronDownIcon width={20} height={20} />}
                        withPadding={false}
                    >
                        {readMoreText}
                    </Button>
                </div>
            </div>
        )
    }

    return <Fragment>{renderTextValue(text, false)}</Fragment>
}
