import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { Typography } from "@designSystem/typography/Typography"
import { useUserOrganisations } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { formatDate } from "@utils/dateUtils"
import { formatPercentageValue, isNullish, showWithFallbackValue } from "@utils/formatUtils"
import React, { PropsWithChildren } from "react"

const OrganisationBlock: React.FC<PropsWithChildren> = ({ children }) => {
    return <div className="border  rounded-lg">{children}</div>
}

const PropertyRow: React.FC<{ title: string; value: string | number | undefined | boolean }> = ({ title, value }) => {
    return (
        <div className="flex gap-4 py-2 px-3">
            <Typography variant="body-1" className="w-[200px]">
                {title}
            </Typography>
            <Typography variant="body-1-semibold">{value}</Typography>
        </div>
    )
}

export const AwarenessOrganisationsAdmin: React.FC = () => {
    const { data: userOrganisationsData } = useUserOrganisations()

    const organisationsWithAwarenessInContract =
        userOrganisationsData?.data?.filter((organisation) => organisation.awareness_in_contract === true) ?? []

    const hasFinishedOnboarding = organisationsWithAwarenessInContract.filter(
        (org) => !isNullish(org.awareness?.onboarded_at),
    )
    const percentageFinishedOnboarding =
        (hasFinishedOnboarding.length / organisationsWithAwarenessInContract.length) * 100

    return (
        <PageLayout variant="detailPage">
            <PageHeader
                title="Awareness Organisations"
                subtitle="Shows every organisations with awareness in contract"
            />
            <div className="space-y-6">
                <div className="block w-[300px]">
                    <div className="border flex flex-col p-4 rounded-md">
                        <Typography variant="body-1-semibold">Finished onboarding</Typography>
                        <Typography variant="header-3">
                            {formatPercentageValue(percentageFinishedOnboarding)}
                        </Typography>
                        <div className="mt-4">
                            <Typography variant="body-1">{`Organisation with awareness: ${organisationsWithAwarenessInContract.length}`}</Typography>
                            <Typography variant="body-1">{`Finished onboarding: ${hasFinishedOnboarding.length}`}</Typography>
                        </div>
                    </div>
                </div>

                <div className="space-y-6">
                    {organisationsWithAwarenessInContract.map((organisation) => {
                        return (
                            <OrganisationBlock key={`awareness-organistion-${organisation.eyed}`}>
                                <div className="p-3">
                                    <Typography variant="header-4">{organisation.name}</Typography>
                                </div>
                                <div className="divide-y border-t">
                                    <PropertyRow
                                        title="Active"
                                        value={`${showWithFallbackValue(organisation.awareness?.active)}`}
                                    />
                                    <PropertyRow
                                        title="Default language"
                                        value={`${showWithFallbackValue(organisation.awareness?.default_language?.Name)}`}
                                    />
                                    <PropertyRow
                                        title="In contract"
                                        value={`${showWithFallbackValue(organisation.awareness?.in_contract)}`}
                                    />
                                    <PropertyRow
                                        title="Last event received"
                                        value={`${organisation.awareness?.last_event_received_at ? formatDate(organisation.awareness.last_event_received_at) : "-"}`}
                                    />
                                    <PropertyRow
                                        title="Onboarded at"
                                        value={`${organisation.awareness?.onboarded_at ? formatDate(organisation.awareness.onboarded_at) : "-"}`}
                                    />
                                    <PropertyRow
                                        title="Onboarding step"
                                        value={`${showWithFallbackValue(organisation.awareness?.onboarding_step)}`}
                                    />
                                    <PropertyRow
                                        title="Recipient source"
                                        value={`${showWithFallbackValue(organisation.awareness?.recipient_source)}`}
                                    />
                                    <PropertyRow
                                        title="Shows reporting"
                                        value={`${showWithFallbackValue(organisation.awareness?.show_reporting)}`}
                                    />
                                </div>
                            </OrganisationBlock>
                        )
                    })}
                </div>
            </div>
        </PageLayout>
    )
}
