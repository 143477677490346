import React from "react"
import { ChevronRightIcon } from "@heroicons/react/24/solid"
import { Typography } from "../typography/Typography"

interface Props {
    icon: React.ReactNode
    title: string
    onClick?: () => void
}

export const LinkBlock: React.FC<Props> = ({ icon, title, onClick }) => {
    return (
        <div
            className="p-5 bg-white drop-shadow-eyeShadow rounded-md flex justify-between items-center flex-1"
            onClick={onClick}
        >
            <div className="flex items-center">
                <div className="w-5 h-5 text-brand-eye-orange">{icon}</div>
                <Typography className="ml-2" shouldPreventWrapping>
                    {title}
                </Typography>
            </div>

            <div className="flex items-center w-[15px] h-[6px] text-neutral-100">
                <ChevronRightIcon />
            </div>
        </div>
    )
}
