const brand = {
    ["eye-orange"]: "#F49719",
    ["eye-blue"]: "#192240",
    ["eye-ice"]: "#B2D3EB",
}

const green = {
    100: "#E6F1E4",
    200: "#B7D7B1",
    300: "#92C38A",
    400: "#6EB063",
    500: "#538F49",
    600: "#305D27",
    700: "#1C3018",
}

const red = {
    100: "#F8DDE3",
    200: "#E7889B",
    300: "#DC4C69",
    400: "#BB2543",
    500: "#8C1C32",
    600: "#5E1221",
    700: "#2F0911",
}

const blue = {
    100: "#E3EBF8",
    200: "#B2D3EB",
    300: "#89B7DC",
    400: "#689ACF",
    500: "#3969AC",
    600: "#22365E",
    700: "#192240",
}

const orange = {
    100: "#FFECCC",
    200: "#FDD291",
    300: "#FFA038",
    400: "#E46E02",
    500: "#CB5D02",
    600: "#B14C01",
    700: "#7A2F00",
}

const neutral = {
    100: "#F8F9FA",
    200: "#F4F5F7",
    300: "#E8EAEE",
    400: "#DDDFE5",
    500: "#909CA9",
    600: "#687688",
    700: "#545F6E",
    800: "#444A51",
    white: "#fff",
}

const yellow = {
    100: "#FAF1D1",
    200: "#FBDC7E",
    300: "#EDBC1D",
    400: "#CAA016",
    500: "#A18117",
    600: "#796215",
    700: "#4F4112",
}

const purple = {
    100: "#F3EAF6",
    200: "#E2CBE7",
    300: "#CCA8D7",
    400: "#B787C5",
    500: "#9C62B2",
    600: "#734488",
    700: "#442A51",
}

export const colorPalette = {
    brand,
    green,
    red,
    blue,
    orange,
    neutral,
    yellow,
    purple,
}
