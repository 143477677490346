import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { ActionIcon } from "@designSystem/icons/ActionIcon"
import { Typography } from "@designSystem/typography/Typography"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { HomeIcon, ClockIcon, ClipboardDocumentIcon, TrophyIcon, CheckBadgeIcon } from "@heroicons/react/24/outline"
import { Button } from "@designSystem/buttons/Button"
import OverviewImage from "@assets/awareness/overview-image.png"
import Modal from "@designSystem/overlays/modal/Modal"
import ContactIllustration from "@/assets/illustrations/contactIllustration.svg?react"
import SuccessIllustration from "@/assets/illustrations/successIllustration.svg?react"
import { useAwarenessUpsellMessage } from "../hooks/useAwarenessUpsellMessage"
import { useActiveEyed } from "@hooks/useActiveEyed"

export const UpsellFlow: React.FC = () => {
    const { t } = useTranslation("pages")
    const [isRequestModalActive, setIsRequestModalActive] = useState(false)
    const [isCompleteModalActive, setIsCompleteModalActive] = useState(false)
    const upsellEntries = t("awareness.upsell.entries", { returnObjects: true }) ?? []
    const activeEyed = useActiveEyed()

    const onUpsellMessageSuccess = () => {
        setIsCompleteModalActive(true)
    }

    const { mutate: sendAwarenessUpsellMessage } = useAwarenessUpsellMessage(onUpsellMessageSuccess)

    const getUpsellEntryIcon = (entry: string) => {
        switch (entry) {
            case "scenarios":
                return <ActionIcon color="orange" size="large" icon={<ClockIcon width={24} />} />
            case "feedback":
                return <ActionIcon color="orange" size="large" icon={<CheckBadgeIcon width={24} />} />
            case "scores":
                return <ActionIcon color="orange" size="large" icon={<TrophyIcon width={24} />} />
            case "reporting":
                return <ActionIcon color="orange" size="large" icon={<ClipboardDocumentIcon width={24} />} />
            default:
                return <ActionIcon color="orange" size="large" icon={<HomeIcon width={24} />} />
        }
    }

    return (
        <PageLayout variant="detailPage">
            <Modal open={isRequestModalActive} setOpen={setIsRequestModalActive} className="p-10" withCloseButton>
                <div className="w-[400px]">
                    <div className="flex justify-center mb-4">
                        <ContactIllustration />
                    </div>

                    <Typography variant="header-2">{t("awareness.upsell.modal.request.title")}</Typography>
                    <Typography className="mt-4">{t("awareness.upsell.modal.request.description")}</Typography>
                    <Button
                        variant="primary"
                        className="w-full justify-center mt-6"
                        onClick={() => {
                            setIsRequestModalActive(false)
                            sendAwarenessUpsellMessage({ eyed: activeEyed })
                        }}
                    >
                        {t("awareness.upsell.modal.request.button")}
                    </Button>
                </div>
            </Modal>

            <Modal open={isCompleteModalActive} setOpen={setIsCompleteModalActive} className="p-10" withCloseButton>
                <div className="w-[400px]">
                    <div className="flex justify-center mb-4">
                        <SuccessIllustration />
                    </div>

                    <Typography variant="header-2">{t("awareness.upsell.modal.completed.title")}</Typography>
                    <Typography className="mt-4">{t("awareness.upsell.modal.completed.description")}</Typography>
                    <Button
                        variant="primary"
                        className="w-full justify-center mt-6"
                        onClick={() => {
                            setIsCompleteModalActive(false)
                        }}
                    >
                        {t("awareness.upsell.modal.completed.button")}
                    </Button>
                </div>
            </Modal>

            <div className="flex-1 overflow-auto">
                <PageHeader title={t("awareness.upsell.title")} />

                <Typography>{t("awareness.upsell.description")}</Typography>

                <section className="mt-8">
                    <Typography variant="header-3">{t("awareness.upsell.entriesTitle")}</Typography>
                    <div className="mt-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                        {Object.entries(upsellEntries).map(([key, value], index) => {
                            return (
                                <div className="flex gap-4 items-start" key={`upsell-entry-${index}`}>
                                    {getUpsellEntryIcon(key)}
                                    <div>
                                        <Typography variant="body-1-semibold">{value.title}</Typography>
                                        <Typography variant="body-1">{value.description}</Typography>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>

                <section className="mt-12 grid grid-cols-1 xl:grid-cols-[445px_auto] gap-10">
                    <img
                        src={OverviewImage}
                        className="w-[445px] h-[230px] aspect-auto"
                        alt="awareness dashboard image"
                    />
                    <div>
                        <Typography variant="header-2">{t("awareness.upsell.cta.title")}</Typography>
                        <Typography withTextWrapping={false} className="mt-4">
                            {t("awareness.upsell.cta.description")}
                        </Typography>
                        <Button
                            className="mt-6"
                            variant="primary"
                            onClick={() => {
                                setIsRequestModalActive(true)
                            }}
                        >
                            {t("awareness.upsell.cta.button")}
                        </Button>
                    </div>
                </section>
            </div>
        </PageLayout>
    )
}
