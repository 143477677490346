import { useInsuranceUpsell } from "@hooks/useInsuranceUpsell"
import React, { useState } from "react"
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import Tippy from "@tippyjs/react"
import { Typography } from "@designSystem/typography/Typography"
import { useTranslation } from "react-i18next"
import { Button } from "@designSystem/buttons/Button"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useFeatureFlagVariantKey } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"

export const InsuranceUpsellTooltip: React.FC = () => {
    const { showInsuranceUpsell, completeInsuranceUpsell } = useInsuranceUpsell()
    const { t } = useTranslation()
    const { captureEvent } = useCapturePostHogEvent()
    const featureFlagVariant = useFeatureFlagVariantKey(featureFlagConfig.insuranceUpsellExperiment)
    const [hasSkippedTooltip, setHasSkippedTooltip] = useState(false)

    if (showInsuranceUpsell === false) {
        return null
    }

    if (hasSkippedTooltip === true) {
        return false
    }

    // The control group is currently set as the tooltip variant of the A/B test
    // The featureflagvariant currently is "undefined" | "control" | "quiz"
    if (featureFlagVariant !== "control") {
        return null
    }

    return (
        <Tippy
            interactive
            onShow={(instance) => {
                // OnShow triggers if the user hovers over de hotspot
                // Add a event listener to all the buttons in the tooltip, these then close the hotspot
                instance.popper.querySelectorAll("button")?.forEach((button) => {
                    button.addEventListener("click", () => {
                        instance.hide()
                    })
                })
            }}
            content={
                <div className="dark w-[320px] p-4 space-y-3">
                    <Typography variant="header-5">{t("dashboardMetrics:insuranceUpsell.tooltip.title")}</Typography>
                    <Typography variant="body-2">
                        {t("dashboardMetrics:insuranceUpsell.tooltip.description")}
                    </Typography>

                    <div className="space-x-2">
                        <a href="https://app.eyeunderwriting.eu/" target="_blank" rel="noopener noreferrer">
                            <Button
                                variant="primary"
                                size="small"
                                onClick={() => {
                                    captureEvent(POSTHOG_CAPTURE_TYPES.INSURANCE_UPSELL.REQUEST_QUOTE)
                                    completeInsuranceUpsell()
                                }}
                            >
                                {t("dashboardMetrics:insuranceUpsell.tooltip.requestQuote")}
                            </Button>
                        </a>
                        <Button
                            variant="secondary"
                            size="small"
                            onClick={() => {
                                captureEvent(POSTHOG_CAPTURE_TYPES.INSURANCE_UPSELL.TOOLTIP.SKIP)
                                completeInsuranceUpsell()
                                setHasSkippedTooltip(true)
                            }}
                        >
                            {t("dashboardMetrics:insuranceUpsell.tooltip.skip")}
                        </Button>
                    </div>
                </div>
            }
        >
            <div className="animate-pulse">
                <CheckBadgeIcon className="text-text-primary-light" width={20} />
            </div>
        </Tippy>
    )
}
