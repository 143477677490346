import { Typography } from "@designSystem/typography/Typography"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from "@designSystem/overlays/modal/Modal"
import { Button } from "@designSystem/buttons/Button"
import { UserContext } from "@/providers/UserContext"
import { usePostSentinelStatus } from "./hooks/usePostSentinelStatus"
import { StateIllustration } from "@components/stateIllustration/StateIllustration"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { IOnboardingState } from "@utils/onboardingActionListUtils"
import { SentinelChecklistContent } from "./SentinelChecklistContent"

interface Props {
    setActiveOnboardingState: (state: IOnboardingState["sentinelOnboarding"]) => void
}

export const SentinelOnboardingChecklist: React.FC<Props> = ({ setActiveOnboardingState }) => {
    const { t } = useTranslation()
    const [completionModalActive, setCompletionModalActive] = useState(false)
    const { activeOrganisation } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    const tenants = activeOrganisation?.azure_tenants ?? []
    const showTenants = tenants.length > 1
    const { mutate, isPending, isSuccess, isError } = usePostSentinelStatus()
    const navigate = useNavigate()

    const onMarkAsDone = () => {
        // Show the modal, and at the same time update the state in the BE.
        // The modal then shows different content depending on the loading/error/success states of the mutation.
        setCompletionModalActive(true)
        mutate({ eyed: activeEyed })
    }

    const onSeeDetails = () => {
        // Users will only see the "see details" button after a succesfull state update.
        // Therefore we can safely set the OPERATIONS_ONBOARDING_IN_PROGRESS state in the parent component
        // This then shows the "Eye is working on your onboarding screen"
        // We could also derive this from the organisation endpoint data, but that would lead to a slightly sloppier user flow, therefore we use this
        // "optimistic" update pattern.
        setActiveOnboardingState("OPERATIONS_ONBOARDING_IN_PROGRESS")
    }

    return (
        <div>
            <div className="space-y-4">
                <Typography variant="header-1">{t("sentinelOnboarding:title")}</Typography>
                <Typography variant="body-1" color="text-secondary">
                    {t("sentinelOnboarding:subtitle")}
                </Typography>
            </div>

            <Modal open={completionModalActive} setOpen={setCompletionModalActive} className="p-10" withCloseButton>
                <div className="w-[400px]">
                    <div className="flex justify-center mb-8">
                        <StateIllustration variant={isPending ? "Loading" : isSuccess ? "Success" : "Error"} />
                    </div>

                    {isPending && (
                        <div>
                            <Typography variant="header-2">{t("sentinelOnboarding:loadingModal.title")}</Typography>
                            <Typography variant="body-2" className="mt-2">
                                {t("sentinelOnboarding:loadingModal.description")}
                            </Typography>
                        </div>
                    )}

                    {isSuccess && (
                        <div>
                            <Typography variant="header-2">{t("sentinelOnboarding:completionModal.title")}</Typography>
                            <div className="space-x-4 *:mt-8">
                                <Button variant="primary" size="small" onClick={() => onSeeDetails()}>
                                    {t("sentinelOnboarding:completionModal.seeDetails")}
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="small"
                                    onClick={() => {
                                        navigate(
                                            getRouterLinks().dashboard({
                                                eyed: activeEyed,
                                            }),
                                        )
                                    }}
                                >
                                    {t("sentinelOnboarding:completionModal.goToDashboard")}
                                </Button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div>
                            <Typography variant="header-2">{t("sentinelOnboarding:errorModal.title")}</Typography>
                            <Typography variant="body-2" className="mt-2">
                                {t("sentinelOnboarding:errorModal.description")}
                            </Typography>

                            <div className="space-x-4 *:mt-8">
                                <Button variant="primary" size="small" onClick={onMarkAsDone}>
                                    {t("sentinelOnboarding:errorModal.tryAgain")}
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="small"
                                    onClick={() => {
                                        navigate(
                                            getRouterLinks().dashboard({
                                                eyed: activeEyed,
                                            }),
                                        )
                                    }}
                                >
                                    {t("sentinelOnboarding:errorModal.goBack")}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>

            <SentinelChecklistContent onMarkAsDone={onMarkAsDone} showTenants={showTenants} tenants={tenants} />
        </div>
    )
}
