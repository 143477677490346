import React from "react"
import { IRecommendation } from "../recommendationTypes"
import Papa from "papaparse"
import { downloadFile } from "@utils/downloadUtils"
import { Button } from "@designSystem/buttons/Button"
import { useTranslation } from "react-i18next"
import { ArrowDownIcon } from "@heroicons/react/20/solid"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { isNullish } from "@utils/formatUtils"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { getAssetColumns, getWhitelistedRecommendationAsset } from "@utils/recommendationUtils"

interface Props {
    recommendation: IRecommendation
    tableType: "assets" | "policies"
}

export const DownloadRecommendationTableButton: React.FC<Props> = ({ recommendation, tableType }) => {
    const { t } = useTranslation()
    const csvDownloadButtonsEnabled = useFeatureFlagEnabled(featureFlagConfig.recommendationCSVDownload)
    const { captureEvent } = useCapturePostHogEvent()

    if (csvDownloadButtonsEnabled === false || isNullish(csvDownloadButtonsEnabled)) {
        return null
    }

    const downloadCSV = (parsedCSV: string) => {
        // Create a blob and then trigger the generic download file utility.
        const blob = new Blob([parsedCSV], { type: "text/csv" })
        downloadFile(blob, `recommendation-${recommendation.id}-${tableType}.csv`)
        captureEvent(POSTHOG_CAPTURE_TYPES.DOWNLOAD_RECOMMENDATION_CSV_EVENT)
    }

    const downloadMFATable = (mfaRecommendation: IRecommendation, type: Props["tableType"]) => {
        if (
            mfaRecommendation.details?.mfa_policies_v2 === undefined &&
            mfaRecommendation.details?.mfa_user_info === undefined
        ) {
            return
        }

        if (type === "assets" && mfaRecommendation.details?.mfa_user_info) {
            // parses the assets into csv format.
            const parsedAssets = Papa.unparse(mfaRecommendation.details?.mfa_user_info)
            downloadCSV(parsedAssets)
            return
        }

        if (mfaRecommendation.details.mfa_policies_v2) {
            // parses the assets into csv format.
            const parsedAssets = Papa.unparse(mfaRecommendation.details.mfa_policies_v2)
            downloadCSV(parsedAssets)
            return
        }
    }

    const downloadAssetsCSV = () => {
        // The MFA recommendation has it's assets available in the mfa_user_info object instead of the general assets property.
        if (recommendation.id === "245a" && recommendation.details?.mfa_user_info) {
            downloadMFATable(recommendation, tableType)
            return
        }

        // Get all the whitelisted columns from the recommendation
        const columns = getAssetColumns(recommendation.assets)
        // Rebuild the assets collection with only whitelisted columns
        const whitelistedAssetCollection = getWhitelistedRecommendationAsset(recommendation.assets)

        // Maps the assets ( which are stored in key/value object ) back into an array. ( format which the csv parser can read)
        const assets = Object.entries(whitelistedAssetCollection).map(([key, value]) => {
            return { ...value, id: key }
        })

        // Parses the assets into csv format.
        const parsedAssets = Papa.unparse(assets, { columns: columns })
        downloadCSV(parsedAssets)
    }

    return (
        <Button
            variant="secondary"
            size="small"
            onClick={downloadAssetsCSV}
            endIcon={<ArrowDownIcon width={20} height={20} />}
        >
            {tableType === "assets" ? t("recommendations.downloadAssets") : t("recommendations.downloadPolicies")}
        </Button>
    )
}
