import { localStorageSettings } from "@/localStorage/localStorage"
import { UserContext } from "@/providers/UserContext"
import { Button } from "@designSystem/buttons/Button"
import { Typography } from "@designSystem/typography/Typography"
import React, { useContext, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import Tippy from "@tippyjs/react"
import Modal from "@designSystem/overlays/modal/Modal"
import { isNullish } from "@utils/formatUtils"
import { Collapse } from "@designSystem/layout/Collapse"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"

export const TenantsIndicator: React.FC = () => {
    const { t } = useTranslation()
    const { activeOrganisation } = useContext(UserContext)
    const azureTenantsCount = activeOrganisation?.azure_tenants?.length ?? 0
    const [hasReviewedMsTenants, setHasReviewedMsTenants] = useState(localStorageSettings.getHasReviewedMSTenants())
    const [tenantsModalOpen, setTenantsModalOpen] = useState(false)

    const onOpenTenantsModal = () => {
        localStorageSettings.setHasReviewedMsTenants()
        setHasReviewedMsTenants(true)
        setTenantsModalOpen(true)
    }

    if (azureTenantsCount === 0) {
        return null
    }

    return (
        <div className="flex items-center gap-1">
            {tenantsModalOpen && (
                <Modal open={tenantsModalOpen} setOpen={setTenantsModalOpen} withCloseButton>
                    <div className="w-[325px] md:w-[540px] p-8 text-left">
                        <div className="space-y-4">
                            <Typography variant="header-2">{t("dashboard.tenants.modal.title")}</Typography>
                            <Typography variant="body-2" color="text-secondary">
                                {t("dashboard.tenants.modal.description")}
                            </Typography>
                            {!isNullish(activeOrganisation?.azure_tenants_updated_at) && (
                                <div className="flex items-center gap-2">
                                    <Typography color="text-secondary">
                                        {`${t("dashboard.tenants.modal.lastUpdated")}:`}
                                    </Typography>
                                    <Typography>
                                        {formatDate(
                                            activeOrganisation?.azure_tenants_updated_at,
                                            DATE_FORMATS.DATE_TIME_FORMAT,
                                        )}
                                    </Typography>
                                </div>
                            )}

                            <div className="space-y-3">
                                {activeOrganisation?.azure_tenants?.map((tenant) => {
                                    return (
                                        <div key={`tenant-block-${tenant.id}`} className="border rounded-md">
                                            <Collapse
                                                defaultOpen={false}
                                                title={
                                                    <div className="">
                                                        <Typography variant="body-1-semibold">{tenant.name}</Typography>
                                                    </div>
                                                }
                                                buttonClassName="w-full flex justify-between p-4 items-center"
                                            >
                                                <div className="p-4 border-t max-h-[250px] overflow-auto">
                                                    <Typography variant="body-2" color="text-secondary">
                                                        {t("dashboard.tenants.modal.connectedDomains")}
                                                    </Typography>

                                                    <ul>
                                                        {tenant.domains?.map((tenantDomain) => {
                                                            return (
                                                                <li key={`tenant-domain-${tenantDomain}`}>
                                                                    {tenantDomain}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </Collapse>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="mt-4">
                            <Typography variant="body-2">
                                <Trans
                                    i18nKey="dashboard.tenants.modal.support"
                                    components={[
                                        <a
                                            key="kb-link"
                                            className="inline-block text-text-link-light hover:underline font-semibold"
                                            href="mailto:support@eye.security"
                                        />,
                                    ]}
                                />
                            </Typography>
                        </div>
                    </div>
                </Modal>
            )}

            {hasReviewedMsTenants === false && (
                <Tippy content={t("dashboard.tenants.tooltip")}>
                    <ExclamationTriangleIcon
                        width={20}
                        className="text-text-warning-light"
                        onClick={onOpenTenantsModal}
                    />
                </Tippy>
            )}

            <Typography>{`${t("dashboard.tenants.header")}:`}</Typography>
            <Button
                withPadding={false}
                variant="text"
                className="text-text-link-light"
                onClick={onOpenTenantsModal}
            >{`${azureTenantsCount} ${t("dashboard.tenants.connected")}`}</Button>
        </div>
    )
}
