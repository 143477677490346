import { EyeOrganisation } from "../features/accountSwitcher/hooks/useUserOrganisations"

export type EyeOrganistionPlan = "Cyber Guard" | "Cyber Guard Plus" | "Cyber Guard Insured"

export const getOrganisationPlan = (organisation: EyeOrganisation): EyeOrganistionPlan => {
    if (organisation.insured) {
        return "Cyber Guard Insured"
    }

    if (organisation.awareness) {
        return "Cyber Guard Plus"
    }

    return "Cyber Guard"
}
