import {
    Table,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    SortableTableHeaderCell,
} from "@designSystem/layout/table/Table"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import {
    Row,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import i18n from "@/i18next"
import { Typography } from "@designSystem/typography/Typography"
import { IRecipient } from "../awarenessTypes"
import { SortableTableHeader } from "@designSystem/layout/table/SortableTableHeader"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import { useTranslation } from "react-i18next"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import { RecipientToggle } from "./RecipientToggle"
import { showWithFallbackValue } from "@utils/formatUtils"
import { DisplayLanguage } from "@components/displayLanguage/DisplayLanguage"

const columnHelper = createColumnHelper<IRecipient>()

const tableColumns = [
    // Combines the firstname & lastname columns into one, computed column.
    columnHelper.accessor((row) => `${row.firstname} ${row.lastname}`, {
        id: "fullName",
        header: i18n.t("pages:awareness.recipientInfo.table.recipient"),
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping>
                {showWithFallbackValue(info.getValue())}
            </Typography>
        ),
        enableGlobalFilter: true,
    }),
    columnHelper.accessor("email", {
        header: i18n.t("pages:awareness.recipientInfo.table.email"),
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping>
                {info.getValue()}
            </Typography>
        ),
        enableGlobalFilter: true,
    }),
    columnHelper.accessor("language", {
        header: i18n.t("pages:awareness.recipientInfo.table.language"),
        cell: (info) => <DisplayLanguage value={info.getValue()} textElipsis shouldPreventWrapping />,
        enableGlobalFilter: true,
    }),
    columnHelper.accessor("isActive", {
        header: i18n.t("pages:awareness.recipientInfo.table.active"),
        cell: (info) => <RecipientToggle recipient={info.row.original} />,
        enableGlobalFilter: true,
    }),
]

interface Props {
    recipients: IRecipient[]
    searchValue: string
}

export const RecipientsTable: React.FC<Props> = ({ recipients, searchValue }) => {
    const { t } = useTranslation()

    const filterRecipients = (row: Row<IRecipient>, columnId: string, filterValue: string) => {
        const value = row.getValue(columnId)
        if (typeof value === "string" && value.toLowerCase().includes(filterValue.toLowerCase())) {
            return true
        }

        return false
    }

    const table = useReactTable({
        data: recipients,
        columns: tableColumns,
        state: {
            globalFilter: searchValue,
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: filterRecipients,
    })

    return (
        <TablePaginationWrapper data={recipients} table={table} isRounded isVisible>
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <SortableTableHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader {...header.getContext()}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </SortableTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>

                <TableBody>
                    {table.getRowModel().rows.length === 0 && (
                        <TableRow>
                            <td colSpan={table.getAllColumns().length} className="p-12">
                                <NoDataMessage
                                    title={t("awareness.recipientInfo.table.notFoundMessage.title")}
                                    subtitle={t("awareness.recipientInfo.table.notFoundMessage.description")}
                                    variant="row"
                                    image={<SearchIllustration className="w-[250px]" />}
                                />
                            </td>
                        </TableRow>
                    )}

                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}
