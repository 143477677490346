import Navigation from "@components/navigation/Navigation"
import SideClapper from "@designSystem/layout/SideClapper"
import React, { Fragment, useContext, useEffect } from "react"
import PortalLogo from "./PortalLogo"
import { UserContext } from "@/providers/UserContext"
import { EyeOrganistionPlan, getOrganisationPlan } from "@utils/organisationUtils"
import { Typography } from "@designSystem/typography/Typography"
import AccountSwitcher from "@features/accountSwitcher/components/AccountSwitcher"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useApplicationStore } from "@/stores/applicationStore"
import { DropoutNavigation } from "@components/navigation/DropoutNavigation"
import { useLocation } from "react-router-dom"

interface SidebarContentProps {
    organisationPlan: EyeOrganistionPlan | null
    setSidebarOpen: (open: boolean) => void
}

const SidebarContent: React.FC<SidebarContentProps> = ({ organisationPlan, setSidebarOpen }) => {
    return (
        <Fragment>
            <div className="flex items-center mb-6 justify-between">
                <div className="flex items-center gap-4 ">
                    <div className="mt-1 h-[30px] w-[62px]">
                        <PortalLogo />
                    </div>

                    {organisationPlan && (
                        <Fragment>
                            <div className="w-[2px] h-[20px] bg-white" />
                            <Typography variant="body-1-semibold" color="text-invert">
                                {organisationPlan}
                            </Typography>
                        </Fragment>
                    )}
                </div>

                <button
                    className="text-text-primary-dark hover:text-text-secondary-light sm:hidden"
                    onClick={() => setSidebarOpen(false)}
                >
                    <XMarkIcon width={24} height={24} />
                </button>
            </div>

            <div className="mb-4">
                <AccountSwitcher />
            </div>

            <Navigation />

            <DropoutNavigation />
        </Fragment>
    )
}

export const AppSidebar: React.FC = () => {
    const { activeOrganisation } = useContext(UserContext)
    const isSidebarOpen = useApplicationStore((state) => state.isSidebarOpen)
    const setIsSidebarOpen = useApplicationStore((state) => state.setIsSidebarOpen)
    const organisationPlan = activeOrganisation ? getOrganisationPlan(activeOrganisation) : null
    const riskStagingEnabled = useApplicationStore((state) => state.riskStagingEnabled)

    const location = useLocation()

    useEffect(() => {
        // Closes the sidebar after navigating to a new page
        setIsSidebarOpen(false)
    }, [location, setIsSidebarOpen])

    return (
        <Fragment>
            <SideClapper open={isSidebarOpen} setOpen={setIsSidebarOpen}>
                <div className="bg-background-brand-primary-light h-full w-[320px] flex flex-col">
                    <SidebarContent organisationPlan={organisationPlan} setSidebarOpen={setIsSidebarOpen} />
                </div>
            </SideClapper>

            <aside
                className="bg-background-brand-primary-light h-full hidden sm:flex flex-col w-[320px] p-5"
                data-testid="desktop-navigation"
            >
                {riskStagingEnabled && (
                    <div className="rainbow-gradient p-3 rounded-md mb-4">
                        <Typography variant="header-5" color="text-invert">
                            Risk staging data enabled
                        </Typography>
                    </div>
                )}
                <SidebarContent organisationPlan={organisationPlan} setSidebarOpen={setIsSidebarOpen} />
            </aside>
        </Fragment>
    )
}
