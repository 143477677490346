import { Button } from "@designSystem/buttons/Button"
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@designSystem/layout/table/Table"
import { SelectMenu } from "@designSystem/overlays/SelectMenu"
import { Typography } from "@designSystem/typography/Typography"
import { IAwarenessLanguage, RecipientColumnSelection, RecipientRegistration } from "@features/awareness/awarenessTypes"
import { AdminStep } from "@features/awareness/components/AdminStep"
import React, { useEffect, useState } from "react"
import { BellIcon } from "@heroicons/react/24/outline"
import { ZodIssue, z } from "zod"
import Tippy from "@tippyjs/react"
import { isEmail } from "@utils/validationUtils"
interface Props {
    recipientsData: RecipientRegistration[] | undefined
    columnOptions: RecipientColumnSelection[]
    setRecipientsData: (recipients: RecipientRegistration[]) => void
    onUploadRecipientsData: (recipientsData: RecipientRegistration[]) => void
    awarenessLanguages: IAwarenessLanguage[]
}

const TABLE_COLUMN_WIDTH = 250

export const DataVerificationStep: React.FC<Props> = ({
    recipientsData,
    setRecipientsData,
    columnOptions,
    onUploadRecipientsData,
    awarenessLanguages,
}) => {
    const [correctedRecipientsData, setCorrectedRecipientsData] = useState<RecipientRegistration[] | undefined>(
        recipientsData,
    )
    const [allRecipientsLanguage, setAllRecipientsLanguage] = useState("en")
    const [validationErrors, setValidationErrors] = useState<ZodIssue[]>([])
    const hasMissingLanguages = recipientsData?.some((recipient) => recipient.language === undefined)
    const correctedRecipientsHaveMissingLanguages = correctedRecipientsData?.some(
        (recipient) => recipient.language === undefined,
    )

    const languageOptions = awarenessLanguages.map((language) => language.Code)

    const validateRecipientsData = (data: RecipientRegistration[]): ZodIssue[] => {
        const recipientSchema = z.object({
            firstName: z.string().optional(),
            lastName: z.string().optional(),
            email: z.custom((data) => {
                return typeof data === "string" ? isEmail(data) : false
            }),
            language: z.string().min(1),
            department: z.string().optional(),
            jobTitle: z.string().optional(),
        })

        const listSchema = z.array(recipientSchema)
        const result = listSchema.safeParse(data)

        if (result.success) {
            return []
        } else {
            console.log("VALIDATION ERROR")
            console.log(result.error.issues)
            return result.error.issues
        }
    }

    const updateCorrectedRecipients = (recipientToUpdate: RecipientRegistration) => {
        setCorrectedRecipientsData(
            correctedRecipientsData?.map((correctedRecipient) => {
                if (correctedRecipient.email === recipientToUpdate.email) {
                    return recipientToUpdate
                }

                return correctedRecipient
            }),
        )
    }

    // In case the corrected recipientsdata is empty, but the recipients data itself is present, we can prefill the corrected data.
    useEffect(() => {
        if (recipientsData && correctedRecipientsData === undefined) {
            setCorrectedRecipientsData(recipientsData)
        }
    }, [recipientsData, correctedRecipientsData])

    // When the recipientsdata changes, make sure the correcteddata is also overridden, in case the user went back some steps and corrected some info.
    useEffect(() => {
        setValidationErrors([])
        setCorrectedRecipientsData(recipientsData)
    }, [recipientsData])

    if (!recipientsData) {
        return (
            <AdminStep subtitle="Step 4" title="Verify data">
                <Typography color="text-secondary">Select columns first</Typography>
            </AdminStep>
        )
    }

    if (hasMissingLanguages) {
        return (
            <AdminStep subtitle="Step 4" title="Verify data">
                <div>
                    <div className="border p-2 rounded-md flex items-center gap-3 mt-2">
                        <BellIcon width={24} className="text-red-500" />
                        <Typography>The following entries are missing a language</Typography>
                    </div>

                    <div className="border p-4 rounded-md shadow-sm mt-4">
                        <Typography variant="body-1-semibold">Select a language for all recipients</Typography>
                        <div className="mt-2">
                            <SelectMenu
                                options={languageOptions}
                                xOrientation="right"
                                yOrientation="bottom"
                                onSelectOption={(option) => {
                                    setCorrectedRecipientsData(
                                        correctedRecipientsData?.map((recipient) => {
                                            return { ...recipient, language: option as string }
                                        }),
                                    )
                                    setAllRecipientsLanguage(option as string)
                                }}
                                selectedOption={allRecipientsLanguage}
                            />
                        </div>
                    </div>

                    <div className="mt-8">
                        <Typography variant="body-1-semibold">Recipients data</Typography>
                        <div className="mt-4 max-h-[600px] overflow-y-auto overflow-x-visible space-y-2 p-8 bg-neutral-100 rounded-md border">
                            {correctedRecipientsData?.map((recipient, index) => {
                                return (
                                    <div
                                        className="flex py-2 px-6 items-center justify-between border rounded-md bg-white"
                                        key={`recipient-row-${recipient.email}-${index}`}
                                    >
                                        <div>
                                            {index}-{recipient.email}
                                        </div>
                                        <SelectMenu
                                            options={languageOptions}
                                            xOrientation="left"
                                            yOrientation="bottom"
                                            onSelectOption={(option) => {
                                                updateCorrectedRecipients({ ...recipient, language: option as string })
                                            }}
                                            selectedOption={recipient.language ?? ""}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <Button
                        variant="primary"
                        disabled={correctedRecipientsData === undefined || correctedRecipientsHaveMissingLanguages}
                        onClick={() => {
                            if (correctedRecipientsData) {
                                setRecipientsData(correctedRecipientsData)
                            }
                        }}
                    >
                        Confirm corrected languages
                    </Button>
                </div>
            </AdminStep>
        )
    }

    return (
        <AdminStep subtitle="Step 4" title="Verify the csv data">
            <Typography>Please verify if the data in the table below is correct.</Typography>

            {validationErrors.length > 0 && (
                <div className="border p-2 rounded-md flex items-center gap-3 mt-2">
                    <BellIcon width={24} className="text-red-500" />
                    <Typography>
                        The data is incomplete or incorrect, please make sure no fields or columns are missing. Also
                        make sure you have assigned the right columns to the correct type of data.
                    </Typography>
                </div>
            )}

            <div className="mt-4">
                <Table isFixed className="h-[400px]" isRounded>
                    <TableHeader>
                        <TableRow>
                            {columnOptions.map((columnOption) => {
                                return (
                                    <TableHeaderCell
                                        key={`header-column-${columnOption.columnName}`}
                                        width={TABLE_COLUMN_WIDTH}
                                    >
                                        {columnOption.columnName}
                                    </TableHeaderCell>
                                )
                            })}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {recipientsData.map((row, rowIndex) => {
                            return (
                                <TableRow key={`check-row-${rowIndex}`}>
                                    {columnOptions.map((columnOption) => {
                                        // The zod error exists in the path array [index, columnName]
                                        const hasError =
                                            validationErrors.find((error) => {
                                                return (
                                                    error.path[0] === rowIndex &&
                                                    error.path[1] === columnOption.columnName
                                                )
                                            }) !== undefined

                                        if (hasError) {
                                            return (
                                                <TableCell
                                                    width={TABLE_COLUMN_WIDTH}
                                                    className={hasError ? "bg-red-100" : ""}
                                                    key={`table-column-${columnOption.columnName}-${rowIndex}`}
                                                >
                                                    <Tippy
                                                        content={`This ${columnOption.columnName} is invalid`}
                                                        placement={"top"}
                                                    >
                                                        {/* The columnoptions and recipientregistration are build on top of the same keys */}
                                                        <div>
                                                            {
                                                                row[
                                                                    columnOption.columnName as keyof RecipientRegistration
                                                                ]
                                                            }
                                                        </div>
                                                    </Tippy>
                                                </TableCell>
                                            )
                                        }

                                        return (
                                            <TableCell
                                                key={`table-column-${columnOption.columnName}-${rowIndex}`}
                                                width={TABLE_COLUMN_WIDTH}
                                            >
                                                <Typography shouldPreventWrapping textElipsis>
                                                    {/* The columnoptions and recipientregistration are build on top of the same keys */}
                                                    {row[columnOption.columnName as keyof RecipientRegistration]}
                                                </Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>

            <div className="mt-4">
                <Button
                    variant="primary"
                    onClick={() => {
                        const validationErrors = validateRecipientsData(recipientsData)

                        if (validationErrors.length === 0) {
                            setValidationErrors([])
                            // Triggers the upload in the parent component, which handles the rest.
                            onUploadRecipientsData(recipientsData)
                        } else {
                            setValidationErrors(validationErrors)
                        }
                    }}
                >
                    Upload recipients
                </Button>
            </div>
        </AdminStep>
    )
}
