import { PageHeader } from "@components/header/PageHeader"
import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import { ClockIcon, ClipboardDocumentListIcon, TrophyIcon, CheckBadgeIcon } from "@heroicons/react/24/outline"
import { ActionIcon } from "@designSystem/icons/ActionIcon"
import { IconContentBlock } from "@designSystem/layout/IconContentBlock"
import { useTranslation } from "react-i18next"

export const IntroductionStep: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className="">
            <PageHeader title={t("awareness.onboarding.steps.introductionStep.title")} />

            <Typography>{t("awareness.onboarding.steps.introductionStep.description")}</Typography>

            <section className="mt-8">
                <Typography variant="header-3">
                    {t("awareness.onboarding.steps.introductionStep.expectTitle")}
                </Typography>

                <div className="inline-grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                    <IconContentBlock
                        title={t("awareness.onboarding.steps.introductionStep.features.scenarios.title")}
                        description={t("awareness.onboarding.steps.introductionStep.features.scenarios.description")}
                        icon={<ActionIcon icon={<ClockIcon width={24} height={24} />} color="orange" size="large" />}
                    />

                    <IconContentBlock
                        title={t("awareness.onboarding.steps.introductionStep.features.feedback.title")}
                        description={t("awareness.onboarding.steps.introductionStep.features.feedback.description")}
                        icon={
                            <ActionIcon icon={<CheckBadgeIcon width={24} height={24} />} color="orange" size="large" />
                        }
                    />

                    <IconContentBlock
                        title={t("awareness.onboarding.steps.introductionStep.features.scores.title")}
                        description={t("awareness.onboarding.steps.introductionStep.features.scores.description")}
                        icon={<ActionIcon icon={<TrophyIcon width={24} height={24} />} color="orange" size="large" />}
                    />

                    <IconContentBlock
                        title={t("awareness.onboarding.steps.introductionStep.features.reporting.title")}
                        description={t("awareness.onboarding.steps.introductionStep.features.reporting.description")}
                        icon={
                            <ActionIcon
                                icon={<ClipboardDocumentListIcon width={24} height={24} />}
                                color="orange"
                                size="large"
                            />
                        }
                    />
                </div>
            </section>
        </div>
    )
}
