import React, { useContext } from "react"
import { useDownloadManagementReport } from "../hooks/useDownloadManagementReport"
import { Button } from "@designSystem/buttons/Button"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useTranslation } from "react-i18next"
import DropDown from "@designSystem/overlays/Dropdown/Dropdown"
import { DropdownPanel } from "@designSystem/overlays/Dropdown/DropdownPanel"
import { DropDownListItem } from "@designSystem/overlays/Dropdown/DropdownListItem"
import { LANGUAGE_CONFIG } from "@config/languageConfig"
import { IntroductionHotspot } from "@designSystem/hotspot/IntroductionHotspot"
import { HotspotConfig } from "@config/hotspotConfig"
import { UserContext } from "@/providers/UserContext"

export const DownloadCurrentReportButton: React.FC = () => {
    const { mutate, isPending } = useDownloadManagementReport()
    const activeEyed = useActiveEyed()
    const { t } = useTranslation()
    const { activeOrganisation } = useContext(UserContext)

    return (
        <DropDown
            buttonType="div"
            button={
                <div className="relative">
                    <div className="absolute -bottom-3 right-[42%]">
                        <IntroductionHotspot
                            buttonText={t("introductionHotspots:managementReportingHotspot.button")}
                            description={t("introductionHotspots:managementReportingHotspot.description")}
                            title={t("introductionHotspots:managementReportingHotspot.title")}
                            hotspotId={HotspotConfig.MANGEMENT_REPORTING_HOTSPOT}
                        />
                    </div>
                    <Button variant="secondary" size="small" isLoading={isPending} disabled={isPending}>
                        {t("dashboard.exportSummary")}
                    </Button>
                </div>
            }
        >
            {(onClosePanel) => {
                return (
                    <DropdownPanel>
                        {LANGUAGE_CONFIG.managementReportingLanguages.map((language) => {
                            return (
                                <DropDownListItem
                                    key={`${language.abbreviation}-management-report`}
                                    onClick={() => {
                                        mutate({
                                            eyed: activeEyed,
                                            language: language.abbreviation,
                                            organisationName: activeOrganisation?.name,
                                        })
                                        onClosePanel()
                                    }}
                                >
                                    {language.label}
                                </DropDownListItem>
                            )
                        })}
                    </DropdownPanel>
                )
            }}
        </DropDown>
    )
}
