import { localStorageSettings } from "@/localStorage/localStorage"
import { create, StateCreator } from "zustand"

type IEASTRecommendationStatus = "PENDING_RECOMMENDATIONS" | "HAS_EAST_RECOMMENDATION" | "NO_EAST_RECOMMENDATION"

interface PostHogSlice {
    postHogConfigured: boolean
    setPostHogConfigured: (postHogConfigured: boolean) => void
    //
    showConsentBanner: boolean
    setShowConsentBanner: (showConsentBanner: boolean) => void
    //
    isUserIdentified: boolean
    setIsUserIdentified: (isIdentified: boolean) => void
    //
    arePostHogUserPropertiesSynced: boolean
    setArePostHogUserPropertiesSynced: (isSynced: boolean) => void
    //
    userMenuActive: boolean
    setUserMenuActive: (active: boolean) => void
}

interface RiskSlice {
    riskStagingEnabled: boolean
    setRiskStagingEnabled: (enabled: boolean) => void
}

interface MessagingSettingsSlice {
    // Indicates if the user has the east recommendation in their org recommendations data.
    eastRecommendationStatus: IEASTRecommendationStatus
    setEastRecommendationStatus: (status: IEASTRecommendationStatus) => void
    // Indicates if the user has closed the east notification
    hasClosedEastNotification: boolean
    setHasClosedEastNotification: (hasClosed: boolean) => void
}

interface NavigationSlice {
    isSidebarOpen: boolean
    setIsSidebarOpen: (isOpen: boolean) => void
}

const createPostHogSlice: StateCreator<
    MessagingSettingsSlice & PostHogSlice & RiskSlice & NavigationSlice,
    [],
    [],
    PostHogSlice
> = (set) => ({
    isUserIdentified: false,
    setIsUserIdentified: (isUserIdentified: boolean) => set({ isUserIdentified: isUserIdentified }),
    // Indicates if posthog consent was configured initially
    postHogConfigured: false,
    setPostHogConfigured: (postHogConfigured: boolean) => set({ postHogConfigured: postHogConfigured }),
    // Showing of consent banner
    showConsentBanner: false,
    setShowConsentBanner: (showConsentBanner: boolean) => set({ showConsentBanner: showConsentBanner }),
    // Property sync
    arePostHogUserPropertiesSynced: false,
    setArePostHogUserPropertiesSynced: (isSynced: boolean) => set({ arePostHogUserPropertiesSynced: isSynced }),
    // User Menu
    userMenuActive: false,
    setUserMenuActive: (active: boolean) => set({ userMenuActive: active }),
})

const createNavigationSlice: StateCreator<
    MessagingSettingsSlice & PostHogSlice & RiskSlice & NavigationSlice,
    [],
    [],
    NavigationSlice
> = (set) => ({
    isSidebarOpen: false,
    setIsSidebarOpen: (isOpen: boolean) => set({ isSidebarOpen: isOpen }),
})

const createRiskSlice: StateCreator<
    MessagingSettingsSlice & PostHogSlice & RiskSlice & NavigationSlice,
    [],
    [],
    RiskSlice
> = (set) => ({
    riskStagingEnabled: false,
    setRiskStagingEnabled: (riskStagingEnabled: boolean) => set({ riskStagingEnabled: riskStagingEnabled }),
})

const createMessagingSettingsSlice: StateCreator<
    MessagingSettingsSlice & PostHogSlice & RiskSlice & NavigationSlice,
    [],
    [],
    MessagingSettingsSlice
> = (set) => ({
    eastRecommendationStatus: "PENDING_RECOMMENDATIONS",
    setEastRecommendationStatus: (status: IEASTRecommendationStatus) => set({ eastRecommendationStatus: status }),
    hasClosedEastNotification: localStorageSettings.getHasClosedEastNotification(),
    setHasClosedEastNotification: (hasClosed: boolean) => set({ hasClosedEastNotification: hasClosed }),
})

export const useApplicationStore = create<PostHogSlice & RiskSlice & MessagingSettingsSlice & NavigationSlice>()(
    (...a) => ({
        ...createPostHogSlice(...a),
        ...createRiskSlice(...a),
        ...createMessagingSettingsSlice(...a),
        ...createNavigationSlice(...a),
    }),
)
