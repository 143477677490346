import { Button, ButtonVariant } from "@designSystem/buttons/Button"
import { useTranslation } from "react-i18next"

interface Props {
    variant: ButtonVariant
}

export const SupportButton: React.FC<Props> = ({ variant }) => {
    const { t } = useTranslation("pages")
    return (
        <a rel="noopener noreferrer" href={"https://kb.eye.security/kb-tickets/new"} target="_blank">
            <Button size="small" variant={variant}>
                {t("contact.support_ticket_button")}
            </Button>
        </a>
    )
}
