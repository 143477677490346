import { AdminStep } from "@features/awareness/components/AdminStep"
import React, { useState } from "react"
import { BuildingOffice2Icon } from "@heroicons/react/24/outline"
import { Button } from "@designSystem/buttons/Button"
import { Typography } from "@designSystem/typography/Typography"
import { OrganisationSelect } from "@components/organisationSelect/OrganisationSelect"
import { EyeOrganisation } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { RecipientsUploadStep } from "@features/awareness/awarenessTypes"

interface Props {
    confirmedOrganistion?: EyeOrganisation
    confirmOrganistion: (organistion: EyeOrganisation) => void
    clearRecipientsStep: (step: RecipientsUploadStep) => void
}

export const OrganisationStep: React.FC<Props> = ({
    confirmedOrganistion,
    confirmOrganistion,
    clearRecipientsStep,
}) => {
    const [selectedOrganisation, setSelectedOrganisation] = useState<EyeOrganisation>()

    return (
        <AdminStep subtitle="Step 1" title="Select a organisation">
            {confirmedOrganistion ? (
                <div className="border p-4 rounded-md flex items-center justify-between shadow-sm">
                    <div className="flex items-center gap-4 text-brand-eye-orange">
                        <BuildingOffice2Icon width={24} />
                        <div>
                            <Typography variant="body-1-semibold">{confirmedOrganistion.name}</Typography>
                            <Typography variant="body-1" color="text-secondary">
                                {confirmedOrganistion.eyed}
                            </Typography>
                        </div>
                    </div>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            clearRecipientsStep("SelectOrganisationStep")
                        }}
                    >
                        Clear Selection
                    </Button>
                </div>
            ) : (
                <div>
                    <OrganisationSelect
                        selectedOrganisation={selectedOrganisation}
                        setSelectedOrganisation={setSelectedOrganisation}
                    />

                    <Button
                        variant="primary"
                        className="mt-4"
                        disabled={selectedOrganisation === undefined}
                        onClick={() => {
                            if (selectedOrganisation) {
                                confirmOrganistion(selectedOrganisation)
                            }
                        }}
                    >
                        Confirm Selection
                    </Button>
                </div>
            )}
        </AdminStep>
    )
}
