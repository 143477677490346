import { Typography } from "@designSystem/typography/Typography"
import React from "react"

interface Props {
    icon: React.ReactNode
    title: string
    description: string
}

export const IconContentBlock: React.FC<Props> = ({ icon, title, description }) => {
    return (
        <div className="flex">
            <div className="pr-5">{icon}</div>

            <div>
                <Typography variant="body-1-semibold">{title}</Typography>
                <Typography variant="body-1">{description}</Typography>
            </div>
        </div>
    )
}
