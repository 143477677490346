export const buildURLWithQueryParams = (endpoint: string, from?: string, to?: string): string => {
    const params = new URLSearchParams()
    if (from) {
        params.append("fromTime", from)
    }
    if (to) {
        params.append("toTime", to)
    }
    return `${endpoint}?${params.toString()}`
}
