import React from "react"
import { Dialog, Transition, DialogPanel } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/solid"
interface Props {
    open: boolean
    setOpen: (open: boolean) => void
    className?: string
    children: React.ReactNode
    withCloseButton?: boolean
}

const Modal: React.FC<Props> = ({ open, setOpen, className, children, withCloseButton = false }) => {
    return (
        <Transition
            show={open}
            enter="duration-200 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 ease-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <Dialog as="div" className="relative z-50" onClose={setOpen} open={open}>
                <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="h-screen w-full flex flex-col items-center">
                            <DialogPanel
                                className={`mt-[2%] bg-white sm:max-w-[800px] drop-shadow-eyeShadow rounded-xl relative ${
                                    className ?? ""
                                }`}
                            >
                                {withCloseButton && (
                                    <button
                                        className="absolute top-4 right-4  hover:stroke-neutral-300"
                                        onClick={() => setOpen(false)}
                                    >
                                        <XMarkIcon width={24} className="text-text-secondary-light" />
                                    </button>
                                )}
                                {children}
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default Modal
