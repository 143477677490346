import React, { PropsWithChildren } from "react"

interface Props {
    onClick?: () => void
}

export const DropDownListItem: React.FC<PropsWithChildren<Props>> = ({ onClick, children }) => {
    return (
        <div
            className="px-2 py-1 rounded-md hover:bg-brand-eye-orange cursor-pointer hover:text-text-invert-light min-w-[150px]"
            onClick={onClick}
        >
            {children}
        </div>
    )
}
